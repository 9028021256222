import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Row, Segmented } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import { PropertyType, UserTypes } from '../../../common/userTypes';
import { useNavigate } from 'react-router-dom';
import './index.less';

const calculateGST = (gstRate, amount) => {
  let gstValue = (gstRate / 100) * amount;
  gstValue = gstValue + amount;
  return gstValue.toFixed(2);
};

export default function Pricing({ isTaxAccountant, planList = [], onClose = null }) {
  const navigate = useNavigate();
  const [billingCycle, setBillingCycle] = useState(2);
  const [plan, setPlan] = useState({});
  const admin = localStorage.getItem('admin');
  const { user_type = null } = admin ? JSON.parse(admin) : {};

  useEffect(() => {
    if (planList.length > 0) {
      const plan_list = {};
      plan_list.commercial_plan = planList.find((item) => item.billing_cycle === billingCycle && item.property_type === PropertyType.COMMERCIAL_PROPERTY);
      plan_list.investment_plan = planList.find((item) => item.billing_cycle === billingCycle && item.property_type === PropertyType.INVESTMENT_PROPERTY);

      setPlan({
        commercial_plan: calculateGST(plan_list.commercial_plan?.gst, Number(plan_list.commercial_plan?.price || 0)),
        investment_plan: calculateGST(plan_list.investment_plan?.gst, Number(plan_list.investment_plan?.price || 0)),
      });
    }
  }, [planList, billingCycle]);

  return (
    <main className={user_type === UserTypes.USER ? 'page-content pricing_wrapper' : 'pricing_wrapper'}>
      <div className={user_type === UserTypes.USER ? 'container-fluid bg-dash-back' : ''}>
        <div className={user_type === UserTypes.USER ? 'layout-specing' : ''}>
          <Card className="0mt-1 main_card">
            <Row className="align-items-center justify-content-between mt-1 mb-4">
              <Col span={12}>
                <div className=" mb-sm-0  mb-sm-0">
                  <h3>Choose your plan</h3>
                  <p className="sub_head">One single plan with all features</p>
                </div>
              </Col>
              <Col span={12}>
                <div className="text-end pricing_toggle">
                  <Segmented
                    value={billingCycle}
                    onChange={(e) => setBillingCycle(e)}
                    options={[
                      {
                        label: (
                          <div style={{ padding: 4 }}>
                            <div className="month_pricing">Monthly</div>
                            {/* <strong>20% off</strong> */}
                          </div>
                        ),
                        value: 1,
                      },
                      {
                        label: (
                          <div style={{ padding: 4 }}>
                            <div>Annual</div>
                            <strong>20% off</strong>
                          </div>
                        ),
                        value: 2,
                      },
                    ]}
                  />
                </div>
              </Col>
            </Row>

            <Row gutter={(25, 24)} justify="center" className="pt-3 ">
              <Col xxl={8} xl={8} lg={8} md={12} sm={12} span={24}>
                <Card className="pricing_card">
                  <div className="text-center">
                    <h5>OWNER OCCUPIED RESIDENTIAL PROPERTY</h5>
                    <h6>$0.00</h6>
                    <p className="plan_details">per property/month</p>
                  </div>
                  <div className="pricing_list">
                    <ul>
                      <li>
                        <p>
                          <span>
                            <CheckCircleOutlined />
                          </span>
                          Inclusive of GST
                        </p>
                      </li>
                      <li>
                        <p>
                          <span>
                            <CheckCircleOutlined />
                          </span>
                          Access to all features
                        </p>
                      </li>
                      <li>
                        <p>
                          <span>
                            <CheckCircleOutlined />
                          </span>
                          No credit card needed for trial period
                        </p>
                      </li>
                      <li>
                        <p>
                          <span>
                            <CheckCircleOutlined />
                          </span>
                          Fees Fully tax deductible
                        </p>
                      </li>
                    </ul>
                  </div>
                  <Button className="text-same" block onClick={() => (onClose ? onClose() : isTaxAccountant ? navigate('/clients/client/subscription/checkout') : navigate('/subscription/checkout'))}>
                    <span className="text-same">Get Started</span>
                  </Button>{' '}
                </Card>
              </Col>
              <Col xxl={8} xl={8} lg={8} md={12} sm={12} span={24}>
                <Card className="pricing_card mt-sm-0 mt-4">
                  <div className="text-center">
                    <h5>INVESTMENT RESIDENTIAL PROPERTY</h5>
                    <h6>${billingCycle === 1 ? plan?.investment_plan || 0 : parseFloat(plan?.investment_plan / 12 || 0).toFixed(2)}</h6>
                    <p className="plan_details">per property/month</p>
                  </div>
                  <div className="pricing_list">
                    <ul>
                      <li>
                        <p>
                          <span>
                            <CheckCircleOutlined />
                          </span>
                          First 3 month free
                        </p>
                      </li>
                      <li>
                        <p>
                          <span>
                            <CheckCircleOutlined />
                          </span>
                          Inclusive of GST
                        </p>
                      </li>
                      <li>
                        <p>
                          <span>
                            <CheckCircleOutlined />
                          </span>
                          Access to all features
                        </p>
                      </li>
                      <li>
                        <p>
                          <span>
                            <CheckCircleOutlined />
                          </span>
                          No credit card needed for trial period
                        </p>
                      </li>
                      <li>
                        <p>
                          <span>
                            <CheckCircleOutlined />
                          </span>
                          Fees Fully tax deductible
                        </p>
                      </li>
                      <li>
                        <p>
                          <span>
                            <CheckCircleOutlined />
                          </span>
                          Price per property
                        </p>
                      </li>
                    </ul>
                  </div>
                  <Button className="text-same" block onClick={() => (onClose ? onClose() : isTaxAccountant ? navigate('/clients/client/subscription/checkout') : navigate('/subscription/checkout'))}>
                    <span className="text-same">Get Started</span>
                  </Button>{' '}
                </Card>
              </Col>
              <Col xxl={8} xl={8} lg={8} md={12} sm={12} span={24}>
                <Card className="pricing_card mt-lg-0 mt-5">
                  <div className="text-center">
                    <h5>INVESTMENT COMMERCIAL PROPERTY</h5>
                    <h6>${billingCycle === 1 ? plan?.commercial_plan || 0 : parseFloat(plan?.commercial_plan / 12 || 0).toFixed(2)}</h6>
                    <p className="plan_details">per property/month</p>
                  </div>
                  <div className="pricing_list">
                    <ul>
                      <li>
                        <p>
                          <span>
                            <CheckCircleOutlined />
                          </span>
                          First 3 month free
                        </p>
                      </li>
                      <li>
                        <p>
                          <span>
                            <CheckCircleOutlined />
                          </span>
                          Inclusive of GST
                        </p>
                      </li>
                      <li>
                        <p>
                          <span>
                            <CheckCircleOutlined />
                          </span>
                          Access to all features
                        </p>
                      </li>
                      <li>
                        <p>
                          <span>
                            <CheckCircleOutlined />
                          </span>
                          No credit card needed for trial period
                        </p>
                      </li>
                      <li>
                        <p>
                          <span>
                            <CheckCircleOutlined />
                          </span>
                          Fees Fully tax deductible
                        </p>
                      </li>
                      <li>
                        <p>
                          <span>
                            <CheckCircleOutlined />
                          </span>
                          Price per property
                        </p>
                      </li>
                    </ul>
                  </div>
                  <Button className="text-same" block onClick={() => (onClose ? onClose() : isTaxAccountant ? navigate('/clients/client/subscription/checkout') : navigate('/subscription/checkout'))}>
                    <span className="text-same">Get Started</span>
                  </Button>
                </Card>
              </Col>
            </Row>
          </Card>
        </div>
      </div>
    </main>
  );
}
