import { Badge, Table, notification } from 'antd';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import { GetdocumentationdData } from '../../../redux/actions/client-detail/clients-tabs/documetation.action';
import { getYearList } from '../../../utils';
import { GetReciptdocumentationdData } from '../../../redux/actions/client-detail/clients-tabs/documentRecipt.action';
import { DocumentationReciptType } from '../../common/userTypes';

const PropertyDocumentation = ({ propertyId = null }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const currentClient = localStorage.getItem('currentClient');
  const DocumentData = useSelector((state) => state.DocumentationDataReducer);

  useEffect(() => {
    dispatch(GetdocumentationdData(currentClient, propertyId));
  }, [dispatch, currentClient]);

  useEffect(() => {
    setLoading(DocumentData.isLoading);
  }, [DocumentData]);

  const NestedData = DocumentData?.BankData?.result?.map((item) => {
    const fy_rent = item?.fy_rent?.map((rent) => {
      return {
        name: 'End Of Year Rental Statement',
        status: rent?.status,
        key: `fy_rent-${rent?.id}`, // Unique key
        date: getYearList()?.find((item) => item?.value === rent?.financial_year)?.label,
        id: rent?.id,
      };
    });
    return {
      ...item,
      Document: [
        {
          name: 'Depreciation',
          date: item?.depreciation?.created_at ? dayjs(item?.depreciation?.created_at).format('DD-MM-YYYY') : '',
          status: item?.depreciation?.status,
          id: item?.depreciation?.id,
          key: `depreciation-${item?.depreciation?.id}`, // Unique key
        },
        {
          name: 'Purchase Settlement',
          date: item?.settlement[0]?.submitted_at ? dayjs(item?.settlement[0]?.submitted_at).format('DD-MM-YYYY') : '',
          status: item?.settlement[0]?.status,
          id: item?.settlement[0]?.id,
          key: `purchase-settlement-${item?.settlement[0]?.id}`, // Unique key
        },
        {
          name: 'Sell Settlement',
          date: item?.settlement[1]?.submitted_at ? dayjs(item?.settlement[1]?.submitted_at).format('DD-MM-YYYY') : '',
          status: item?.settlement[1]?.status,
          id: item?.settlement[1]?.id,
          key: `sell-settlement-${item?.settlement[1]?.id}`, // Unique key
        },
        ...fy_rent,
      ],
      key: item.id, // Ensure each top-level item has a unique key
    };
  });

  const columns = [
    {
      dataIndex: 'name',
      key: 'name',
      width: '20%',
      render: (text, record, index) => {
        return (
          <>
            {record?.id === undefined ? (
              <p className="browseBtn1" key={index}>
                {record.name}
              </p>
            ) : (
              // eslint-disable-next-line
              <a
                className="browseBtn"
                onClick={() =>
                  getBankDocument(
                    record?.id,
                    record.name === 'Purchase Settlement' || record.name === 'Sell Settlement'
                      ? DocumentationReciptType.SETTLEMENT
                      : record.name === 'End Of Year Rental Statement'
                      ? 3
                      : DocumentationReciptType.DEPRECIATION,
                  )
                }
                key={index}
              >
                {record.name}
              </a>
            )}
          </>
        );
      },
    },
    {
      dataIndex: 'date',
      key: 'date',
      width: '20%',
      render: (_text, record, index) => {
        return record?.date ? <span key={index}>{record?.date}</span> : <span key={index}>-</span>;
      },
    },
    {
      dataIndex: 'status',
      key: 'status',
      width: '20%',
      render: (_text, record, index) => {
        return record?.id ? (
          <span key={index}>
            <Badge status="success" className="me-2" />
            Completed
          </span>
        ) : (
          <span key={index}>
            <Badge status="warning" className="me-2" />
            Pending
          </span>
        );
      },
    },
  ];

  const getBankDocument = (id, type) => {
    setLoading(true);
    dispatch(GetReciptdocumentationdData(id, currentClient, type)).then((res) => {
      if (res?.payload?.success) {
        setLoading(false);
        window.open(res?.payload?.result?.url, '_blank');
      } else {
        notification.error({
          message: 'Failure',
          description: res?.payload.message || 'Something went wrong!',
        });
        setLoading(false);
      }
    });
  };

  return <Table loading={loading} columns={columns} showHeader={false} dataSource={NestedData?.[0]?.Document || []} pagination={false} bordered={false} />;
};

export default PropertyDocumentation;
