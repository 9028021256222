import React, { useEffect, useRef, useState } from 'react';
import { Alert, Layout, theme, Tour } from 'antd';
import Home from './pages/home';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import { changeProductTour } from '../redux/actions/Header/productTour';
import { getNotificationData } from '../redux/actions/Header/getNotificationData.action';
import HomeHeader from './header';
import Sidebar from './sidebar';
import TaxAccountantSubSidebar from './sidebar/TaxAccountantSubSidebar';
import { UserTypes } from './common/userTypes';

const { Sider, Content } = Layout;

const App = (props) => {
  const [collapsed, setCollapsed] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const notificationDta = useSelector((state) => state.getNotificationDataReducer);
  const ref1 = useRef({});
  const [open, setOpen] = useState(false);
  const [userData, setUserData] = useState({});
  const { pathname } = useLocation();

  const userDetails = JSON.parse(localStorage.getItem('admin')) || {};

  const isTaxAccountant = userDetails?.user_type === UserTypes.TAXACCOUTANT || userDetails?.user_type === UserTypes.SUB_TAXACCOUTANT;

  const [view, setView] = useState(false);

  useEffect(() => {
    if (pathname) {
      pathname.split('/').includes('clients') && pathname.split('/').includes('client') && isTaxAccountant ? setView(true) : setView(false);
    }
  }, [pathname, isTaxAccountant]);

  useEffect(() => {
    if (notificationDta && notificationDta?.data.result && notificationDta.error === false) {
      setUserData(notificationDta?.data.result.userData || {});
    }
  }, [notificationDta]);

  const setProductTour = (value) => {
    setOpen(value);
  };

  const closeProductTour = () => {
    setOpen(false);
    dispatch(changeProductTour()).then((res) => {
      if (res?.result?.success === true) {
        dispatch(getNotificationData());
      }
    });
  };

  const steps = [
    {
      title: 'Add Property',
      description: 'Set up your property profile from here.',
      target: () => ref1.current.home_property_btn,
    },
    {
      title: 'Document for Set Up Process',
      description: (
        <>
          Here you can check list of documents and task needed to complete the property profile set up e.g. <br />
          <br />
          • Advise your property manager add Unique ID for monthly rental statement <br />
          • Email Settlement Statement to Unique ID <br />
          • Email Depreciation Schedule to Unique ID <br />
          • Connect Bank Loan Accounts <br />
          <br />
          Don't forget to click "View More" to check the pending document/tasks all properties.
        </>
      ),
      target: () => ref1.current.home_pending_task,
    },
    {
      title: 'Expenses directly paid by you',
      description: (
        <>
          Add all your property related income and expenses directly paid / incurred by you. <br />
          Don't enter costs that are already in your Settlement Statement, Depreciation Schedule, or Monthly Rental Summary.
          <br />
          We automatically track and record costs from these documents for you.
        </>
      ),
      target: () => ref1.current.home_property_income_btn,
    },
    {
      title: 'Loan Summary',
      description: 'View Interest Rate, EMI and Available Redraw accross all your loans in real time.',
      target: () => ref1.current.home_loan_overview,
    },
    {
      title: 'Notification',
      description: `Stay updated with our our handy notifications.`,
      target: () => ref1.current.header_notification,
    },
    // {
    //   title: 'Help !',
    //   description: `Help using faqs and videos.`,
    //   target: () => ref1.current.header_help,
    // },
    {
      title: 'Your Profile',
      description: 'Update your profile and add Tax Accountant to share your records.',
      target: () => ref1.current.header_profile,
    },
    {
      title: 'Property',
      description: `View and edit details of all your properties.`,
      target: () => ref1.current.menu_property,
    },
    {
      title: 'Transaction',
      description: `View, Search and Edit transactions.`,
      target: () => ref1.current.menu_transactions,
    },
    {
      title: 'Report',
      description: `Email/ Download your Income & Expenses and Costs Base reports.`,
      target: () => ref1.current.menu_reports,
    },
    {
      title: 'Contact us',
      description: `Your can send us a whatsapp message with your query and we will resolve that quickly`,
      target: () => ref1.current.home_whatsup_btn,
    },
    {
      title: 'Thank you!',
      description: `We're excited to have you onboard and look forward to helping you make the most of your experience! 
      Here's a video that will give you a quick overview our account setup process.`,
      cover: (
        <iframe
          width="460"
          height="215"
          src="https://www.youtube.com/embed/hXnCkTxO7Mg?si=TOfKHkxrvUt3oOUU"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
      ),
    },
  ];

  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  return (
    <>
      {userData.subscription && (
        <div className="warning_div">
          {userData.subscription && userData.subscription_free_trail_expire_in ? (
            <Alert
              showIcon={false}
              className="text-center"
              message={
                <>
                  Your free trial ends in {userData.subscription_free_trail_expire_in || 1} {(userData.subscription_free_trail_expire_in || 1) > 1 ? 'days' : 'day'}.{' '}
                  <u style={{ cursor: 'pointer' }} onClick={() => navigate('/subscription')}>
                    Subscribe now
                  </u>{' '}
                  to unlock financial insights and simplified tax reporting.
                </>
              }
              banner
            />
          ) : (
            <Alert
              type="error"
              showIcon={false}
              className="text-center"
              message={
                <>
                  Your free trial has ended.{' '}
                  <u style={{ cursor: 'pointer' }} onClick={() => navigate('/subscription')}>
                    {' '}
                    Subscribe now
                  </u>{' '}
                  to unlock financial insights and simplified tax reporting.
                </>
              }
              banner
            />
          )}
        </div>
      )}

      <div className={`page-wrapper landrick-theme toggled ${userData.subscription ? 'header-alert' : ''}  ${collapsed ? 'resizeHeader' : ''}`} id="side__bar">
        <Layout className="h-100">
          <Sider
            width={230}
            trigger={null}
            className="bg-white"
            collapsible
            collapsed={view ? true : collapsed}
            style={{
              position: 'fixed',
              height: '100%',
              left: 0,
              zIndex: 1000,
              overflow: 'hidden',
            }}
          >
            <Sidebar ref={ref1} collapsed={view ? true : collapsed} view={view} />
          </Sider>
          {view && (
            <Sider
              width={214}
              trigger={null}
              className="bg-white"
              collapsible
              collapsed={collapsed}
              style={{
                position: 'fixed',
                height: '100%',
                left: 80,
                zIndex: 1000,
                overflowY: 'auto',
                overflowX: 'hidden',
              }}
            >
              <TaxAccountantSubSidebar view={view} setCollapsed={setCollapsed} collapsed={collapsed} />
            </Sider>
          )}
          {/* <Button
            className="firstSidebarNavigation"
            type="text"
            icon={collapsed ? <RightCircleOutlined /> : <LeftCircleOutlined />}
            onClick={() => setCollapsed(!collapsed)}
            style={{
              fontSize: '16px',
              left: view ? (collapsed ? 141 : 273) : collapsed ? 60 : 213,
            }}
          /> */}
          <Layout
            style={{
              marginLeft: view ? (collapsed ? 161 : 293) : collapsed ? 80 : 233,
            }}
          >
            <main className="page-content bg-light">
              <HomeHeader ref={ref1} productTour={setProductTour} collapsed={collapsed} setCollapsed={setCollapsed} view={view} />
            </main>
            <div className={` ${isTaxAccountant && view ? 'mt-1 pt-2' : 'mt-3 pt-2'} `}>
              <Content
                className="custom-scroll hide-scrollbar page-content"
                style={{
                  background: colorBgContainer,
                  borderRadius: borderRadiusLG,
                  minHeight: '100%',
                }}
              >
                {props.key_name && props.key_name === 'home' ? <Home ref={ref1} /> : props.comp}
              </Content>
            </div>
          </Layout>
        </Layout>
      </div>
      <Tour
        open={open}
        onClose={() => closeProductTour()}
        steps={steps}
        indicatorsRender={(current, total) => (
          <span>
            {current + 1} / {total}
          </span>
        )}
      />
    </>
  );
};
export default App;
