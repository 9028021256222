import { CopyOutlined, FolderOpenOutlined, RightOutlined } from '@ant-design/icons';
import { Avatar, Button, Col, Flex, Modal, notification, Popover, Row, Space } from 'antd';
import dollorDashboard from '../../../assets/images/Transaction.svg';
import propertyDashboard from '../../../assets/images/property.svg';
import reportDashboard from '../../../assets/images/Report.svg';
import bankIcon from '../../../assets/images/bank.svg';
import { useNavigate } from 'react-router';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useRef, useState } from 'react';

export default function HowWorks() {
  const ref = useRef();
  const [isOpenEmail, setIsOpenEmail] = useState(false);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const admin = JSON.parse(localStorage.getItem('admin'));

  const hide = () => {
    setOpen(false);
  };
  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };

  const copyText = () => {
    notification.success({
      message: 'Success',
      description: 'Copied.',
    });
  };

  const copyEmailText = () => {
    navigator.clipboard.writeText(ref.current.innerHTML.replace(/<br ?\/?>/g, '\n'));

    notification.success({
      message: 'Success',
      description: 'Text copied.',
    });
  };

  const emailText = () => {
    const encodedEmailContent = encodeURIComponent(ref.current.innerHTML.replace(/<br ?\/?>/g, '\n'));

    // Create the mailto link
    const mailtoLink = `mailto:${admin?.virtual_email}?subject=Rent Summary&body=${encodedEmailContent}`;

    // Open the email client with the mailto link
    window.location.href = mailtoLink;
  };

  const addPropertyContent = (
    <div>
      <span>Update your property details:</span>
      <ul>
        <li>
          <b>Property Address</b>
        </li>
        <li>
          <b>Select Property Type:</b>
          <ul>
            <li>Owner-Occupied</li>
            <li>Investment - Residential</li>
            <li>Investment - Commercial</li>
          </ul>
        </li>
        <li>
          <b>Select Depreciation Method:</b>
          <ul>
            <li>Diminishing Value</li>
            <li>Prime Cost</li>
          </ul>
        </li>
        <li>
          <b>Bank Loan Details:</b> Provide BSB and Account Number
        </li>
      </ul>
      <span>Click the button below to add your property.</span>
      <div className="pt-2 ml-2">
        <Button onClick={() => navigate('/property/add')} type="primary">
          Add Property Now!
        </Button>
      </div>
    </div>
  );

  const sendDocumentContent = (
    <div>
      <span>
        Email your documents to your{' '}
        <strong>
          Unique ID : {admin?.virtual_email}
          <CopyToClipboard className="pb-0 pe-0" text={admin?.virtual_email} onCopy={() => copyText()}>
            <CopyOutlined />
          </CopyToClipboard>
        </strong>{' '}
        for automatic recording:
      </span>
      <ul>
        <li>
          <b>Depreciation Schedule</b>
        </li>
        <li>
          <b>Settlement Statement</b> received from your conveyancer at the time of purchase or sale
        </li>
        <li>
          <b>Rent Statements:</b>
          <ul>
            <li>End-of-Year Rent Summary for previous financial year</li>
            <li>Ask your property manager to send future rental summaries directly to your Unique ID.</li>
          </ul>
        </li>
      </ul>
      <div className="pt-2 ml-2">
        <Button
          onClick={() => {
            setIsOpenEmail(true);
            hide();
          }}
          type="primary"
        >
          Email Now!
        </Button>
      </div>
    </div>
  );

  const connectBankContent = (
    <div>
      <span>
        Connect your loan accounts from over 100 banks and lenders through secure Open Banking governed by the ACCC. <br />
        This ensures automatic recording of interest, bank fees, and other charges.
      </span>
      <ul>
        <li>
          <b>Only connect your property loan accounts.</b>
        </li>
        <li>
          <b>No need to connect credit cards, transaction accounts, or offset accounts—</b> this keeps your sensitive data private.
        </li>
        <li>
          <b>No online banking password needed.</b>
        </li>
        <li>
          <b>Once connected, we'll receive data for the past 2 years plus live updates in the future.</b>
        </li>
      </ul>
      <span>Click the button below to connect your accounts.</span>
      <div className="pt-2 ml-2">
        <Button onClick={() => navigate('/bank')} type="primary">
          Connect Now!
        </Button>
      </div>
    </div>
  );

  const addExpenseContent = (
    <div>
      <span>Easily record expenses you've paid directly (e.g., land tax, insurance, planning permits, capital improvements) using our mobile app or web portal in less than 20 seconds</span>

      <ul>
        <li>
          <b>Steps:</b>
          <ol>
            <li>
              <b>Scan or upload the invoice/receipt.</b>
            </li>
            <li>
              <b>Select the property from the drop-down list.</b>
            </li>
            <li>
              <b>Choose the expense or cost category from the drop-down list.</b>
            </li>
            <li>
              <b>Enter the amount.</b>
            </li>
          </ol>
        </li>
      </ul>
      <span>Click the button below to add an expense.</span>
      <div className="pt-2 ml-2">
        <Button onClick={() => navigate('/record-expenses')} type="primary">
          Add Expense!
        </Button>
      </div>
    </div>
  );

  const reportContent = (
    <div>
      <span>Create reports and analyses in few clicks:</span>
      <ul>
        <li>
          <b>Income & Expense Reports:</b>
          <ul>
            <li>View your property's profit and loss for any period you select—year, month, or specific dates.</li>
          </ul>
        </li>
        <li>
          <b>Cost Base Reports:</b>
          <ul>
            <li>Know your actual property costs for an accurate cost base and Capital Gains Tax when selling.</li>
          </ul>
        </li>
        <li>
          <b>Loan Summary Reports:</b>
          <ul>
            <li>See details of your loan accounts across all banks, updated every 6 hours.</li>
          </ul>
        </li>
      </ul>
      <span>Click the button below to review your reports.</span>
      <div className="pt-2 ml-2">
        <Button onClick={() => navigate('/reports')} type="primary">
          Review Report!
        </Button>
      </div>
    </div>
  );

  return (
    <>
      <Row className="mt-4 mb-3 pt-2 card_wrapper" gutter={20}>
        <Col span={24}>
          <h5 className="m-0 mb-3">How it works? Step by Step Process</h5>
          <div className="card">
            <div className="card-body">
              <Flex justify="space-between" align="center">
                <Popover
                  title={
                    <div className="d-flex justify-content-between">
                      <b>Add Property</b>
                      <span>One-time Setup</span>
                    </div>
                  }
                  content={addPropertyContent}
                  trigger="hover"
                >
                  <Space className="cursor-pointer">
                    <Avatar shape="square" style={{ backgroundColor: '#f2f8f8', color: '#56aaaa !important', verticalAlign: 'middle' }} size="large">
                      <img style={{ filter: 'brightness(0.9) invert(0.6) sepia(0.6) hue-rotate(132deg) saturate(200%)' }} width={20} src={propertyDashboard} alt="" />
                    </Avatar>
                    <h6 className="m-0">Add Property</h6>
                  </Space>
                </Popover>
                <Popover
                  title={
                    <div className="d-flex justify-content-between">
                      <b>Email Property-Related Documents</b>
                      <span>One-time Setup</span>
                    </div>
                  }
                  content={sendDocumentContent}
                  trigger="hover"
                  open={open}
                  onOpenChange={handleOpenChange}
                >
                  <Space className="cursor-pointer">
                    <RightOutlined style={{ color: '#dae0e5' }} />
                    <Avatar shape="square" style={{ backgroundColor: '#f2f8f8', color: '#56aaaa !important', verticalAlign: 'middle' }} size="large">
                      <FolderOpenOutlined style={{ fontSize: 20, color: '#56aaaa' }} />
                    </Avatar>
                    <h6 className="m-0">Send Property Documents</h6>
                  </Space>
                </Popover>
                <Popover
                  title={
                    <div className="d-flex justify-content-between">
                      <b>Connect Bank Loan Account</b>
                      <span>One-time Setup</span>
                    </div>
                  }
                  content={connectBankContent}
                  trigger="hover"
                >
                  <Space className="cursor-pointer">
                    <RightOutlined style={{ color: '#dae0e5' }} />
                    <Avatar shape="square" style={{ backgroundColor: '#f2f8f8', color: '#56aaaa !important', verticalAlign: 'middle' }} size="large">
                      <img style={{ filter: 'brightness(0.9) invert(0.6) sepia(0.6) hue-rotate(132deg) saturate(200%)' }} width={20} src={bankIcon} alt="" />
                    </Avatar>
                    <h6 className="m-0">Connect your Bank</h6>
                  </Space>
                </Popover>
                <Popover
                  title={
                    <div className="d-flex justify-content-between">
                      <b>Add Day-to-Day Expenses</b>
                      <span>Ongoing</span>
                    </div>
                  }
                  content={addExpenseContent}
                  trigger="hover"
                >
                  <Space className="cursor-pointer">
                    <RightOutlined style={{ color: '#dae0e5' }} />
                    <Avatar shape="square" style={{ backgroundColor: '#f2f8f8', color: '#56aaaa !important', verticalAlign: 'middle' }} size="large">
                      <img style={{ filter: 'brightness(0.9) invert(0.6) sepia(0.6) hue-rotate(132deg) saturate(200%)' }} width={20} src={dollorDashboard} alt="" />
                    </Avatar>
                    <h6 className="m-0">Add Day-to-Day Expenses</h6>
                  </Space>
                </Popover>
                <Popover
                  title={
                    <div className="d-flex justify-content-between">
                      <b>Generate Reports</b>
                    </div>
                  }
                  content={reportContent}
                  trigger="hover"
                >
                  <Space className="cursor-pointer">
                    <RightOutlined style={{ color: '#dae0e5' }} />
                    <Avatar shape="square" style={{ backgroundColor: '#f2f8f8', color: '#56aaaa !important', verticalAlign: 'middle' }} size="large">
                      <img style={{ filter: 'brightness(0.9) invert(0.6) sepia(0.6) hue-rotate(132deg) saturate(200%)' }} width={20} src={reportDashboard} alt="" />
                    </Avatar>
                    <h6 className="m-0">Generate Reports</h6>
                  </Space>
                </Popover>
              </Flex>
            </div>
          </div>
        </Col>
      </Row>
      {/* EMAILNOW MODAL */}
      <Modal onCancel={() => setIsOpenEmail(false)} footer={null} centered width={550} style={{ zIndex: '1005', borderRadius: '1em' }} className="txtAccountentModal" open={isOpenEmail}>
        <div ref={ref}>
          Dear Property Manager, <br />
          <br />
          How are you? I hope you are doing well.
          <br />
          <br />
          Going forward, please add my below email to send my monthly rental summary for all my properties "{admin?.virtual_email}"
          <br />
          <br />
          Property Details as below : <br />
          <b>[Your Property Name]</b>
          <br />
          <br />
          Please confirm by email once this has been action. <br />
          <br />
          Have a nice day <br />
          Regards
        </div>

        <div className="p-2 text-end">
          <Button className="copy_btn3" onClick={() => copyEmailText()}>
            Copy
          </Button>
          <Button className="copy_btn1" onClick={() => emailText()}>
            Send Mail
          </Button>
        </div>
      </Modal>
    </>
  );
}
