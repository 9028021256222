import dashboardSVG from '../images/Home.svg'
import depreciationSVG from '../images/depreciation.svg'
import propertySettlementSVG from '../images/hand-shake.svg'
import propertyValuationsSVG from '../images/dollar-house.svg'
import appSVG from '../images/logo.svg'
import appFaviconImg from '../images/favicon .ico'
import eyeSVG from '../images/eye.svg'
import plusSVG from '../images/plus.svg'
import deleteSVG from '../images/trash.svg'
import pencilSVG from '../images/edit.svg'


export const AppIcon = (props) => {
    return ( 
        <img src={appSVG} alt="app__logo" {...props}  />
    )
}
export const AppFavicon = (props) => {
    return ( 
        <img src={appFaviconImg} alt="app__logo" {...props}  />
    )
}

export const DashboardIcon = () => {
    return (
        <img src={dashboardSVG} className='me-3' alt="dashboard" />
    )
}

export const DepreciationIcon = () => {
    return (
        <img src={depreciationSVG} className='me-3' alt="dashboard" />
    )
}

export const PropertySettlementIcon = () => {
    return (
        <img src={propertySettlementSVG} className='me-3' alt="dashboard" />
    )
}

export const PropertyValuationsIcon = () => {
    return (
        <img src={propertyValuationsSVG} className='me-3' alt="dashboard" />
    )
}

export const EyeIcon = (props) => {
    return (
        <img alt="app__logo" src={eyeSVG} {...props}/>
    )
}

export const PlusIcon = (props) => {
    return (
        <img alt="app__logo" src={plusSVG} {...props}/>
    )
}

export const DeleteIcon = (props) => {
    return (
        <img alt="app__logo" src={deleteSVG} {...props}/>
    )
}

export const PencilIcon = (props) => {
    return (
        <img alt="app__logo" src={pencilSVG} {...props}/>
    )
}

