import { FrownOutlined } from '@ant-design/icons';
import { notification, Spin } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import logosvg from '../../../assets/images/logo.svg';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { ChannelPartnerType, UserTypes } from '../../common/userTypes';
import { fetchAuthSession } from 'aws-amplify/auth';
import { I18n } from '@aws-amplify/core';
import { Authenticator, View, useTheme, Image, Text, Heading, useAuthenticator, Input, VisuallyHidden, Button } from '@aws-amplify/ui-react';
import { verifyUser } from '../../../redux/actions/login/verify.action';
import '@aws-amplify/ui-react/styles.css';
import './index.less';
import { getUserUsingToken } from '../../../redux/actions/login/userLogin.action';

I18n.setLanguage('en');
I18n.putVocabulariesForLanguage('en', {
  'Sign in': 'Sign In', // Button label
});

const Login = () => {
  const { user } = useAuthenticator((context) => [context.user]);
  let { type, token } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const landing_url = process.env.REACT_APP_LANDING_PAGE_URL;
  const [loading, setLoading] = useState(token ? true : false);
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [phone_number, setPhoneNumber] = useState('');
  const [dialCode, setDialCode] = useState('+61');
  const dialCodes = ['+61', '+64', '+65', '+91'];

  useEffect(() => {
    if (token) {
      const fetchData = getUserUsingToken(token);
      fetchData().then((res) => {
        if (res?.success) {
          const { email, phone_number, name } = res?.result;
          setEmail(email);
          setName(name);
          if (phone_number) {
            const foundCode = dialCodes.find((code) => phone_number.startsWith(code));
            if (foundCode) {
              setDialCode(foundCode);
              setPhoneNumber(phone_number.slice(foundCode.length));
            } else {
              setPhoneNumber(phone_number);
            }
          }
        } else {
          notification.error({
            message: 'Failure',
            description: res?.message || 'Something went wrong!',
          });
        }
        setLoading(false);
      });
    }
  }, [token]);

  const verifyUserUsingJwtToken = useCallback(async () => {
    try {
      const { accessToken } = (await fetchAuthSession()).tokens ?? {};
      const userType = type === 'tax-accountant' ? 2 : type === 'channel-partner' ? 3 : 1;
      const values = { token: accessToken.toString(), userType };
      const res = await dispatch(verifyUser(values));

      if (res?.payload?.success === true) {
        const userTypes = [UserTypes.TAXACCOUTANT, UserTypes.SUB_TAXACCOUTANT, UserTypes.USER, UserTypes.CHANNEL_PARTNER, UserTypes.SUB_CHANNEL_PARTNER];
        if (userTypes.includes(res.payload.result.user_type)) {
          const { result } = res.payload;
          localStorage.setItem('admin', JSON.stringify(result));
          localStorage.setItem('token', result.accessToken);
          if (result.is_login === false) {
            navigate('/term-conditions', { replace: true });
          } else if ((result.user_type === UserTypes.CHANNEL_PARTNER && result.channel_partner_type === ChannelPartnerType.MORTGAGE_BROKER) || result.user_type === UserTypes.TAXACCOUTANT) {
            navigate('/dashboard', { replace: true });
          } else {
            navigate('/', { replace: true });
          }
        } else {
          localStorage.clear();
          window.open(landing_url, '_self');
        }
      } else {
        notification.open({
          message: 'Failure',
          description: res?.payload?.message || 'Something went wrong!',
          icon: <FrownOutlined style={{ color: 'red' }} />,
        });
        localStorage.clear();
        window.open(landing_url, '_self');
      }
    } catch (error) {
      console.log('error', error);
    }
  }, [dispatch, type, navigate, landing_url]);

  useEffect(() => {
    if (type && !['user', 'tax-accountant', 'channel-partner'].includes(type)) {
      notification.error({
        message: 'Failure',
        description: 'Something went wrong!',
      });
      localStorage.clear();
      window.open(landing_url, '_self');
    }
    if (user) {
      if (user.username && type && ['user', 'tax-accountant', 'channel-partner'].includes(type)) {
        verifyUserUsingJwtToken();
      } else {
        notification.error({
          message: 'Failure',
          description: 'Something went wrong!',
        });
        localStorage.clear();
        window.open(landing_url, '_self');
      }
    }
  }, [user, type, landing_url, verifyUserUsingJwtToken]);
  const formFields = {
    signIn: {
      username: {
        label: 'Email',
        placeholder: 'Enter your Email',
        isRequired: true,
      },
    },
    signUp: {
      username: {
        label: 'Email',
        placeholder: 'Enter your Email',
        isRequired: true,
        order: 1,
        defaultValue: email, // Set the email value
      },
      name: {
        label: 'Name',
        placeholder: 'Enter your Name',
        isRequired: true,
        order: 2,
        defaultValue: name,
      },
      phone_number: {
        label: 'Phone Number',
        placeholder: '412345678',
        isRequired: true,
        order: 3,
        dialCode: dialCode,
        dialCodeList: ['+61', '+64', '+65', '+91'],
        defaultValue: phone_number,
      },
      password: {
        label: 'Password',
        placeholder: 'Enter your Password',
        isRequired: true,
        order: 4,
      },
    },
    forceNewPassword: {
      password: {
        placeholder: 'Enter your Password',
      },
    },
    forgotPassword: {
      username: {
        label: 'Email',
        placeholder: 'Enter your Email',
        isRequired: true,
      },
    },
    confirmResetPassword: {
      confirmation_code: {
        placeholder: 'Enter your Confirmation Code',
        label: 'Confirmation Code',
        isRequired: true,
      },
      confirm_password: {
        placeholder: 'Enter your Password Please',
      },
    },
    setupTotp: {
      QR: {
        totpIssuer: 'test issuer',
        totpUsername: 'amplify_qr_test_user',
      },
      confirmation_code: {
        label: 'Confirmation Code',
        placeholder: 'Enter your Confirmation Code',
        isRequired: true,
      },
    },
    confirmSignIn: {
      confirmation_code: {
        label: 'Confirmation Code',
        placeholder: 'Enter your Confirmation Code',
        isRequired: true,
      },
    },
    confirmVerifyUser: {
      confirmation_code: {
        label: 'Confirmation Code',
        placeholder: 'Enter your Confirmation Code',
        isRequired: true,
      },
    },
  };

  const components = {
    SignIn: {
      Header() {
        const { tokens } = useTheme();
        const { toSignUp } = useAuthenticator();

        return (
          <>
            <View textAlign="center" padding={tokens.space.large}>
              <Image alt="Amplify logo" src={logosvg} />
            </View>
            <Heading padding={`0 ${tokens.space.xl}`} level={4}>
              Sign In
            </Heading>
            <Text padding={`0 ${tokens.space.xl}`} className="creat_account">
              Enter your account details below or{' '}
              <button className="link-button" onClick={toSignUp} type="button">
                <b> Sign Up</b>
              </button>
            </Text>
          </>
        );
      },
      Footer() {
        const { toSignUp } = useAuthenticator();
        return (
          <>
            <Authenticator.SignIn.Footer />
            <View textAlign="center">
              <button className="link-button" onClick={toSignUp} type="button">
                <b>Create an account</b>
              </button>
            </View>
          </>
        );
      },
    },
    ConfirmSignIn: {
      Header() {
        const { tokens } = useTheme();

        return (
          <>
            <View textAlign="center" padding={tokens.space.large}>
              <Image alt="Amplify logo" src={logosvg} />
            </View>
            <Text className="creat_account">We have delivered the authentication code by SMS to your register mobile number. Please enter the code to complete authentication.</Text>
          </>
        );
      },
    },
    SignUp: {
      Header() {
        const { tokens } = useTheme();
        const { toSignIn } = useAuthenticator();
        return (
          <>
            <View textAlign="center" padding={tokens.space.large}>
              <Image alt="Amplify logo" src={logosvg} />
            </View>
            <Heading padding={`0 ${tokens.space.xl}`} level={4}>
              Create Your Account
            </Heading>
            <Text padding={`0 ${tokens.space.xl}`} className="creat_account">
              Enter your account details below or{' '}
              <button className="link-button" onClick={toSignIn} type="button">
                <b>Sign In</b>
              </button>
            </Text>
          </>
        );
      },
      FormFields() {
        return (
          <>
            {/* Re-use default `Authenticator.SignUp.FormFields` */}
            <Authenticator.SignUp.FormFields />

            {/* Append user type field to sign up, check pre signup lambada for mobile number exist or not?  */}
            <VisuallyHidden>
              <Input name="nickname" value={type === 'tax-accountant' ? 2 : type === 'channel-partner' ? 3 : 1} />
            </VisuallyHidden>
          </>
        );
      },
    },
    ForgotPassword: {
      Header() {
        const { tokens } = useTheme();

        return (
          <>
            <View textAlign="center" padding={tokens.space.large}>
              <Image alt="Amplify logo" src={logosvg} />
            </View>
            <Heading level={4}>Forgot your password?</Heading>
            <Text className="creat_account">Enter your Email below and we will send a message to reset your password.</Text>
          </>
        );
      },
    },
    ConfirmResetPassword: {
      Header() {
        const { tokens } = useTheme();

        return (
          <>
            <View textAlign="center" padding={tokens.space.large}>
              <Image alt="Amplify logo" src={logosvg} />
            </View>
            <Heading level={4}>Reset Password</Heading>
          </>
        );
      },
      Footer() {
        const { toSignIn } = useAuthenticator();
        return (
          <Button type="button" className="amplify-button amplify-field-group__control amplify-button--link amplify-button--small" onClick={toSignIn}>
            Back to Sign In
          </Button>
        );
      },
    },
    VerifyUser: {
      Header() {
        const { tokens } = useTheme();

        return (
          <>
            <View textAlign="center" padding={tokens.space.large}>
              <Image alt="Amplify logo" src={logosvg} />
            </View>
            <Heading level={4}>Account recovery requires verified contact information</Heading>
          </>
        );
      },
      FormFields() {
        return <></>;
      },
    },
    ConfirmVerifyUser: {
      Header() {
        const { tokens } = useTheme();

        return (
          <>
            <View textAlign="center" padding={tokens.space.large}>
              <Image alt="Amplify logo" src={logosvg} />
            </View>
            <Heading level={4}>Account recovery requires verified contact information</Heading>
          </>
        );
      },
    },
    ConfirmSignUp: {
      Header() {
        const { tokens } = useTheme();
        const { codeDeliveryDetail } = useAuthenticator();
        return (
          <>
            <View textAlign="center" padding={tokens.space.large}>
              <Image alt="Amplify logo" src={logosvg} />
            </View>
            <Heading level={4}>We Emailed You</Heading>
            <Text className="creat_account">{codeDeliveryDetail}</Text>
          </>
        );
      },
      Footer() {
        return (
          <Text style={{ color: '#ff4d4f' }}>
            What if I don't get the OTP in email? <br />
            <br />
            <ul>
              <li>Check your spam folder in case the email got caught there.</li>
              <li>Click on "Resend OTP" on the verification page.</li>
              <li>Contact us at support@thepropertyaccountant.com.au if you continue to have trouble.</li>
            </ul>
          </Text>
        );
      },
    },
    ForceNewPassword: {
      Header() {
        const { tokens } = useTheme();
        return (
          <>
            <View textAlign="center" padding={tokens.space.large}>
              <Image alt="Amplify logo" src={logosvg} />
            </View>
            <Heading level={4}>Change Password</Heading>
          </>
        );
      },
    },
  };

  const services = {
    async validateCustomSignUp(formData) {
      if (Object.keys(formData).length > 0 && formData?.phone_number) {
        const country_code = formData?.country_code || '+61';
        const mobileNumberRegex = country_code === '+61' ? /^4\d{8}$/ : /^\d{8,10}$/;
        if (!mobileNumberRegex.test(formData?.phone_number)) {
          return {
            phone_number: 'Please enter valid phone number',
          };
        }
      }
    },
  };

  return (
    <div className="login_wrapper">
      <div className="login_form">
        {loading && (
          <div className="">
            <div className="card-body p-0">
              <div className="modal-body p-4">
                <div className="text-center">
                  <Image alt="The Property Account logo" src={logosvg} />
                  <p className="mt-2"></p>
                  <Spin spinning={true} size="large"></Spin>
                </div>
              </div>
            </div>
          </div>
        )}
        {!loading && (
          <Authenticator hideDefault={true} formFields={formFields} components={components} services={services} initialState={token ? 'signUp' : 'signIn'}>
            {({ signOut, user }) => (
              <div className="">
                <div className="card-body p-0">
                  <div className="modal-body p-4">
                    <div className="text-center">
                      <Image alt="The Property Account logo" src={logosvg} />
                      <p className="mt-2">Retrieving your profile information. Please wait...</p>
                      <Spin spinning={true} size="large">
                        {/* <div>
                          <p>
                            Welcome {user.username} {console.log('user', user)}
                          </p>
                          <button onClick={signOut}>Sign out</button>
                        </div> */}
                      </Spin>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </Authenticator>
        )}
      </div>
    </div>
  );
};

export default Login;
