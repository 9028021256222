import clients from '../../assets/images/clients.svg';
import onboarding from '../../assets/images/onbording.svg';
import subusers from '../../assets/images/subUser.svg';
import reportDashboard from '../../assets/images/Report.svg';
import svgDashboard from '../../assets/images/Home.svg';

import CustomIcon from './custIcon';

const MortgageBrokerNavigationConfig = [
  {
    title: 'Dashboard',
    path: 'dashboard',
    key: 'dashboard',
    icon: <CustomIcon width={20} icon={svgDashboard} />,
  },
  {
    title: 'Clients',
    path: `clients`,
    icon: <CustomIcon width={20} icon={clients} />,
  },
  {
    title: 'Loan Overview',
    path: 'loan-overview',
    icon: <CustomIcon width={20} icon={reportDashboard} />,
  },
  {
    title: 'Invite Clients',
    path: 'onboarding',
    icon: <CustomIcon width={20} icon={onboarding} />,
  },
  {
    title: 'Team Members',
    path: 'sub-users',
    icon: <CustomIcon width={20} icon={subusers} />,
  },
];

export default MortgageBrokerNavigationConfig;
