import React, { Fragment, forwardRef, useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import {
  BellOutlined,
  CopyOutlined,
  FrownOutlined,
  LeftCircleOutlined,
  MailOutlined,
  QuestionCircleOutlined,
  RightCircleOutlined,
  SmileOutlined,
  UserOutlined,
  VideoCameraOutlined,
} from '@ant-design/icons';
import { Form, Modal, notification, Typography, Spin, Input, Popconfirm, Button, Dropdown, Badge, Tabs, Popover, Menu, Checkbox, Avatar } from 'antd';
import avtar from '../../assets/images/avtar2.png';
import downArrowSVG from '../../assets/images/down-arrow.svg';
import { useSelector, useDispatch } from 'react-redux';
import { GetDashboardData } from '../../redux/actions/dashboard/getDashboardData.action';
import CustomeInput from '../../components/common/index';
import { AddTaxAccountant } from '../../redux/actions/login/addTaxAccountant.action';
import CopyToClipboard from 'react-copy-to-clipboard';
import { ManageRevert } from '../../redux/actions/Header/ManageRevert.action';
import { GetAssignTaxuser } from '../../redux/actions/login/getAssignTaxtouser.action';
import { DeleteAssignTaxtoUser } from '../../redux/actions/login/deleteAssignTaxtoUser.action';
import { getNotificationData } from '../../redux/actions/Header/getNotificationData.action';
import { ProductTourType, UserTypes } from '../common/userTypes';
import PhoneInput from 'react-phone-input-2';
import './index.less';
import NoticeList from './NoticeList';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { AddMortgageBroker, DeleteAssignMortgageBroker, GetAssignMortgageBroker } from '../../redux/actions/login/mortgageBroker.action';
import ChangePassword from '../../utils/ChangePassword';
import { getIcon, getTitle } from '../common/NotificationUtil';
import { getApproveUser } from '../../redux/actions/dashboard/getApprove.action';
import { getRejectUser } from '../../redux/actions/dashboard/getReject.action';
import ApplyAsFP from '../../utils/ApplyAsFP';
import ApplyAsTaxAccountant from '../../utils/ApplyAsTaxAccountant';

const Header = forwardRef(function Header(props, tour_ref, collapsed, setCollapsed = () => {}) {
  const [modal, contextHolder] = Modal.useModal();
  const [mortgageBrokerForm] = Form.useForm();
  const { signOut } = useAuthenticator((context) => [context.user]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const [profilerOpen, setProfilerOpen] = useState(false);

  const [isOpenTour, setIsOpenTour] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [spin, setSpin] = useState(false);
  const [spin1, setSpin1] = useState(false);
  const [spin4, setSpin4] = useState(false);
  const [spinAdd, setSpinAdd] = useState(false);
  const [visible, setVisible] = useState(false);
  const [open, setOpen] = useState(false);

  const [assignTax, setAssignTax] = useState([]);
  const [notificationData, setNotificationData] = useState([]);
  const [notiLoading, setNotiLoading] = useState(false);
  const [isOpen1, setIsOpen1] = useState(false);

  const clientDetail = useSelector((state) => state.getClientdetailByIdReducer);

  // Mortgage broker state
  const [isOpenMortgageBroker, setIsOpenMortgageBroker] = useState(false);
  const [mortgageBrokerData, setMortgageBrokerData] = useState([]);

  const [searchParams] = useSearchParams();
  const states = searchParams.get('state');

  const landing_url = process.env.REACT_APP_LANDING_PAGE_URL;

  const { name, email, user_type, phone_number, profile_image_path, company_logo } = localStorage.getItem('admin') ? JSON.parse(localStorage.getItem('admin')) : {};
  let admin = localStorage.getItem('admin');
  let admin1 = JSON.parse(admin);

  const isTaxAccountant = user_type === UserTypes.TAXACCOUTANT || user_type === UserTypes.SUB_TAXACCOUTANT;

  const hideUserDropdown = pathname.split('/').includes('clients') && isTaxAccountant && pathname.split('/').includes('client');

  const notificationDta = useSelector((state) => state.getNotificationDataReducer);
  let userId = 0;
  try {
    const lsData = JSON.parse(localStorage.getItem('admin'));
    userId = lsData.id;
  } catch (error) {}

  const [collapsedPosition, setCollapsedPosition] = useState(null);

  useEffect(() => {
    if (props.view) {
      setCollapsedPosition('295px');
    }
    if (props.collapsed && props.view) {
      setCollapsedPosition('161px');
    }
    if (props.collapsed && !props.view) {
      setCollapsedPosition('81px');
    }
    if (!props.collapsed && !props.view) {
      setCollapsedPosition('231px');
    }
  }, [pathname, props.collapsed, props.view]);

  useEffect(() => {
    if (admin1?.user_type) {
      dispatch(getNotificationData());
    }
  }, [dispatch, admin1?.user_type]);

  useEffect(() => {
    setNotiLoading(notificationDta.isLoading || false);
    if (notificationDta && notificationDta?.data.result && notificationDta.error === false) {
      setNotificationData(notificationDta?.data.result.ActionItemData || []);

      // open notification modal if user is tax accountant
      const tax_accountant_request = notificationDta?.data.result.ActionItemData.find((item) => item.type === 'tax_accountant_request');

      if (!tax_accountant_request && admin1?.user_type === UserTypes.USER && notificationDta?.data?.result?.userData?.product_tour === ProductTourType.PRODUCT_TOUR_PENDING) {
        setIsOpenTour(true);
      }

      if (tax_accountant_request) {
        modal.confirm({
          title: getTitle(tax_accountant_request?.type),
          icon: <p className="mr-2"> {getIcon(tax_accountant_request?.type)} </p>,
          content: (
            <div>
              <p>
                I{' '}
                <Popover
                  content={
                    <>
                      <p>By accepting the Terms & Conditions, you authorize Tax Accountant to access and manage specific property-related data necessary for tax purposes.</p>
                      <p>This includes:</p>
                      <ul>
                        <li>Property Details and Reports</li>
                        <li>Income, Expenses, and Costs associated with the property (including supporting documents)</li>
                        <li>Reports such as Income and Expenses and Cost Base Reports</li>
                        <li>Bank Loan Summary</li>
                      </ul>
                      <p>
                        With this consent, Tax Accountant is permitted to make additions or modifications to property details and expenses.
                        <br /> They may also share this information with their team members for tax preparation and analytical purposes.
                        <br /> Rest assured, your data will be handled securely and with utmost confidentiality.
                      </p>
                    </>
                  }
                  title="Consent to Share Your Property Data"
                  trigger="click"
                >
                  <button className="link-button" type="button">
                    consent
                  </button>
                </Popover>{' '}
                to share my property accounting and financial data with my Tax Accountant {tax_accountant_request?.name}.
              </p>
            </div>
          ),
          okText: 'Approve',
          cancelText: 'Deny',
          onOk() {
            return new Promise((resolve, reject) => {
              handleApprove(tax_accountant_request?.id, 1, resolve, reject);
            }).catch(() => console.log('Oops errors!'));
          },
          onCancel() {
            return new Promise((resolve, reject) => {
              handleReject(tax_accountant_request?.id, 1, resolve, reject);
            }).catch(() => console.log('Oops errors!'));
          },
        });
      }
    }
  }, [notificationDta, admin1?.user_type]);

  // mortgage broker api call
  useEffect(() => {
    if (isOpenMortgageBroker === true) {
      setSpin1(true);
      setSpinAdd(true);
      dispatch(GetAssignMortgageBroker()).then((res) => {
        if (res?.success === true) {
          setMortgageBrokerData(res.result);
        }
        setSpin1(false);
        setSpinAdd(false);
      });
    }
  }, [isOpenMortgageBroker, dispatch]);

  useEffect(() => {
    if (states === 'manage') {
      dispatch(ManageRevert()).then((res) => {
        if (res.payload.success === true) {
          notification.open({
            message: 'Success',
            description: res?.payload?.message,
            icon: (
              <SmileOutlined
                style={{
                  color: '#56AAAA',
                }}
              />
            ),
          });
        } else {
          notification.open({
            message: 'Failure',
            description: res?.payload?.message || 'Something went wrong!',
            icon: (
              <FrownOutlined
                style={{
                  color: 'red',
                }}
              />
            ),
          });
        }
      });
    }
  }, [states, dispatch]);

  const handleApprove = (id, type, resolve, reject) => {
    dispatch(getApproveUser(id, type)).then((res) => {
      if (res.payload.success === true) {
        notification.success({
          message: 'Success',
          description: res.payload.message,
        });
        reloadNotification();
        resolve();
      } else {
        notification.error({
          message: 'Failure',
          description: res.payload.message || 'Something went wrong!',
        });
        reject();
      }
    });
  };

  const handleReject = (id, type, resolve, reject) => {
    notifiationLoading(true);
    setSpin(true);
    dispatch(getRejectUser(id, type)).then((res) => {
      if (res.payload.success === true) {
        notification.success({
          message: 'Success',
          description: res.payload.message,
        });
        reloadNotification();
        resolve();
      } else {
        notification.error({
          message: 'Failure',
          description: res.payload.message || 'Something went wrong!',
        });
        reject();
      }
    });
  };

  const copyText = () => {
    notification.open({
      message: 'Success',
      description: 'Copied.',
      icon: (
        <SmileOutlined
          style={{
            color: '#56AAAA',
          }}
        />
      ),
    });
  };

  const logout = () => {
    signOut();
    localStorage.clear();
    window.open(landing_url, '_self');
  };

  const handleAccountent = async (values) => {
    setSpin1(true);
    delete values.agreement;
    var newValues = {
      ...values,
      mobile: values.mobile ? `+${values.mobile}` : '',
    };
    dispatch(AddTaxAccountant(newValues)).then((data) => {
      if (data.payload.success === true) {
        notification.open({
          message: 'Success',
          description: 'Tax Accountant Added Successfully.',
          icon: (
            <SmileOutlined
              style={{
                color: '#56AAAA',
              }}
            />
          ),
        });
        setIsOpen1(false);
        setSpin1(false);
        dispatch(GetDashboardData(userId));
      } else {
        notification.open({
          message: 'Failure',
          description: data.payload.message || 'Something went wrong!',
          icon: (
            <FrownOutlined
              style={{
                color: 'red',
              }}
            />
          ),
        });
      }
      setSpin1(false);
    });
  };

  const handleMortgageBroker = async (values) => {
    try {
      setSpin1(true);
      delete values.agreement;
      var updatedValues = {
        ...values,
        mobile: values.mobile ? `+${values.mobile}` : '',
      };
      const data = await dispatch(AddMortgageBroker(updatedValues));
      if (data.success === true) {
        notification.open({
          message: 'Success',
          description: 'Mortgage Broker Added Successfully.',
          icon: (
            <SmileOutlined
              style={{
                color: '#56AAAA',
              }}
            />
          ),
        });
        setIsOpenMortgageBroker(false);
        dispatch(GetDashboardData(userId));
      } else {
        notification.open({
          message: 'Failure',
          description: data.message || 'Something went wrong!',
          icon: (
            <FrownOutlined
              style={{
                color: 'red',
              }}
            />
          ),
        });
      }
    } catch (error) {
      notification.open({
        message: 'Failure',
        description: error.message || 'Something went wrong!',
        icon: (
          <FrownOutlined
            style={{
              color: 'red',
            }}
          />
        ),
      });
    } finally {
      setSpin1(false);
    }
  };

  const deleteTaxuser = () => {
    setSpin4(true);
    dispatch(DeleteAssignTaxtoUser()).then((res) => {
      if (res?.payload?.success === true) {
        notification.open({
          message: 'Success',
          description: res.payload.message,
          icon: (
            <SmileOutlined
              style={{
                color: '#56AAAA',
              }}
            />
          ),
        });
        setIsOpen1(false);
        setAssignTax([]);
        setSpin4(false);
      } else {
        notification.open({
          message: 'Failure',
          description: res.payload.message || 'Something went wrong!',
          icon: (
            <FrownOutlined
              style={{
                color: 'red',
              }}
            />
          ),
        });
        setSpin4(false);
      }
    });
  };

  const deleteMortgageBroker = () => {
    setSpin(true);
    dispatch(DeleteAssignMortgageBroker()).then((res) => {
      if (res?.success === true) {
        notification.open({
          message: 'Success',
          description: res?.message,
          icon: (
            <SmileOutlined
              style={{
                color: '#56AAAA',
              }}
            />
          ),
        });
        setIsOpenMortgageBroker(false);
        setMortgageBrokerData([]);
        setSpin(false);
      } else {
        notification.open({
          message: 'Failure',
          description: res?.message || 'Something went wrong!',
          icon: (
            <FrownOutlined
              style={{
                color: 'red',
              }}
            />
          ),
        });
        setSpin(false);
      }
    });
  };

  const reloadNotification = () => {
    dispatch(getNotificationData());
  };

  const notifiationLoading = (value) => {
    setNotiLoading(value);
  };
  const notifiationVisible = (value) => {
    setVisible(value);
  };

  const items = [
    {
      label: 'Videos',
      path: 'video',
      key: 'menu_video',
      icon: <VideoCameraOutlined style={{ fontSize: 15 }} />,
    },
    {
      label: 'FAQs',
      path: 'faqs',
      key: 'menu_faqs',
      icon: <QuestionCircleOutlined style={{ fontSize: 15 }} />,
    },
  ];

  const onHelpClick = (e) => {
    setOpen(false);
    if (e.key === 'menu_video') {
      navigate('/video');
    } else if (e.key === 'menu_faqs') {
      navigate('/faqs');
    }
  };

  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };
  const tabItems = [
    {
      key: 'noti-tab-1',
      label: 'Notification',
      children: <NoticeList list={notificationData} onReloadHandler={reloadNotification} notifiationLoading={notifiationLoading} notifiationVisible={notifiationVisible} />,
    },
  ];
  return (
    <Fragment>
      <div className="top-header" style={{ zIndex: '1000', left: collapsedPosition }}>
        <div className={`header-bar d-flex justify-content-between border-bottom`}>
          <div className="d-flex gap-2 align-items-center">
            <div id="close-sidebar" className="btn btn-icon btn-soft-light toggle_button">
              <Button
                type="text"
                icon={props.collapsed ? <RightCircleOutlined /> : <LeftCircleOutlined />}
                onClick={() => props.setCollapsed(!props.collapsed)}
                style={{
                  fontSize: '16px',
                }}
              />
            </div>

            {/* <div className="d-flex align-items-center">
            {admin1?.user_type === UserTypes.USER ? (
              <Link to="/" className="logo-icon me-3">
                <span className="big">
                  <AppIcon height="30" className="logo-light-mode" />
                  <AppIcon height="30" className="logo-dark-mode" />
                </span>
              </Link>
            ) : (
              <Link to="/" className="logo-icon me-3">
                <span className="big">
                  <AppIcon height="30" className="logo-light-mode" />
                </span>
              </Link>
            )}
            <div id="close-sidebar" className="btn btn-icon btn-soft-light toggle_button" onClick={() => toggleSideBar()}>
              <UnorderedListOutlined />
            </div>
            <div className="search-bar p-0 d-none d-md-block ms-2"></div>
            </div> */}

            {hideUserDropdown && clientDetail?.data?.result && (
              <div className="search-bar p-0  ms-2 mt-0">
                <div className="dropdown-item text-dark">
                  <div className="col-12 text-end virtual_mail_header startingSpan cursor-pointer">
                    <span className="m-0">
                      <strong>Unique ID : </strong> {clientDetail?.data?.result?.virtual_email}
                      <CopyToClipboard className="pb-0 pe-0" text={clientDetail?.data?.result?.virtual_email} onCopy={() => copyText()}>
                        <CopyOutlined />
                      </CopyToClipboard>
                    </span>
                  </div>
                </div>
              </div>
            )}
          </div>

          <ul className="list-unstyled mb-0 d-flex align-items-center me-2 ">
            <li className="list-inline-item mb-0 ms-1 d-none d-sm-block">
              <div className="search-bar p-0 d-none d-md-block ms-2">
                <ul className="list-unstyled mb-0"></ul>
              </div>
            </li>
            {admin1?.user_type === UserTypes.USER && (
              <>
                <li className="list-inline-item mb-0 ms-1 d-none d-sm-none d-md-block">
                  <div className="search-bar p-0  ms-2 mt-0">
                    <div className="dropdown-item text-dark">
                      <div className="col-12 text-end virtual_mail_header">
                        <span className="m-0">
                          <strong>Unique ID : </strong> {admin1.virtual_email}
                          <CopyToClipboard className="pb-0 pe-0" text={admin1.virtual_email} onCopy={() => copyText()}>
                            <CopyOutlined />
                          </CopyToClipboard>
                        </span>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="list-inline-item mb-0 ms-1 d-sm-block">
                  <div className="search-bar p-0  d-md-block ms-2 mt-1">
                    <Popover
                      placement="bottom"
                      content={<Menu items={items} selectedKeys={[`menu_${pathname.split('/').pop()}`]} onClick={onHelpClick} mode="vertical" />}
                      trigger="click"
                      open={open}
                      onOpenChange={handleOpenChange}
                    >
                      <QuestionCircleOutlined className="icon" ref={(ref) => (tour_ref.current.header_help = ref)} />
                    </Popover>
                  </div>
                </li>
                <li className="list-inline-item mb-0 ms-1 d-sm-block    ">
                  <div className="search-bar p-0  d-md-block ms-2 mr-2 mt-1    ">
                    <Dropdown
                      placement="bottomRight"
                      menu={{
                        items: [
                          {
                            key: 'noti-tab-1',
                            label: (
                              <div className="card header-notification">
                                <Spin spinning={notiLoading} delay={300}>
                                  <Tabs className="tabs" items={tabItems} />
                                </Spin>
                              </div>
                            ),
                          },
                        ],
                      }}
                      overlayStyle={{
                        position: 'relative',
                        width: '410px',
                      }}
                      overlayClassName="notification-list"
                      trigger={['click']}
                      open={visible}
                      onOpenChange={(open) => {
                        if (open) {
                          setVisible(true);
                        } else {
                          setVisible(false);
                        }
                      }}
                    >
                      <span className="noticeButton" ref={(ref) => (tour_ref.current.header_notification = ref)}>
                        <Badge count={notificationData.length} style={{ boxShadow: 'none' }} className="noti-badge">
                          <BellOutlined className="icon" />
                        </Badge>
                      </span>
                    </Dropdown>
                  </div>
                </li>
              </>
            )}

            {!hideUserDropdown && (
              <li className="list-inline-item mb-0 ms-1 d-none d-sm-block">
                <div className="dropdown dropdown-primary">
                  <button
                    type="button"
                    className="btn dropdown-toggle p-0 dropdown__toggle "
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    ref={(ref) => (tour_ref.current.header_profile = ref)}
                  >
                    <Avatar src={profile_image_path || company_logo || avtar} size={36} className="me-3" shape={admin1?.user_type === UserTypes.USER ? 'circle' : 'square'} />
                    {name}

                    <img src={downArrowSVG} className="img-fluid ms-3" alt="" />
                  </button>

                  <div className="dropdown-menu dd-menu dropdown-menu-end bg-white shadow border-0 mt-3 py-3" style={{ minWidth: '200px' }}>
                    <button className="dropdown-item d-flex align-items-center text-dark pb-3">
                      <Avatar src={profile_image_path || company_logo || avtar} size="large" shape={admin1?.user_type === UserTypes.USER ? 'circle' : 'square'} />
                      <div className="flex-1 ms-2">
                        <span className="d-block">{email}</span>
                        <small className="text-muted">{phone_number}</small>
                      </div>
                    </button>

                    {(admin1?.user_type === UserTypes.CHANNEL_PARTNER || admin1?.user_type === UserTypes.TAXACCOUTANT) && (
                      <div className="code-box">
                        <div className="dropdown-item text-dark">
                          <div className="col-12 text-end virtual_mail_header">
                            <span className="m-0">
                              <strong>Referral Code : </strong> {admin1.referral_code}
                              <CopyToClipboard className="pb-0 pe-0" text={admin1.referral_code} onCopy={() => copyText()}>
                                <CopyOutlined />
                              </CopyToClipboard>
                            </span>
                          </div>
                        </div>
                      </div>
                    )}

                    {admin1?.user_type === UserTypes.USER && (
                      <div>
                        <div className="dropdown-item text-dark">
                          <div className="col-12 text-end virtual_mail_header">
                            <span className="m-0">
                              {admin1.virtual_email}
                              <CopyToClipboard className="pb-0 pe-0" text={admin1.virtual_email} onCopy={() => copyText()}>
                                <CopyOutlined />
                              </CopyToClipboard>
                            </span>
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="dropdown-divider border-top mt-4"></div>

                    {admin1?.user_type === UserTypes.TAXACCOUTANT && (
                      <>
                        <button className="dropdown-item profile_item text-dark" onClick={() => setProfilerOpen(true)}>
                          <span className="mb-0 d-inline-block me-1">
                            <i className="ti ti-logout"></i>
                          </span>
                          Apply as Financial Planner
                        </button>

                        <ApplyAsFP isOpen={profilerOpen} setIsOpen={setProfilerOpen} />
                      </>
                    )}

                    {admin1?.user_type === UserTypes.USER ? (
                      <>
                        <button className="dropdown-item profile_item text-dark " onClick={() => setIsOpen1(true)}>
                          <span className="mb-0 d-inline-block me-1">
                            <i className="ti ti-logout"></i>
                          </span>
                          Add TaxAccountant
                        </button>
                        <button className="dropdown-item profile_item text-dark " onClick={() => setIsOpenMortgageBroker(true)}>
                          <span className="mb-0 d-inline-block me-1">
                            <i className="ti ti-logout"></i>
                          </span>
                          Add Mortgage Broker
                        </button>
                        <button className="dropdown-item profile_item text-dark" onClick={() => navigate('/subscription', { state: { fromSameSite: true } })}>
                          <span className="mb-0 d-inline-block me-1">
                            <i className="ti ti-logout"></i>
                          </span>
                          Subscription
                        </button>
                      </>
                    ) : (
                      ''
                    )}
                    <button className="dropdown-item profile_item text-dark" onClick={() => setIsOpen(true)}>
                      <span className="mb-0 d-inline-block me-1">
                        <i className="ti ti-logout"></i>
                      </span>
                      Change Password
                    </button>
                    {[UserTypes.SUB_TAXACCOUTANT, UserTypes.TAXACCOUTANT, UserTypes.CHANNEL_PARTNER, UserTypes.SUB_CHANNEL_PARTNER].includes(admin1?.user_type) ? (
                      ''
                    ) : (
                      <button className="dropdown-item profile_item text-dark" onClick={() => navigate('/delete-account')}>
                        <span className="mb-0 d-inline-block me-1">
                          <i className="ti ti-logout"></i>
                        </span>
                        Delete Account
                      </button>
                    )}

                    <button className="dropdown-item profile_item text-dark" onClick={() => logout()}>
                      <span className="mb-0 d-inline-block me-1">
                        <i className="ti ti-logout"></i>
                      </span>
                      Logout
                    </button>
                  </div>
                </div>
              </li>
            )}

            {hideUserDropdown && (
              <>
                <button onClick={() => navigate('/clients/client/record-expenses')} className="btn p-0 px-3 py-2 mr-1 property_btn header_extra_buttons">
                  + Add Expense
                </button>

                <button onClick={() => navigate('/clients/client/property/add')} className="property_btn1 btn btn-success p-0 px-3 py-2 me-2 header_extra_buttons">
                  + Add Property
                </button>
              </>
            )}

            <li className="list-inline-item mb-0 ms-1 d-sm-none">
              <div className="dropdown dropdown-primary">
                <button type="button" className="btn dropdown-toggle p-0" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <Avatar src={profile_image_path || company_logo || avtar} size={36} className="avatar avatar-ex-small" shape={admin1?.user_type === UserTypes.USER ? 'circle' : 'square'} />
                </button>
                <div className="dropdown-menu dd-menu dropdown-menu-end bg-white shadow border-0 mt-3 py-3" style={{ minWidth: '200px' }}>
                  <button className="dropdown-item d-flex align-items-center text-dark pb-3">
                    <Avatar
                      src={profile_image_path || company_logo || avtar}
                      size="large"
                      className="avatar avatar-md-sm  border shadow"
                      shape={admin1?.user_type === UserTypes.USER ? 'circle' : 'square'}
                    />
                    <div className="flex-1 ms-2">
                      <span className="d-block">{email}</span>
                      <small className="text-muted">{phone_number}</small>
                    </div>
                  </button>
                  {admin1?.user_type === UserTypes.USER ? (
                    <div>
                      <div className="dropdown-item text-dark">
                        <div className="col-12 text-start virtual_mail_header mt-1">
                          <span className="m-0" style={{ fontSize: '15px' }}>
                            {admin1.virtual_email}
                            <CopyToClipboard className="pb-0 pe-0" text={admin1.virtual_email} onCopy={() => copyText()}>
                              <CopyOutlined />
                            </CopyToClipboard>
                          </span>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ''
                  )}

                  <div className="dropdown-divider border-top mt-4"></div>
                  {admin1?.user_type === UserTypes.USER ? (
                    <>
                      <button className="dropdown-item text-dark " onClick={() => setIsOpen1(true)}>
                        <span className="mb-0 d-inline-block me-1">
                          <i className="ti ti-logout"></i>
                        </span>
                        Add TaxAccountant
                      </button>
                      <button className="dropdown-item profile_item text-dark " onClick={() => setIsOpenMortgageBroker(true)}>
                        <span className="mb-0 d-inline-block me-1">
                          <i className="ti ti-logout"></i>
                        </span>
                        Add Mortgage Broker
                      </button>
                      <button className="dropdown-item text-dark" onClick={() => navigate('/subscription', { state: { fromSameSite: true } })}>
                        Subscription
                      </button>
                    </>
                  ) : (
                    ''
                  )}
                  <button className="dropdown-item text-dark" onClick={() => setIsOpen(true)}>
                    <span className="mb-0 d-inline-block me-1">
                      <i className="ti ti-logout"></i>
                    </span>
                    Change Password
                  </button>
                  {[UserTypes.SUB_TAXACCOUTANT, UserTypes.TAXACCOUTANT, UserTypes.CHANNEL_PARTNER, UserTypes.SUB_CHANNEL_PARTNER].includes(admin1?.user_type) ? (
                    ''
                  ) : (
                    <button className="dropdown-item text-dark" onClick={() => navigate('/delete-account')}>
                      <span className="mb-0 d-inline-block me-1">
                        <i className="ti ti-logout"></i>
                      </span>
                      Delete Account
                    </button>
                  )}

                  <button className="dropdown-item text-dark" onClick={() => logout()}>
                    <span className="mb-0 d-inline-block me-1">
                      <i className="ti ti-logout"></i>
                    </span>
                    Logout
                  </button>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <ChangePassword isOpen={isOpen} setIsOpen={setIsOpen} spin={spin} setSpin={setSpin} />
      </div>

      {/* MODAL add Tax-accountant */}
      <ApplyAsTaxAccountant isOpen1={isOpen1} setIsOpen1={setIsOpen1} userId={userId} />

      {/* MODAL add Mortgage Broker */}
      <Modal
        onCancel={() => {
          setIsOpenMortgageBroker(false);
          mortgageBrokerForm.resetFields();
        }}
        footer={null}
        centered
        style={{ zIndex: '1005', borderRadius: '1em' }}
        className="txtAccountentModal"
        open={isOpenMortgageBroker}
        destroyOnClose
      >
        <Typography.Text style={{ fontSize: '1.5rem' }}>Mortgage Broker</Typography.Text>
        <Spin spinning={spin1}>
          {spinAdd === false && mortgageBrokerData?.id === undefined && (
            <Form layout="vertical" id="forgotPassword" onFinish={handleMortgageBroker} style={{ marginTop: '2em' }} form={mortgageBrokerForm}>
              <Form.Item
                label="Broker Name"
                name="name"
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    message: 'Please enter Broker Name!',
                  },
                ]}
              >
                <CustomeInput prefix={<UserOutlined className="site-form-item-icon" />} inputtype="alphabetspace" />
              </Form.Item>
              <Form.Item label="Company Name (Optional)" name="companyName">
                <CustomeInput prefix={<SmileOutlined className="site-form-item-icon" />} inputtype="alphabetspace" />
              </Form.Item>
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    message: 'Please enter correct email!',
                    type: 'email',
                  },
                ]}
              >
                <Input prefix={<MailOutlined className="site-form-item-icon" />} />
              </Form.Item>
              <Form.Item
                label="Mobile"
                name="mobile"
                rules={[
                  {
                    required: false,
                    whitespace: true,
                    message: 'Please input your Mobile Number!',
                  },
                  {
                    pattern: new RegExp(/^([0-9]+.?[0-9]*|.[0-9]+)$/),
                    message: 'Please input only digits!',
                  },
                ]}
              >
                <PhoneInput country="au" placeholder="Enter Phone Number" enableSearch />
              </Form.Item>
              <Form.Item
                name="agreement"
                valuePropName="checked"
                className="m-0"
                rules={[
                  {
                    validator: (_, value) => (value ? Promise.resolve() : Promise.reject(new Error('Should accept consent.'))),
                  },
                ]}
              >
                <Checkbox>
                  I{' '}
                  <Popover
                    content={
                      <>
                        <p>By accepting, you consent to share my loan account data with Mortgage Broker.</p>
                        <p>This includes:</p>
                        <ul>
                          <li>Loan Amount</li>
                          <li>Interest Rate</li>
                          <li>Loan Terms</li>
                          <li>Loan Tenure</li>
                          <li>Security for Loan</li>
                        </ul>
                        <p>
                          Your Broker may grant access to their team members to review your loan information and secure the best rates and terms for your loan.
                          <br /> Rest assured, your data will be handled securely and with the utmost confidentiality.
                        </p>
                      </>
                    }
                    title="Consent to Share Loan Data with Mortgage Broker"
                    trigger="click"
                  >
                    <button className="link-button" type="button">
                      consent
                    </button>
                  </Popover>{' '}
                  to share loan data.
                </Checkbox>
              </Form.Item>
              <div className="normal_button text-center mt-1">
                <button size="large" type="submit">
                  Add
                </button>
              </div>
            </Form>
          )}
          {spin1 === false && mortgageBrokerData?.id && (
            <>
              <Spin spinning={spin4}>
                <li className="list-group-item" key={mortgageBrokerData?.id}>
                  <div className="d-sm-flex justify-content-between align-items-center mt-4 mb-3">
                    <div>
                      <h6 className="" key={`mortgageBrokerData-name-${mortgageBrokerData?.id}`}>
                        {mortgageBrokerData.name}
                      </h6>
                      {mortgageBrokerData?.phone_number && (
                        <p className="mb-1 text-muted" key={`mortgageBrokerData-email-${mortgageBrokerData?.email}`}>
                          {mortgageBrokerData?.phone_number}
                        </p>
                      )}

                      <p className="mb-1 text-muted" key={`mortgageBrokerData-email-${mortgageBrokerData?.id}`}>
                        {mortgageBrokerData.email}
                      </p>
                    </div>
                    <div key={crypto.randomUUID()} className="my-3 my-sm-0">
                      <Popconfirm placement="left" title="Are you sure to delete?" onConfirm={() => deleteMortgageBroker()} okText="Yes" cancelText="No">
                        <Button className="btn-danger">Delete</Button>
                      </Popconfirm>
                    </div>
                  </div>
                </li>
              </Spin>
            </>
          )}
        </Spin>
      </Modal>

      {/* MODAL product tour */}
      <Modal
        onCancel={() => setIsOpenTour(false)}
        maskClosable={false}
        closeIcon={null}
        footer={null}
        centered
        style={{ zIndex: '1005', borderRadius: '1em' }}
        className="txtAccountentModal1"
        open={isOpenTour}
      >
        <center>
          <p className="mt-3">
            <Typography.Text style={{ fontSize: '1.5rem' }}>
              Hi {notificationDta?.data?.result?.userData?.name}, <br />
              Welcome to The Property Accountant
            </Typography.Text>
          </p>
          <p className="mt-3" style={{ fontSize: '1rem' }}>
            Unlock the full potential of The Property Accountant? View our product tour explore key features and functionalities.
          </p>
          <div className="justify-content-center d-flex mt-3 ">
            <button
              className="btn property_btn m-2 confirm_btn"
              onClick={() => {
                setIsOpenTour(false);
                props.productTour(true);
              }}
            >
              Get started
            </button>
          </div>
        </center>
      </Modal>

      {contextHolder}
    </Fragment>
  );
});

export default Header;
