import { deleteRequest } from '../../../utils/apiUtils';
import { DeleteUserFailure, DeleteUserRequest, DeleteUserSuccess } from '../../reducers/slices/Header/Deleteuser.slice';

export const DeleteUser = () => {
  return async (dispatch) => {
    await dispatch(DeleteUserRequest());
    const { result, error } = await deleteRequest(`user/account`);

    if (!error) {
      return await dispatch(DeleteUserSuccess(result));
    }
    return await dispatch(DeleteUserFailure(error));
  };
};
