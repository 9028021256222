import clients from '../../assets/images/clients.svg';
import reportDashboard from '../../assets/images/Report.svg';
import CustomIcon from './custIcon';

const MortgageBrokerMemberNavigationConfig = [
  {
    title: 'Clients',
    path: `clients`,
    icon: <CustomIcon width={20} icon={clients} />,
  },
  {
    title: 'Loan Overview',
    path: 'loan-overview',
    icon: <CustomIcon width={20} icon={reportDashboard} />,
  },
];

export default MortgageBrokerMemberNavigationConfig;
