import { Button, Card, Form, Input, List, Modal, Popover, Skeleton, Spin, Typography, notification } from 'antd';
import React, { useEffect, useState } from 'react';
import { getYearList } from '../../../utils';
import { DownloadOutlined, InfoCircleOutlined, LockOutlined, UnlockOutlined } from '@ant-design/icons';
import { downloadTaxWorkingPaper, getLockYearsList, lockYear } from '../../../redux/actions/AuditTrail/getAuditTrailList.action';
import { useDispatch, useSelector } from 'react-redux';
import { sendOtpCheckVerificationCode } from '../../../redux/actions/Header/sendOtp.action';
import { OtpCheckVerificationCode } from '../../../redux/actions/Header/verifyOtp.action';
import { UserTypes } from '../../common/userTypes';

const Lock = () => {
  const [otpForm] = Form.useForm();
  const [loading, setLoading] = useState(true);
  const [otpLoading, setOtpLoading] = useState(false);
  const [lockYears, setLockYears] = useState([]);
  const [lockYearsData, setLockYearsData] = useState([]);
  const [otpId, setOtpId] = useState('');
  const [lockValue, setLockValue] = useState({});
  const [isOpendelconfirm, setIsOpendelconfirm] = useState(false);
  const currentClient = localStorage.getItem('currentClient');
  const [modal, contextHolder] = Modal.useModal();
  const admin = localStorage.getItem('admin');
  const { user_type = null } = admin ? JSON.parse(admin) : {};

  const [otp, setOtp] = useState(Array(6).fill(''));

  const handleChange = (value, index) => {
    if (/^\d?$/.test(value)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      if (value && index < 5) {
        document.getElementById(`otp-input-${index + 1}`).focus();
      }
    }
  };

  const hasStartedTypingOtp = () => {
    return otp.some((digit) => digit !== '');
  };

  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace' && !otp[index] && index > 0) {
      document.getElementById(`otp-input-${index - 1}`).focus();
    }
  };

  const validateOtp = () => {
    return otp.join('').length === 6;
  };

  const dispatch = useDispatch();
  const getLockYearsListDta = useSelector((state) => state.getLockYearsListReducer);

  const tooltipText = (
    <ul className="fs-6">
      <li> Lock the report once your review is complete to prevent further changes. This helps avoid accidental edits to previous periods.</li>
      <li> You can always unlock the report with OTP anytime to make changes</li>
    </ul>
  );

  useEffect(() => {
    if (!getLockYearsListDta?.data?.result) {
      getLockYears();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getLockYears = () => {
    setLoading(true);
    dispatch(getLockYearsList(currentClient)).then((data) => {
      if (data.payload.success) {
        setLockYearsData(data.payload.result || []);
        setLockYears(data.payload.result?.map((item) => item.year) || []);
      }
      setLoading(false);
    });
  };

  const handleLock = (year, status) => {
    // notification for confirm lock
    if (status === 1) {
      modal.confirm({
        title: 'Lock Confirmation',
        content: (
          <>
            <p>
              Once you lock the period (from {`01-07-${year}`} to {`30-06-${year + 1}`}), no edits or changes will be allowed.
            </p>
            <p>This ensures data integrity and control over any future modifications.</p>
            <p>To make changes, you'll need to unlock the report.</p>
          </>
        ),
        icon: <LockOutlined />,
        okText: 'Lock Report',
        okType: 'danger',
        cancelText: 'Cancel',
        onOk: () => lockConfirm(year, status),
      });
    }
    // notification for confirm unlock
    if (status === 0) {
      modal.confirm({
        title: 'Confirm Unlock',
        content: 'Are you sure you want to unlock the report?',
        icon: <UnlockOutlined />,
        okText: 'Unlock Report',
        okType: 'danger',
        cancelText: 'Cancel',
        onOk: () => lockConfirm(year, status),
      });
    }
  };

  const onLock = (year, status) => {
    setLoading(true);
    dispatch(lockYear(year, status, currentClient)).then((data) => {
      if (data.success) {
        notification.success({
          message: 'Success',
          description: data.message,
        });
        getLockYears();
      } else {
        notification.error({
          message: 'Failure',
          description: data.message,
        });
      }
      setLoading(false);
    });
  };

  const handledownloadTaxWorkingPaper = async (record) => {
    setLoading(true);
    dispatch(downloadTaxWorkingPaper(currentClient, record.id)).then((data) => {
      if (data.success === true) {
        notification.success({
          message: 'Success',
          description: data.message,
        });
        const link = document.createElement('a');
        link.href = data.result.url;
        link.download = data.result.url;
        link.click();
        setLoading(false);
      } else {
        notification.error({
          message: 'Failure',
          description: data.message || 'Something went wrong!',
        });
        setLoading(false);
      }
    });
  };

  const lockConfirm = (year, status) => {
    setLoading(true);
    setLockValue({ year, status });
    dispatch(sendOtpCheckVerificationCode(status === 1 ? 'lockreport' : 'unlockreport')).then((res) => {
      if (res.payload.success === true) {
        setOtpId(res.payload.result.otpId);
        setIsOpendelconfirm(true);
        setLoading(false);
      } else {
        notification.error({
          message: 'Failure',
          description: res.payload.message || 'Something went wrong!',
        });
        setLoading(false);
      }
    });
  };

  const handleFinishConfirm = (value) => {
    setOtpLoading(true);
    const finalOtp = otp.join('');
    const values = {
      id: otpId,
      otp: finalOtp,
    };
    dispatch(OtpCheckVerificationCode(values, lockValue.status === 1 ? 'lockreport' : 'unlockreport')).then((res) => {
      if (res.payload.success === true) {
        notification.success({
          message: 'Success',
          description: res.payload.message,
        });
        setOtp(Array(6).fill(''));
        setIsOpendelconfirm(false);
        setOtpLoading(false);
        onLock(lockValue.year, lockValue.status);
      } else {
        notification.error({
          message: 'Failure',
          description: res.payload.message || 'Something went wrong!',
        });
        setOtpLoading(false);
      }
    });
  };

  const isDocumentUploaded = (item) => {
    const lockYearData = lockYearsData?.find((el) => el.year === item.value && el.doc_url !== null);

    return lockYearData;
  };

  return (
    <Card
      title={
        <div className="d-flex align-items-center gap-2">
          <span>Lock Report</span>
          <Popover placement="bottom" title={tooltipText}>
            <InfoCircleOutlined className="cursor-pointer" />
          </Popover>
        </div>
      }
      className="box_shadow mt-3"
    >
      <List
        loading={loading}
        className="demo-loadmore-list"
        itemLayout="horizontal"
        dataSource={getYearList() || []}
        renderItem={(item) => (
          <List.Item>
            <Skeleton avatar title={false} loading={item.loading} active>
              <List.Item.Meta title={item.label} />
              {lockYears.includes(item.value) && !isDocumentUploaded(item) && user_type !== UserTypes.USER && (
                <Popover content="Reports locked!! Tax working paper would be available for download within 15 mins">
                  <Button className="mr-1" icon={<DownloadOutlined />} type="dashed">
                    Download
                  </Button>
                </Popover>
              )}
              {lockYears.includes(item.value) && isDocumentUploaded(item) && user_type !== UserTypes.USER && (
                <Popover content="Download Tax Working Paper">
                  <Button
                    className="mr-1"
                    icon={<DownloadOutlined />}
                    type="default"
                    // disabled={!lockYearsData.find((el) => el.year === item.value && el.doc_url !== null)}
                    onClick={() => handledownloadTaxWorkingPaper(isDocumentUploaded(item))}
                  >
                    Download
                  </Button>
                </Popover>
              )}
              {!lockYears.includes(item.value) && (
                <Button icon={<UnlockOutlined />} type="primary" onClick={() => handleLock(item.value, 1)}>
                  Lock
                </Button>
              )}
              {lockYears.includes(item.value) && (
                <Button className="property_btn1_icon" icon={<LockOutlined />} onClick={() => handleLock(item.value, 0)}>
                  Unlock
                </Button>
              )}
            </Skeleton>
          </List.Item>
        )}
      />
      {/* `contextHolder` should always be placed under the context you want to access */}
      {contextHolder}

      {/* MODAL delete verify Otp */}
      <Modal
        onCancel={() => {
          setIsOpendelconfirm(false);
          setOtp(Array(6).fill(''));
        }}
        footer={null}
        centered
        destroyOnClose
        style={{ zIndex: '1005', borderRadius: '1em' }}
        className="forgotPasswordModal"
        open={isOpendelconfirm}
        maskClosable={false}
      >
        <Spin spinning={otpLoading}>
          <Typography.Text style={{ fontSize: '1.5rem' }}>OTP Verification</Typography.Text>
          <p className="mt-2 ">An OTP (One-Time Password) has been sent to your registered mobile number.</p>
          <Form layout="vertical" form={otpForm} id="forgotPassword" onFinish={handleFinishConfirm} className="mt-3 w-100">
            {/* <Form.Item
              label="OTP"
              name="otpCode"
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: 'Please enter your otp!',
                },
              ]}
            >
              <Input.OTP length={6} />
            </Form.Item> */}
            <div className="">
              <Form.Item
                label="OTP"
                validateStatus={hasStartedTypingOtp() ? (validateOtp() ? 'success' : 'error') : undefined}
                help={hasStartedTypingOtp() && !validateOtp() ? <span className="text-center">OTP must be 6 digits</span> : undefined}
                required
                name="otpCode"
                className="w-100"
              >
                <div className="d-flex justify-content-start gap-2 ">
                  {otp.map((_, index) => (
                    <Input
                      key={index}
                      autoComplete="off"
                      id={`otp-input-${index}`}
                      type="text"
                      maxLength={1}
                      value={otp[index]}
                      onChange={(e) => handleChange(e.target.value, index)}
                      onKeyDown={(e) => handleKeyDown(e, index)}
                      style={{ width: '40px', textAlign: 'center' }}
                      inputMode="numeric"
                      pattern="\d*"
                    />
                  ))}
                </div>
              </Form.Item>
            </div>
            <div className="normal_button text-center">
              <button className={`${!validateOtp() ? 'disabledButton' : 'mt-1'}`} size="large" disabled={!validateOtp()} type="submit">
                Verify
              </button>
              {/* <Button className={`${!validateOtp() ? 'disabledButton' : ''}`} type="primary" htmlType="submit" disabled={!validateOtp()}>
                Verify
              </Button> */}
            </div>
          </Form>
        </Spin>
      </Modal>
    </Card>
  );
};

export default Lock;
