import { MailOutlined, SmileOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Checkbox, Form, Input, Modal, notification, Popconfirm, Popover, Spin, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import CustomeInput from '../components/common/index';

import PhoneInput from 'react-phone-input-2';
import { AddTaxAccountant } from '../redux/actions/login/addTaxAccountant.action';
import { useDispatch } from 'react-redux';
import { GetDashboardData } from '../redux/actions/dashboard/getDashboardData.action';
import { DeleteAssignTaxtoUser } from '../redux/actions/login/deleteAssignTaxtoUser.action';
import { GetAssignTaxuser } from '../redux/actions/login/getAssignTaxtouser.action';

const ApplyAsTaxAccountant = ({ setIsOpen1 = () => {}, isOpen1 = false, userId = null, onReloadHandler = () => {} }) => {
  const [spin1, setSpin1] = useState(false);
  const [spin4, setSpin4] = useState(false);
  const [form] = Form.useForm();
  const [spinAdd, setSpinAdd] = useState(false);
  const [assignTax, setAssignTax] = useState({});

  const dispatch = useDispatch();

  useEffect(() => {
    async function getAssignTax() {
      if (isOpen1 === true) {
        setSpin1(true);
        setSpinAdd(true);
        await dispatch(GetAssignTaxuser()).then((res) => {
          if (res?.payload?.success === true) {
            setAssignTax(res.payload.result);
          }
          setSpin1(false);
          setSpinAdd(false);
        });
      }
    }
    if (userId) {
      getAssignTax();
    }
  }, [isOpen1, userId, dispatch]);

  const handleAccountent = async (values) => {
    setSpin1(true);
    delete values.agreement;
    var newValues = {
      ...values,
      mobile: values.mobile ? `+${values.mobile}` : '',
    };
    dispatch(AddTaxAccountant(newValues)).then((data) => {
      if (data.payload.success === true) {
        notification.open({
          message: 'Success',
          description: 'Tax Accountant Added Successfully.',
          icon: (
            <SmileOutlined
              style={{
                color: '#56AAAA',
              }}
            />
          ),
        });
        if (!userId) {
          onReloadHandler();
        }
        setIsOpen1(false);
        setSpin1(false);
        form.resetFields();
        dispatch(GetDashboardData(userId));
      } else {
        notification.error({
          message: 'Failure',
          description: data.payload.message || 'Something went wrong!',
        });
      }
      setSpin1(false);
    });
  };

  const deleteTaxuser = async () => {
    setSpin4(true);
    await dispatch(DeleteAssignTaxtoUser()).then((res) => {
      if (res?.payload?.success === true) {
        notification.open({
          message: 'Success',
          description: res.payload.message,
          icon: (
            <SmileOutlined
              style={{
                color: '#56AAAA',
              }}
            />
          ),
        });
        setIsOpen1(false);
        setAssignTax([]);
        setSpin4(false);
      } else {
        notification.error({
          message: 'Failure',
          description: res.payload.message || 'Something went wrong!',
        });
        setSpin4(false);
      }
    });
  };

  return (
    <Modal destroyOnClose onCancel={() => setIsOpen1(false)} footer={null} centered style={{ zIndex: '1005', borderRadius: '1em' }} className="txtAccountentModal" open={isOpen1}>
      <Typography.Text style={{ fontSize: '1.5rem' }}>Tax Accountant</Typography.Text>
      <Spin spinning={spin1}>
        {spinAdd === false && assignTax?.id === undefined && (
          <Form form={form} layout="vertical" id="forgotPassword" onFinish={handleAccountent} style={{ marginTop: '2em' }}>
            <Form.Item
              label="Accountant Name"
              name="name"
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: 'Please enter Accountant Name!',
                },
              ]}
            >
              <CustomeInput prefix={<UserOutlined className="site-form-item-icon" />} inputtype="alphabetspace" />
            </Form.Item>
            <Form.Item label="Company Name (Optional)" name="companyName">
              <CustomeInput prefix={<SmileOutlined className="site-form-item-icon" />} inputtype="alphabetspace" />
            </Form.Item>
            <Form.Item
              label="Email"
              name="email"
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: 'Please enter correct email!',
                  type: 'email',
                },
              ]}
            >
              <Input prefix={<MailOutlined className="site-form-item-icon" />} />
            </Form.Item>
            <Form.Item
              label="Mobile"
              name="mobile"
              rules={[
                {
                  required: false,
                  message: 'Please input your Mobile Number!',
                },
                {
                  pattern: new RegExp(/^([0-9]+.?[0-9]*|.[0-9]+)$/),
                  message: 'Please input only digits!',
                },
              ]}
            >
              <PhoneInput country="au" placeholder="Enter Phone Number" enableSearch />
            </Form.Item>
            <Form.Item
              name="agreement"
              valuePropName="checked"
              className="m-0"
              rules={[
                {
                  validator: (_, value) => (value ? Promise.resolve() : Promise.reject(new Error('Should accept consent.'))),
                },
              ]}
            >
              <Checkbox>
                I{' '}
                <Popover
                  content={
                    <>
                      <p>By accepting the Terms & Conditions, you authorize Tax Accountant to access and manage specific property-related data necessary for tax purposes.</p>
                      <p>This includes:</p>
                      <ul>
                        <li>Property Details and Reports</li>
                        <li>Income, Expenses, and Costs associated with the property (including supporting documents)</li>
                        <li>Reports such as Income and Expenses and Cost Base Reports</li>
                        <li>Bank Loan Summary</li>
                      </ul>
                      <p>
                        With this consent, Tax Accountant is permitted to make additions or modifications to property details and expenses.
                        <br /> They may also share this information with their team members for tax preparation and analytical purposes.
                        <br /> Rest assured, your data will be handled securely and with utmost confidentiality.
                      </p>
                    </>
                  }
                  title="Consent to Share Your Property Data"
                  trigger="click"
                >
                  <button className="link-button" type="button">
                    consent
                  </button>
                </Popover>{' '}
                to share your data.
              </Checkbox>
            </Form.Item>
            <div className="normal_button text-center">
              <button size="large" type="submit">
                Add
              </button>
            </div>
          </Form>
        )}

        {spin1 === false && assignTax?.id && (
          <>
            <Spin spinning={spin4}>
              <li className="list-group-item" key={assignTax?.id}>
                <div className="d-sm-flex justify-content-between align-items-center mt-4 mb-3">
                  <div>
                    <h6 className="" key={assignTax?.id}>
                      {assignTax.name}
                    </h6>
                    <p className="mb-1 text-muted" key={assignTax?.id}>
                      {assignTax.email}
                    </p>
                  </div>
                  <div key={crypto.randomUUID()} className="my-3 my-sm-0">
                    <Popconfirm placement="left" title="Are you sure to delete?" onConfirm={() => deleteTaxuser()} okText="Yes" cancelText="No">
                      <Button className="btn-danger">Delete</Button>
                    </Popconfirm>
                  </div>
                </div>
              </li>
            </Spin>
          </>
        )}
      </Spin>
    </Modal>
  );
};

export default ApplyAsTaxAccountant;
