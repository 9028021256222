import { CopyOutlined, HomeOutlined, SmileOutlined } from '@ant-design/icons';
import { notification, Menu, Tooltip, Avatar } from 'antd';
import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { AppIcon } from '../../assets/Icons';
import NavigationConfig from './navigationConfig';
import ChannelPartnerNavigationConfig from './channelPartnerNavigation';
import { ChannelPartnerType, UserTypes } from '../common/userTypes';
import { forwardRef } from 'react';
import MortgageBrokerNavigationConfig from './mortgageBrokerNavigation';
import MortgageBrokerMemberNavigationConfig from './mortgageBrokerMemberNavigation';
import CopyToClipboard from 'react-copy-to-clipboard';
import ChangePassword from '../../utils/ChangePassword';
import avtar from '../../assets/images/avtar2.png';
import TaxNewNavigation from './taxNewNavigation';
import ApplyAsFP from '../../utils/ApplyAsFP';
import { GetValidateClass } from '../../utils';
import SubTaxNavigation from './texNavigationConfig';
import "./homeCss.less"

const Sidebar = forwardRef(function Sidebar(props, ref) {
  const { pathname } = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const [spin, setSpin] = useState(false);

  const { name, email, phone_number, profile_image_path, company_logo } = localStorage.getItem('admin') ? JSON.parse(localStorage.getItem('admin')) : {};

  let admin = localStorage.getItem('admin');
  let admin1 = JSON.parse(admin);

  const TaxNavigation = TaxNewNavigation();

  const TaxSubNavigation = SubTaxNavigation();

  const navigation =
    admin1?.user_type === UserTypes.USER
      ? NavigationConfig
      : admin1?.user_type === UserTypes.SUB_TAXACCOUTANT
      ? TaxSubNavigation
      : admin1?.user_type === UserTypes.SUB_CHANNEL_PARTNER
      ? MortgageBrokerMemberNavigationConfig
      : admin1?.user_type === UserTypes.CHANNEL_PARTNER && admin1?.channel_partner_type === ChannelPartnerType.MORTGAGE_BROKER
      ? MortgageBrokerNavigationConfig
      : admin1?.user_type === UserTypes.CHANNEL_PARTNER
      ? ChannelPartnerNavigationConfig
      : TaxNavigation;

  const copyText = () => {
    notification.open({
      message: 'Success',
      description: 'Copied.',
      icon: (
        <SmileOutlined
          style={{
            color: '#56AAAA',
          }}
        />
      ),
    });
  };

  const landing_url = process.env.REACT_APP_LANDING_PAGE_URL;

  const getRef = (key, refval) => {
    return (ref.current[key] = refval);
  };

  const [profilerOpen, setProfilerOpen] = useState(false);

  const logout = () => {
    localStorage.clear();
    notification.open({
      message: 'Success',
      description: 'Logout successfully',
      duration: 15,
      icon: (
        <SmileOutlined
          style={{
            color: '#56AAAA',
          }}
        />
      ),
    });
    window.open(landing_url, '_self');
  };

  const ForwardedMenuItem = forwardRef((props, ref) => {
    return (
      <div ref={ref} className={props.className}>
        {props.collapsed ? (
          <>
            <Tooltip title={props.label} className="collapsedTooltip" placement="right">
              <Link to={props.path}> {React.cloneElement(props.icon, { className: 'userSiderMenuIcon' })}</Link>
            </Tooltip>
          </>
        ) : (
          <>
            {props.icon}
            <Link to={props.path}>{props.label}</Link>
          </>
        )}
      </div>
    );
  });

  const handleClick = (path) => {
    if (path !== 'transactions') {
      localStorage.removeItem('transactionFilters');
    }
  };

  const overlayStyle = {
    textAlign: 'start',
    padding: '0 10px',
    height: '25px',
    lineHeight: '25px',
  };

  return (
    <>
      <nav
        className={`sidebar-wrapper sidebarNavigationParent`}
        style={{
          background: `${props.collapsed || props.view ? '#56aaaa' : ''}`,
        }}
      >
        <div
          className="d-flex flex-column vh-100 overflow-hidden"
          style={{
            background: `${props.collapsed || props.view ? '#56aaaa' : ''}`,
          }}
        >
          <div style={{ flex: '1', paddingBottom: '10px' }}>
            <div className="sidebar-brand  border-bottom  p-2" style={{ textAlign: 'center' }}>
              {admin1?.user_type === UserTypes.USER ? (
                <Link to="/">
                  {props.collapsed ? (
                    <div className={`minimizedSideBarFirst ${props.view || props.collapsed ? 'text-white' : 'text-black'}`}>
                      <HomeOutlined className="logo-light-mode minimizedSideBarFirst" />
                    </div>
                  ) : (
                    <>
                      <span className="big">
                        <AppIcon height="40" className="logo-light-mode" />
                        <AppIcon height="50" className="logo-dark-mode" />
                      </span>
                    </>
                  )}
                </Link>
              ) : (
                <Link to="/clients" className="logo-icon">
                  <span className="big">
                    {props.collapsed ? (
                      <div className={`minimizedSideBarFirst ${props.view || props.collapsed ? 'text-white' : 'text-black'}`}>
                        <HomeOutlined className="logo-light-mode minimizedSideBarFirst" />
                      </div>
                    ) : (
                      <AppIcon height="40" className="logo-light-mode" />
                    )}
                  </span>
                </Link>
              )}
            </div>

            <Menu
              className="pt-2"
              mode="inline"
              style={{
                background: `${props.collapsed || props.view ? '#56aaaa' : ''}`,
              }}
              selectedKeys={[pathname]}
              items={navigation.map((item) => {
                const path = item.path === 'home' ? '/' : '/' + item.path;

                return {
                  key: path,
                  onClick: () => handleClick(item.path),
                  title: '',
                  className: `${GetValidateClass(item.path)} ${props.view || props.collapsed ? 'minimizedSideBarFirst' : ''}`,
                  label: <ForwardedMenuItem ref={(ref) => getRef(item.key, ref)} collapsed={props.collapsed} className={GetValidateClass(item.path)} icon={item.icon} path={path} label={item.title} />,
                };
              })}
            />
          </div>

          {props.view && (
            <Menu
              inlineCollapsed={true}
              className="siderOverlayMenu"
              style={{
                background: `${props.collapsed || props.view ? '#56aaaa' : ''}`,
              }}
            >
              <Menu.SubMenu
                key="profile"
                className="avatarMenuItem"
                title={
                  <div className={`${!props.collapsed ? 'd-flex align-items-center' : 'm-0 p-0'}`}>
                    <Avatar src={profile_image_path || company_logo || avtar} size="medium" shape={'square'} className="" />

                    {!props.collapsed && <span className="ms-2">{name}</span>}
                  </div>
                }
              >
                <div className="d-flex align-items-center h-100 gap-2 p-3 ">
                  <Avatar src={profile_image_path || company_logo || avtar} size="large" shape={'square'} className="" />
                  <div className="d-flex flex-column gap-3">
                    <span className="fw-normal">{name}</span>
                    <span className="fw-normal">{email}</span>
                    <small className="text-muted">{phone_number}</small>
                  </div>
                </div>

                {(admin1?.user_type === UserTypes.CHANNEL_PARTNER || admin1?.user_type === UserTypes.TAXACCOUTANT) && (
                  <Menu.Item key="2" className="dropdown-item text-dark menuReferralCode">
                    <div className="col-12 text-start">
                      <span className="m-0 d-flex gap-2">
                        <strong>Referral Code: </strong> {admin1.referral_code}
                        <CopyToClipboard className="pb-0 text-start" text={admin1.referral_code} onCopy={() => copyText()}>
                          <CopyOutlined />
                        </CopyToClipboard>
                      </span>
                    </div>
                  </Menu.Item>
                )}
                <Menu.Divider />

                {admin1?.user_type === UserTypes.TAXACCOUTANT && (
                  <Menu.Item className="dropdown-item profile_item text-dark" onClick={() => setProfilerOpen(true)} style={overlayStyle}>
                    <span className="mb-0 d-inline-block me-1">
                      <i className="ti ti-logout"></i>
                    </span>
                    Apply as Financial Planner
                  </Menu.Item>
                )}

                <Menu.Item onClick={() => setIsOpen(true)} key="7" className="dropdown-item profile_item text-dark" style={overlayStyle}>
                  <span className="mb-0 d-inline-block me-1">
                    <i className="ti ti-logout"></i>
                  </span>
                  Change Password
                </Menu.Item>

                <Menu.Item key="9" className="dropdown-item profile_item text-dark" onClick={() => logout()} style={overlayStyle}>
                  <span className="mb-0 d-inline-block me-1">
                    <i className="ti ti-logout"></i>
                  </span>
                  Logout
                </Menu.Item>
                {/* <li className="list-inline-item mb-0 ms-1 d-sm-none">
                  <div className="dropdown dropdown-primary">
                    <div className="dropdown-menu dd-menu dropdown-menu-end bg-white shadow border-0 mt-3 py-3" style={{ minWidth: '200px' }}>
                      <button className="dropdown-item d-flex align-items-center text-dark pb-3">
                        <Avatar
                          src={profile_image_path || company_logo || avtar}
                          size="large"
                          className="avatar avatar-md-sm  border shadow"
                          shape={admin1?.user_type === UserTypes.USER ? 'circle' : 'square'}
                        />
                        <div className="flex-1 ms-2">
                          <span className="d-block">{email}</span>
                          <small className="text-muted">{phone_number}</small>
                        </div>
                      </button>
                      {admin1?.user_type === UserTypes.USER ? (
                        <div>
                          <div className="dropdown-item text-dark">
                            <div className="col-12 text-start virtual_mail_header mt-1">
                              <span className="m-0" style={{ fontSize: '15px' }}>
                                {admin1.virtual_email}
                                <CopyToClipboard className="pb-0 pe-0" text={admin1.virtual_email} onCopy={() => copyText()}>
                                  <CopyOutlined />
                                </CopyToClipboard>
                              </span>
                            </div>
                          </div>
                        </div>
                      ) : (
                        ''
                      )}

                      <div className="dropdown-divider border-top mt-4"></div>

                      <button className="dropdown-item text-dark" onClick={() => logout()}>
                        <span className="mb-0 d-inline-block me-1">
                          <i className="ti ti-logout"></i>
                        </span>
                        Logout
                      </button>
                    </div>
                  </div>
                </li> */}
              </Menu.SubMenu>

              {/* -------------------------------------------------------------------------- */}

              {/* <Menu.Item
              key="logout"
              onClick={logout}
              ref={(ref) => getRef('logout', ref)}
              className={`logout-li mb-2 ${props.view ? 'minimizedSideBarFirst' : ''}`}
              style={{ fontSize: 16, fontWeight: 600 }}
              icon={
                <svg className="me-3" width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M16.8302 6.08667C17.9837 7.24056 18.7692 8.71057 19.0873 10.3108C19.4054 11.9111 19.2419 13.5698 18.6174 15.0771C17.9929 16.5844 16.9354 17.8728 15.5788 18.7792C14.2221 19.6856 12.6272 20.1693 10.9956 20.1693C9.36403 20.1693 7.76908 19.6856 6.41243 18.7792C5.05579 17.8728 3.99836 16.5844 3.37386 15.0771C2.74936 13.5698 2.58582 11.9111 2.90393 10.3108C3.22204 8.71057 4.00751 7.24056 5.16103 6.08667"
                    stroke="black"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path d="M11 1.83331V11" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              }
            >
              <span className="mb-2">Logout</span>
            </Menu.Item> */}
            </Menu>
          )}
        </div>
      </nav>
      <ApplyAsFP isOpen={profilerOpen} setIsOpen={setProfilerOpen} />

      {props.view && (
        <>
          <ChangePassword isOpen={isOpen} setIsOpen={setIsOpen} setSpin={setSpin} spin={spin} />
        </>
      )}
    </>
  );
});

export default Sidebar;
