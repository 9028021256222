import React from 'react';
import onboarding from '../../assets/images/onbording.svg';
import subusers from '../../assets/images/subUser.svg';
import Dashboard from '../../assets/images/Home.svg';
import reportDashboard from '../../assets/images/Report.svg';

import CustomIcon from './custIcon';
import { useSelector } from 'react-redux';

const TaxNewNavigation = () => {
  const notificationDta = useSelector((state) => state.getNotificationDataReducer);
  const isFinancialPlanner = notificationDta?.data?.result?.userData?.is_financial_planner;

  let TexNewNavigationConfig = [
    {
      title: 'Dashboard',
      path: `dashboard`,
      icon: <CustomIcon width={20} icon={Dashboard} />,
    },
    {
      title: 'Invite Clients',
      path: 'onboarding',
      icon: <CustomIcon width={20} icon={onboarding} />,
    },
    {
      title: 'Team Members',
      path: 'sub-users',
      icon: <CustomIcon width={20} icon={subusers} />,
    },
  ];

  if (isFinancialPlanner) {
    TexNewNavigationConfig.push({
      title: 'Loan Overview',
      path: 'loan-overview',
      icon: <CustomIcon width={20} icon={reportDashboard} />,
    });
  }

  return TexNewNavigationConfig;
};

export default TaxNewNavigation;
