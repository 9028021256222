import { Button,  Form, Input, Modal, notification, Popconfirm,  Select, Spin, Table } from 'antd';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { GetSubUserList } from '../../../../redux/actions/tex-dashboard/subUsers/getUserList.action';
import CustomInput from '../../../common';
import CustomIcon from '../../../sidebar/custIcon';
import deleteIcon from '../../../../assets/images/delete.svg';
import editIcon from '../../../../assets/images/edit.svg';
import { GetUserdropList } from '../../../../redux/actions/tex-dashboard/subUsers/getUserdropList.action';
import { AddUserData } from '../../../../redux/actions/tex-dashboard/subUsers/postUserdata.action';
import { FrownOutlined } from '@ant-design/icons';
import { GetUserDataListById } from '../../../../redux/actions/tex-dashboard/subUsers/getsubUserDataById.action';
import { DeleteSubuser } from '../../../../redux/actions/tex-dashboard/subUsers/deleteUser.action';
import PhoneInput from 'react-phone-input-2';
const SubUsers = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [editDrop, setEditDrop] = useState([]);
  const [oldDropData, setOldDropData] = useState([]);
  const [IDS, setIDS] = useState('');
  const [userform] = Form.useForm();
  const [userform1] = Form.useForm();
  const [check, setcheck] = useState(false);
  const [userDropData, setuserDropData] = useState([]);

  const subUserData = useSelector((state) => state.getSubusersListReducer);
  const userDropList = useSelector((state) => state.getuserDropListreducer);

  useEffect(() => {
    dispatch(GetSubUserList());
  }, [dispatch]);

  useEffect(() => {
    setLoading(subUserData.isLoading);
    if (subUserData && subUserData.data.result && subUserData.error === false) {
      let dt = subUserData.data.result.map((elm) => {
        return { ...elm, key: crypto.randomUUID() };
      });
      setData(dt);
    }
    if (userDropList && userDropList.data.result && userDropList.error === false) {
      let dt = userDropList.data.result.map((elm) => {
        return { value: elm.id, label: elm.name };
      });
      setuserDropData(dt);
    }
  }, [subUserData, userDropList]);

  const handleAdd = () => {
    setIsModalOpen(true);
    dispatch(GetUserdropList());
  };

  const onFinish = (value) => {
    var newValue = {
      ...value,
      mobile: `+${value.mobile}`,
    };
    delete value.phone_number;
    delete newValue.phone_number;
    const ID = IDS || '';
    IDS === '' ? setLoading1(true) : setLoading2(true);

    if (check === true && newValue.clients) {
      newValue.clients = editDrop;
    } else if (check === false) {
      const OlddropData1 = oldDropData.map((elem) => elem.value);
      newValue.clients = OlddropData1;
    }
    dispatch(AddUserData(newValue, ID)).then((res) => {
      if (res.payload.success === true) {
        notification.success({
          message: 'Success',
          description: res?.payload.message,
        });
        dispatch(GetSubUserList());
        dispatch(GetUserdropList());
        setcheck(false);
        setEditDrop([]);
        setOldDropData([]);
        setIDS('');
        if (IDS === '') {
          setIsModalOpen(false);
          setLoading1(false);
          userform.resetFields();
        } else {
          setIsModalOpen1(false);
          setLoading2(false);
          userform1.resetFields();
        }
      } else {
        notification.open({
          message: 'Failure',
          description: res?.payload.message || 'Something went wrong!',
          icon: (
            <FrownOutlined
              style={{
                color: 'red',
              }}
            />
          ),
        });
        setLoading1(false);
        setLoading2(false);
      }
      dispatch(GetUserdropList());
    });
  };

  const HandelEdit = async (id) => {
    setIsModalOpen1(true);
    setLoading1(true);
    var dropData = [];
    await dispatch(GetUserdropList()).then(async (res) => {
      setLoading1(false);
      if (res.payload.result && res.payload.result.length > 0) {
        dropData = await res.payload.result.map((elm) => {
          return { value: elm.id, label: elm.name };
        });
      }
    });

    dispatch(GetUserDataListById(id)).then(async (res) => {
      setIDS(res.payload.result.id);
      setLoading2(false);
      let selectedData = await res?.payload?.result?.clients.map((elm) => {
        return { value: elm.id, label: elm.name };
      });
      setOldDropData(selectedData);
      const totalData = [...dropData, ...selectedData];
      setuserDropData(totalData);

      userform1.setFieldsValue({
        email: res.payload.result.email,
        phone_number: res?.payload?.result?.phone_number,
        name: res?.payload?.result?.name,
        clients: selectedData,
      });
    });
  };

  const handleDelete = (id) => {
    setLoading(true);
    dispatch(DeleteSubuser(id)).then((res) => {
      if (res.payload.success === true) {
        notification.success({
          message: 'Success',
          description: res?.payload.message,
        });
        setLoading(false);
        dispatch(GetSubUserList());
        dispatch(GetUserdropList());
      } else {
        notification.open({
          message: 'Failure',
          description: res?.payload.message || 'Something went wrong!',
          icon: (
            <FrownOutlined
              style={{
                color: 'red',
              }}
            />
          ),
        });
        setLoading(false);
      }
    });
  };

  const HandalChange = (e) => {
    setEditDrop(e);
    setcheck(true);
  };

  const handleCancel = () => {
    userform.resetFields();
    setOldDropData([]);
    setcheck(false);
    setIsModalOpen(false);
    setEditDrop([]);
    setuserDropData([]);
  };
  const handleCancel1 = () => {
    setIsModalOpen1(false);
    setcheck(false);
    setEditDrop([]);
    setIDS('');
    setOldDropData([]);
    setuserDropData([]);
  };

  // TABLE
  const columns = [
    {
      title: 'Username',
      dataIndex: 'name',
      key: 'username',
      width: '22%',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      width: '22%',
    },
    {
      title: 'Phone',
      dataIndex: 'phone_number',
      key: 'mobile',
      width: '13%',
    },
    {
      title: 'Action',
      dataIndex: 'taxacc_status',
      key: 'taxacc_status',
      width: '2%',
      render: (text, record) => {
        return (
          <>
            {record.taxacc_status === 1 ? (
              ''
            ) : (
              <div className="d-flex justify-content-between align-items-center">
                <Button type="primary" icon={<CustomIcon icon={editIcon} />} size="small" onClick={() => HandelEdit(record.id)} />
                <Popconfirm placement="topRight" title="Are you sure to delete?" onConfirm={() => handleDelete(record.id)} okText="Yes" cancelText="No">
                  <Button type="primary" className="Delete_btn" icon={<CustomIcon icon={deleteIcon} />} size="small" />
                </Popconfirm>
              </div>
            )}
          </>
        );
      },
    },
  ];

  return (
    <>
      <main className="page-content">
        <div className="container-fluid bg-dash-back">
          <div className="layout-specing">
            <Spin spinning={loading} size="large">
              <div className="row align-items-center mt-1">
                <div className="col-xl-6 col-md-6 col-sm-6 col-12">
                  <div className="text-center text-sm-start mb-sm-0 mb-3 mb-sm-0">
                    <h5 className="m-0">Team Members</h5>
                  </div>
                </div>
                <div className="col-xl-6 col-md-6 col-sm-6 col-12">
                  <div className="text-center text-sm-end justify-content-between">
                    <Button className="property_btn1" onClick={() => handleAdd()}>
                      Add Member
                    </Button>
                  </div>
                </div>
              </div>
              <div className="table">
                <Table paddingclassName="mt-4 property_list" columns={columns} dataSource={data} rowCount={10} />
              </div>
            </Spin>
          </div>
        </div>
      </main>
      {/* ADD MODAL  */}
      <Modal title={<h4 className="mb-0">Add Member</h4>} open={isModalOpen} width={600} onCancel={handleCancel} footer={null} destroyOnClose>
        <Spin spinning={loading1}>
          <div className="add_form">
            <div className="row justify-content-center">
              <Form form={userform} name="userform" onFinish={onFinish} autoComplete="off" layout="vertical">
                <div className="card-body p-3 pb-0">
                  <div className="row">
                    <div className="col-md-6">
                      <Form.Item
                        label="Name"
                        name="name"
                        rules={[
                          {
                            required: true,
                            whitespace: true,
                            message: 'Please input your name!',
                          },
                        ]}
                      >
                        <Input className="form-control p-2" />
                      </Form.Item>
                    </div>
                    <div className="col-md-6">
                      <Form.Item
                        label="Phone"
                        name="mobile"
                        rules={[
                          {
                            required: true,
                            whitespace: true,
                            message: 'Please input correct phone number!',
                          },
                        ]}
                        className="phone_input"
                      >
                        <PhoneInput country="au" placeholder="Enter Phone Number" enableSearch />
                      </Form.Item>
                    </div>
                    <div className="col-md-12">
                      <Form.Item
                        label="Email"
                        name="email"
                        rules={[
                          {
                            required: true,
                            whitespace: true,
                            message: 'Please input your valid email!',
                            type: 'email',
                          },
                        ]}
                      >
                        <CustomInput className="form-control p-2" inputtype="text" autoComplete="off" />
                      </Form.Item>
                    </div>
                    <div className="col-md-12">
                      <Form.Item label="Select Clients" name="clients">
                        <Select
                          maxTagCount="responsive"
                          mode="multiple"
                          style={{ width: '100%', textAlign: 'left' }}
                          placeholder="Select Clients"
                          options={userDropData}
                          onChange={(e) => HandalChange(e)}
                          filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input) || (option?.label.toUpperCase() ?? '').includes(input)}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <Form.Item className="text-center">
                    <Button type="primary" className="mt-1" htmlType="submit">
                      Submit
                    </Button>
                  </Form.Item>
                </div>
              </Form>
            </div>
          </div>

          {/* <div className="site_header mt-0 text-center">
                        <label className='mb-2'><h5>or</h5></label>
                    </div> */}
          {/* <h6>Import :</h6> */}
        </Spin>
      </Modal>

      {/* EDIT MODAL  */}
      <Modal title={<h4 className="mb-0">Update Member</h4>} open={isModalOpen1} width={600} onCancel={handleCancel1} footer={null} destroyOnClose>
        <Spin spinning={loading2 || loading1}>
          <div className="add_form">
            <div className="row justify-content-center">
              <Form form={userform1} name="userform" onFinish={onFinish} autoComplete="off" layout="vertical">
                <div className="card-body p-3 pb-0">
                  <div className="row">
                    <div className="col-md-6">
                      <Form.Item
                        label="Name"
                        name="name"
                        rules={[
                          {
                            required: true,
                            whitespace: true,
                            message: 'Please input your name!',
                          },
                        ]}
                      >
                        <Input disabled style={{ color: 'black' }} className="form-control p-2" />
                      </Form.Item>
                    </div>
                    <div className="col-md-6">
                      <Form.Item label="Phone" className="phone_input" name="phone_number" required>
                        <PhoneInput disabled country="au" placeholder="Enter Phone Number" enableSearch />
                      </Form.Item>
                    </div>
                    <div className="col-md-12">
                      <Form.Item
                        label="Email"
                        name="email"
                        rules={[
                          {
                            required: true,
                            whitespace: true,
                            message: 'Please input your valid email!',
                            type: 'email',
                          },
                        ]}
                      >
                        <CustomInput disabled style={{ color: 'black' }} className="form-control p-2" inputtype="text" autoComplete="off" />
                      </Form.Item>
                    </div>
                    <div className="col-md-12">
                      <Form.Item label="Select Clients" name="clients">
                        <Select
                          maxTagCount="responsive"
                          mode="multiple"
                          style={{ width: '100%', textAlign: 'left' }}
                          placeholder="Select Clients"
                          options={userDropData}
                          onChange={(e) => HandalChange(e)}
                          filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input) || (option?.label.toUpperCase() ?? '').includes(input)}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <Form.Item className="text-center">
                    <Button type="primary" className="mt-1" htmlType="submit">
                      Update
                    </Button>
                  </Form.Item>
                </div>
              </Form>
            </div>
          </div>

          {/* <div className="site_header mt-0 text-center">
                        <label className='mb-2'><h5>or</h5></label>
                    </div> */}
          {/* <h6>Import :</h6> */}
        </Spin>
      </Modal>
    </>
  );
};

export default SubUsers;
