import React, { useEffect, useRef, useState } from 'react';
import '../../../../assets/less/index.less';
import watsappIcon from '../../../../assets/images/watsappIcon.svg';

import card1 from '../../../../assets/card1.png';
import card2 from '../../../../assets/card2.png';
import card3 from '../../../../assets/card3.png';
import card4 from '../../../../assets/card4.png';

import PropertyIcon from '../../../../assets/images/statsProperty.svg';

import { GetPropertyNotificationData } from '../../../../redux/actions/dashboard/getDashboardData.action';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Spin, notification, Button, Row, Col, Card, List } from 'antd';
import { DisconnectOutlined, LinkOutlined, NotificationOutlined, SmileOutlined } from '@ant-design/icons';
import { useLocation, useNavigate } from 'react-router-dom';
import { getTitle, getIcon } from '../../../common/NotificationUtil';
import NoProperty from '../../../common/NoProperty';

const ClientDashboard = ({ isTaxAccountant = false }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const ref = useRef();
  const [notificationLoading, setNotificationLoading] = useState(false);
  const [isOpenEmail, setIsOpenEmail] = useState(false);
  const [propertyAddress, setPropertyAddress] = useState({});
  const [propertyNotificationData, setPropertyNotificationData] = useState([]);

  const clientDetail = useSelector((state) => state.getClientdetailByIdReducer);

  const clientVirtualEmail = clientDetail?.data?.result?.virtual_email;

  const propertyNotificationDta = useSelector((state) => state.getPropertyNotificationDataReducer);
  const admin = JSON.parse(localStorage.getItem('admin'));
  const currentClient = JSON.parse(localStorage.getItem('currentClient'));

  const dashboardStats = propertyNotificationDta?.data?.result?.statistics;

  const statItems = [
    {
      title: 'Total Properties',
      icon: PropertyIcon,
      value: dashboardStats?.total_propertys || 0,
      card: card1,
      antIcon: false,
      className: 'pera_icon',
    },

    {
      title: 'Pending Tasks',
      antIcon: true,
      icon: (
        <NotificationOutlined
          style={{
            fontSize: '20px',
            marginTop: '15px',
            color: '#7c67e6',
            cursor: 'pointer',
          }}
        />
      ),
      value: dashboardStats?.total_pending_actions || 0,
      card: card2,
      className: 'pera_icon1',
    },

    {
      title: 'Bank Connected',
      className: 'pera_icon3',
      antIcon: true,
      icon: (
        <LinkOutlined
          style={{
            fontSize: '20px',
            marginTop: '15px',
            color: '#56C36E',
            cursor: 'pointer',
          }}
        />
      ),
      value: dashboardStats?.total_banks_connected || 0,
      card: card4,
    },

    {
      title: 'Bank Not Connected',
      antIcon: true,
      className: 'pera_icon2',

      icon: (
        <DisconnectOutlined
          style={{
            fontSize: '20px',
            marginTop: '15px',
            color: '#F35C5C',
            cursor: 'pointer',
          }}
        />
      ),
      value: dashboardStats?.total_banks_disconnected || 0,
      card: card3,
    },
  ];

  // Initial api call
  useEffect(() => {
    dispatch(GetPropertyNotificationData(currentClient));
  }, [dispatch, currentClient]);

  useEffect(() => {
    if (location?.state?.reload_change) {
      dispatch(GetPropertyNotificationData(currentClient));
    }
  }, [location.state, currentClient, dispatch]);

  // Set property notification stastics reducer data to state
  useEffect(() => {
    setNotificationLoading(propertyNotificationDta.isLoading || false);
    if (propertyNotificationDta && propertyNotificationDta?.data?.result?.Data && propertyNotificationDta.error === false) {
      setPropertyNotificationData(propertyNotificationDta?.data?.result?.Data);
    }
  }, [propertyNotificationDta]);

  const renderItem = (item) => {
    if (item.type === 'check_deprecation_mail') {
      return <div key={crypto.randomUUID()} className="my-3 my-sm-0"></div>;
    } else if (item.type === 'check_settlement_mail') {
      return <div key={crypto.randomUUID()} className="my-3 my-sm-0"></div>;
    } else if (item.type === 'check_investment_rent_mail') {
      return (
        <div key={crypto.randomUUID()} className="my-3 my-sm-0">
          {item.type ? (
            <Button
              className="email_btn"
              onClick={() => {
                setIsOpenEmail(true);
                setPropertyAddress(item);
              }}
            >
              Email Now
            </Button>
          ) : (
            ''
          )}
        </div>
      );
    } else if (item.type === 'add_property') {
      return (
        <div key={crypto.randomUUID()} className="my-3 my-sm-0">
          <Button onClick={() => navigate(`/clients/client/property/add`, { state: { fromSameSite: true } })}>Add</Button>
        </div>
      );
    } else {
      return '';
    }
  };

  const copyText = () => {
    navigator.clipboard.writeText(ref.current.innerHTML.replace(/<br ?\/?>/g, '\n'));

    notification.open({
      message: 'Success',
      description: 'Text copied.',
      icon: <SmileOutlined style={{ color: '#56AAAA' }} />,
    });
  };

  const emailText = () => {
    var encodedEmailContent = encodeURIComponent(ref.current.innerHTML.replace(/<br ?\/?>/g, '\n'));

    // Create the mailto link
    var mailtoLink = `mailto:${admin?.virtual_email}?subject=Rent Summary&body=${encodedEmailContent}`;

    // Open the email client with the mailto link
    window.location.href = mailtoLink;
  };

  return (
    <main className="page-content">
      <div className="container-fluid bg-dash-back">
        <a href="https://wa.me/+61493767832?text=I%20am%20interested%20in%20Property%20Accountant" className="whats_icon" target="_blank" rel="noreferrer">
          <img src={watsappIcon} className="img-fluid" alt="whatsapp icon" />
        </a>
        <div className="layout-specing p-0">
          <div>
            <div className="card_wrapper">
              <div className="row">
                {statItems.map((item, index) => (
                  <div key={index} className="col-xl-3 col-md-6 col-sm-6 col-12 mt-4">
                    <div className="card">
                      <div className="card-body">
                        <img className="circle" src={item.card} alt="" />
                        <h3 className="card-title fw-bold">{item.value}</h3>
                        <div className="card_ico d-flex justify-content-between mt-3 align-items-center">
                          <p className="card-text dashboard-card-text fw-normal m-0">{item.title}</p>
                          <p className={`m-0 ${item.className} `}>
                            {item.antIcon && item.icon}
                            <img src={item.icon} className="img-fluid" alt="" />
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <Row className="mb-3 mt-3 card_wrapper" gutter={20}>
            <Col xl={24} lg={24} md={24} sm={24} xs={24} className="card_wrapper">
              <div className="d-flex justify-content-between mt-1">
                <h5 className="m-0 mb-3">Pending Action / Reminder</h5>
                {/* {!notificationLoading && propertyNotificationData?.length >= 5 && (
                  <span className="cursor-pointer" onClick={() => navigate('/clients/client/property-details', { state: { fromSameSite: true } })}>
                    <span className="Route_showmore"> View More </span>
                  </span>
                )} */}
              </div>
              <Spin spinning={notificationLoading} size="large">
                <Card className="card_inner overflow-auto">
                  {propertyNotificationData && propertyNotificationData.length > 0 && (
                    <List
                      itemLayout="horizontal"
                      dataSource={propertyNotificationData || []}
                      renderItem={(item, index) => (
                        <>
                          <List.Item>
                            <List.Item.Meta title={<h6 className="fw-bold">{item.property_name}</h6>} />
                          </List.Item>
                          <List
                            itemLayout="horizontal"
                            dataSource={item.action_item?.filter((item) => !item.complete) || []}
                            renderItem={(item, index) => (
                              <List.Item key={index} style={item?.complete ? { background: '#f6f6f6' } : {}}>
                                <List.Item.Meta avatar={getIcon(item?.type)} title={getTitle(item?.type)} description={item?.message} />
                                {!item?.complete && <div>{renderItem(item)}</div>}
                              </List.Item>
                            )}
                          />
                        </>
                      )}
                    />
                  )}

                  {!notificationLoading && propertyNotificationData.length < 1 && <NoProperty isTaxAccountant={true} />}
                </Card>
              </Spin>
            </Col>
          </Row>
        </div>
      </div>

      {/* EMAILNOW MODAL */}
      <Modal onCancel={() => setIsOpenEmail(false)} footer={null} centered width={550} style={{ zIndex: '1005', borderRadius: '1em' }} className="txtAccountentModal" open={isOpenEmail}>
        <div ref={ref}>
          Dear Property Manager, <br />
          <br />
          How are you? I hope you are doing well.
          <br />
          <br />
          Going forward, please add my below email to send my monthly rental summary for all my properties "{isTaxAccountant ? clientVirtualEmail : admin?.virtual_email}"
          <br />
          <br />
          Property Details as below : <br />
          {propertyAddress?.property_address}
          <br />
          <br />
          Please confirm by email once this has been action. <br />
          <br />
          Have a nice day <br />
          Regards
        </div>

        <div className="p-2 text-end">
          <Button className="copy_btn3" onClick={() => copyText()}>
            Copy
          </Button>
          <Button className="copy_btn1" onClick={() => emailText()}>
            Send Mail
          </Button>
        </div>
      </Modal>
    </main>
  );
};

export default ClientDashboard;
