import React, { useMemo } from 'react';
import { Navigate } from 'react-router-dom';
import { UserTypes } from '../components/common/userTypes';
/**
 * Component to handle authentication for protected routes.
 * @param children - JSX.Element - The routes to render if the user is authenticated.
 * @returns JSX.Element - The routes to render if the user is authenticated, otherwise navigates to the landing page.
 */
const ProtectedRoutes = ({ children }) => {
  const isAuthenticated = useMemo(() => {
    const token = localStorage.getItem('token');
    const admin = localStorage.getItem('admin');
    const { user_type = null } = admin ? JSON.parse(admin) : {};

    const landing_url = process.env.REACT_APP_LANDING_PAGE_URL;

    if (token && user_type && user_type === UserTypes.USER) {
      return children;
    }
    if (token && user_type && [UserTypes.TAXACCOUTANT, UserTypes.CHANNEL_PARTNER].includes(user_type)) {
      return <Navigate to={'/dashboard'} />;
    } else if (token && user_type && [UserTypes.SUB_TAXACCOUTANT, UserTypes.SUB_CHANNEL_PARTNER].includes(user_type)) {
      return <Navigate to={'/clients'} />;
    } else {
      window.open(landing_url, '_self');
    }
  }, [children]);

  return isAuthenticated;
};

export default ProtectedRoutes;
