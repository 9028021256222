import { putRequest } from "../../../utils/apiUtils";
import {
    changePasswordFailure,
    changePasswordSuccess,
    changePasswordRequest,
} from "../../reducers/slices/auth/userChangePassword.slice";

export const UserChangePassword = (data) => {
    return async (dispatch) => {
        await dispatch(changePasswordRequest());
        const { result, error } = await putRequest(`user/change-password`, data);

        if (!error) {
            return await dispatch(changePasswordSuccess(result));
        }
        return await dispatch(changePasswordFailure(result));
    };
};
