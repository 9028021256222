import { CopyOutlined, ShopOutlined, PlusOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import React, { useState } from 'react';
import { Form, Modal, notification, Select, Spin, Upload } from 'antd';
import logosvg from '../../../assets/images/logo.svg';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import CustomInput from '../../common';
import { userCompanyInfo } from '../../../redux/actions/login/userCompanyInfo.action';
import { UserReferral } from '../../../redux/actions/login/userReferral.action';
import { ChannelPartnerType, UserTypes } from '../../common/userTypes';
import '../../../assets/css/antd.css';

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const ChannelPartnerTypeOption = [
  {
    value: ChannelPartnerType.MORTGAGE_BROKER,
    label: 'Mortgage Broker',
  },
  {
    value: ChannelPartnerType.CONVEYANCER,
    label: 'Conveyancer',
  },
  {
    value: ChannelPartnerType.RENTAL_PROPERTY_MANAGER,
    label: 'Rental Property Manager',
  },
  {
    value: ChannelPartnerType.BUYERS_AGENT,
    label: 'Buyers Agent',
  },
  {
    value: ChannelPartnerType.OTHERS,
    label: 'Others',
  },
];
const Signup = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const referral = [];
  const signupdata = [];
  const terms = false;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [channelPartnerType, setChannelPartnerType] = useState(null);

  const type = JSON.parse(localStorage.getItem('admin'));

  // UPLOAD IMAGE
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [fileList, setFileList] = useState([]);
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
  };
  const handleChange = ({ fileList: newFileList }) => {
    if (newFileList.length > 0 && !['image/png', 'image/jpg', 'image/jpeg'].includes(newFileList[0]?.type)) {
      notification.error({
        message: 'Failure',
        description: 'You can upload image formats .jpg, .jpeg, .png only!',
      });
      setFileList([]);
      return false;
    }
    setFileList(newFileList);
  };
  const uploadButton = (
    <div>
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );
  // END UPLOAD IMAGE

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleCancel1 = () => {
    setIsModalOpen1(false);
  };

  const handleFinish = (value) => {
    setLoading(true);
    value.checked = terms;

    if (value?.company_name !== undefined) {
      const formData = new FormData();
      formData.append('file', value?.company_logo?.fileList[0]?.originFileObj ? value?.company_logo?.fileList[0]?.originFileObj : '');
      formData.append('companyName', value?.company_name);

      if (type.user_type === UserTypes.CHANNEL_PARTNER && value?.type) {
        formData.append('ChannelPartnerType', value?.type);
      }

      if (type.user_type === UserTypes.CHANNEL_PARTNER && (value?.type === ChannelPartnerType.MORTGAGE_BROKER || type.channel_partner_type === ChannelPartnerType.MORTGAGE_BROKER)) {
        formData.append('mortgage_broker_uni_number', value.mortgage_broker_uni_number || '');
      }

      dispatch(userCompanyInfo(formData)).then((response) => {
        if (response?.payload?.success === true) {
          const { result } = response.payload;
          if (result.user_type === UserTypes.CHANNEL_PARTNER && result.channel_partner_type === ChannelPartnerType.MORTGAGE_BROKER) {
            navigate('/dashboard', { replace: true });
          } else {
            navigate('/clients', { replace: true });
          }

          setLoading(false);
          setLoading(false);
        } else {
          notification.error({
            message: 'Failure',
            description: response?.payload?.message || 'Something went wrong!',
          });
          setLoading(false);
        }
      });
      setLoading(false);
    } else if (value.referral_code || value.referral_code === undefined || value.referral_code === '') {
      signupdata.referral_code = referral === undefined ? '' : referral;
      dispatch(
        UserReferral({
          referralCode: value?.referral_code ? value?.referral_code : '',
        }),
      ).then((response) => {
        if (response?.payload?.success === true) {
          setLoading(false);
          navigate('/', { replace: true });
        } else {
          notification.error({
            message: 'Failure',
            description: response?.payload?.message || 'Something went wrong!',
          });
          setLoading(false);
        }
      });
      setLoading(false);
    }
  };
  const Handleback = () => {
    navigate('/term-conditions');
  };

  return (
    <>
      <div className="login_wrapper tandc_wrapper">
        <div className="card">
          <Spin spinning={loading} size="large">
            <div className="card-body p-0">
              <div className="modal-body p-4">
                <div>
                  {' '}
                  <ArrowLeftOutlined onClick={Handleback} style={{ color: '#008080', fontSize: '18px' }} />
                </div>
                <div className="text-center tandc_logo">
                  <img src={logosvg} className="img-fluid w-50" alt="" />
                </div>
                <div className="mt-3 creat_account"></div>
                {type.user_type === UserTypes.USER ? (
                  <Form
                    name="normal_login"
                    className="login-form"
                    initialValues={{
                      remember: true,
                    }}
                    onFinish={handleFinish}
                  >
                    <div>
                      <div className="row">
                        <div className="col-md-12"></div>

                        <div className="col-md-12">
                          <div className="mb-3">
                            <div className="col-md-12">
                              <div className="mb-3">
                                <div className="form-icon">
                                  <label>
                                    Referral Code <span className="text-danger"></span>
                                  </label>
                                  <Form.Item
                                    name="referral_code"
                                    rules={[
                                      {
                                        min: 8,
                                        message: 'Referral Code required minimum 8 digits!',
                                      },
                                    ]}
                                  >
                                    <CustomInput prefix={<CopyOutlined className="site-form-item-icon" />} firstzero="false" placeholder="Referral code" />
                                  </Form.Item>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="text-center login_button tandc_button">
                      <button type="submit">Submit</button>
                    </div>
                  </Form>
                ) : (
                  <>
                    <div className="mt-3 creat_account text-center mt-4 mb-3">{/* <h4 className="m-0">Tax Accountant</h4> */}</div>
                    <Form
                      name="normal_login"
                      className="login-form"
                      initialValues={{
                        remember: true,
                      }}
                      onFinish={handleFinish}
                    >
                      <div>
                        <div className="row">
                          <div className="col-md-12"></div>
                          <div className="col-md-12">
                            <div className="mb-3">
                              <div className="form-icon">
                                <label>
                                  Company Name <span className="text-danger">*</span>
                                </label>
                                <Form.Item
                                  name="company_name"
                                  rules={[
                                    {
                                      required: true,
                                      whitespace: true,
                                      message: 'Please input your Company Name!',
                                    },
                                  ]}
                                >
                                  <CustomInput prefix={<ShopOutlined className="site-form-item-icon" />} placeholder="Company Name" />
                                </Form.Item>
                              </div>
                            </div>
                          </div>

                          {type.user_type === UserTypes.CHANNEL_PARTNER && type.channel_partner_type === null && (
                            <div className="col-md-12">
                              <div className="mb-3">
                                <div className="form-icon">
                                  <label>
                                    Channel Partner Type <span className="text-danger">*</span>
                                  </label>
                                  <Form.Item
                                    name="type"
                                    rules={[
                                      {
                                        required: true,
                                        message: 'Please select one!',
                                      },
                                    ]}
                                  >
                                    <Select placeholder="Select Type" options={ChannelPartnerTypeOption} onChange={(value) => setChannelPartnerType(value)} />
                                  </Form.Item>
                                </div>
                              </div>
                            </div>
                          )}

                          {type.user_type === UserTypes.CHANNEL_PARTNER &&
                            (channelPartnerType === ChannelPartnerType.MORTGAGE_BROKER || type.channel_partner_type === ChannelPartnerType.MORTGAGE_BROKER) && (
                              <div className="col-md-12">
                                <div className="mb-3">
                                  <div className="form-icon">
                                    <label>CRN Number</label>
                                    <Form.Item name="mortgage_broker_uni_number">
                                      <CustomInput placeholder="CRN Number" />
                                    </Form.Item>
                                  </div>
                                </div>
                              </div>
                            )}
                          <div className="col-md-6 ">
                            <div className="mb-3">
                              <div className="form-icon">
                                <label>
                                  Company Logo <span className="text-danger"></span>
                                </label>
                                <Form.Item name="company_logo">
                                  <Upload
                                    action=""
                                    listType="picture-card"
                                    fileList={fileList}
                                    accept=".jpg, .jpeg, .png, image/jpeg, image/png"
                                    onPreview={handlePreview}
                                    onChange={handleChange}
                                    className="login_upload"
                                  >
                                    {fileList.length >= 1 ? null : uploadButton}
                                  </Upload>
                                </Form.Item>
                                <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={() => setPreviewOpen(false)}>
                                  <img
                                    alt="example"
                                    style={{
                                      width: '100%',
                                    }}
                                    src={previewImage}
                                  />
                                </Modal>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="text-center login_button tandc_button">
                        <button type="submit">Submit</button>
                      </div>
                    </Form>{' '}
                  </>
                )}
              </div>
            </div>
          </Spin>
        </div>
      </div>

      <Modal title="" className="privacy_height" open={isModalOpen} footer={null} width={1000} onCancel={handleCancel}>
        <section className="d-table w-100">
          <div className="container">
            <div className="row">
              <div className="col-12 text-center">
                <h1 className=" mt-2">Terms of Use</h1>
              </div>
            </div>
          </div>
        </section>
        <section className="section_modal">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div>
                  <ol className="p-3">
                    <li>Welcome to the Application or website of The Property Accountant Pty Ltd (ABN 17 664 478 595) ("we", "us" or the "Company").</li>
                    <li>
                      This website is located on the web via the domain <a href="https://thepropertyaccountant.com.au/">www.thepropertyaccountant.com.au</a> and includes all of the files located in
                      that domain ("the website").
                    </li>
                    <li>
                      This Application can be downloaded via the digital distribution service operated and developed by Apple Inc. (Apple App Store) or Google Inc. (Google Play Store) and includes all
                      of the files located in that Application ("the App").
                    </li>
                  </ol>
                  <h3 className="pp_heading">1. Agreement to these Terms of Use</h3>
                  <ol className="p-3">
                    <li>
                      By accessing the App and/or the website, you agree to be bound by these terms of use ("Terms of Use"). These Terms of Use constitute a binding agreement between you and the
                      Company and govern your use of the App and/or the website.
                    </li>
                  </ol>
                  <h4>1.1 Privacy Policy</h4>
                  <ol className="p-3">
                    <li>
                      As part of these Terms of Use, your use of the App and/or the website is also subject to our Privacy Policy (located at Australia), which is incorporated by reference into these
                      Terms of Use.
                    </li>
                  </ol>

                  <h3 className="pp_heading">2. Restrictions on use</h3>
                  <h4>2.1 Prohibited conduct</h4>
                  <ol className="p-3">
                    <li>Your use of the App and/or the website is subject to the rules set out in Schedule 1 below.</li>
                  </ol>
                  <h4>2.2 Violations of these Terms of Use</h4>
                  <ol className="p-3">
                    <li>
                      Without limiting any other remedies available to the Company at law or in equity, the Company reserves the right to, without notice:
                      <ul>
                        <li>
                          Temporarily or indefinitely suspend, or terminate, your access to the App and/or website or refuse to provide services to you if:
                          <ul className="sub_point">
                            <li>You breach any provision of these Terms of Use;</li>
                            <li>The Company is unable to verify or authenticate any information that you provide to us; or</li>
                            <li>The Company believes that your actions may cause damage and/or legal liability to the Company, any of its customers or suppliers or any other person; and</li>
                          </ul>
                        </li>
                        <li>
                          Remove or block access to any information and/or materials (in whole or in part) that the Company, at its sole and absolute discretion, regards in any way to be objectionable
                          or in violation of any applicable law, any person's intellectual property rights or these Terms of Use.
                        </li>
                      </ul>
                    </li>
                  </ol>
                  <h4>2.3 Indemnity</h4>
                  <ol className="p-3">
                    <li>
                      You indemnify and hold harmless the Company and its officers, employees, agents, consultants, licensors, partners and affiliates from and against any losses, liabilities, costs,
                      expenses or damages (including actual, special, indirect and consequential losses or damages of every kind and nature, including all legal fees on a solicitor-client basis)
                      suffered or incurred by any of them due to, arising out of, or in any way related to (directly or indirectly):
                      <ul>
                        <li>Any material or information that you submit, post, transmit or otherwise make available through the App and/or the website;</li>
                        <li>Your use of, or connection to, the App and/or the website; or</li>
                        <li>Your negligence or misconduct, breach of these Terms of Use or violation of any law or the rights of any person.</li>
                      </ul>
                    </li>
                  </ol>

                  <h3 className="pp_heading">3. Registration and account security</h3>
                  <h4>3.1 Requirement for registration</h4>
                  <ol className="p-3">
                    <li>The Company reserves the right to make any parts of the App or the website accessible only to users who have registered.</li>
                  </ol>
                  <h4>3.2 Username and password</h4>
                  <ol className="p-3">
                    <li>
                      Upon registration, you will be issued with a username and password to access your account. You are responsible for maintaining the security of your password. The Company will not
                      be liable for any loss or damage arising from or in connection with your failure to comply with this security obligation. You agree that the Company will be entitled to assume
                      that any person using the App or the website with your username and password is you or your authorised representative.
                    </li>
                    <li>You must notify the Company immediately of any known or suspected unauthorised use of any password or any other breach of security.</li>
                  </ol>
                  <h4>3.3 User information</h4>
                  <ol className="p-3">
                    <li>
                      In order to register an account, you must agree to these Terms of Use and provide the Company with:
                      <ul>
                        <li>A valid email address;</li>
                        <li>Accurate billing and contact information (including your street address and the name and telephone number of your authorised billing contact and administrator); and</li>
                        <li>Any other information that may be required by the Company during the registration process.</li>
                      </ul>
                      You must promptly update this information to maintain its accuracy at all times.
                    </li>
                    <li>
                      You represent and warrant to the Company that all information provided to the Company by you, including the information provided by you through our account registration module or
                      entered into your account profile, is true and not misleading and does not violate any applicable law or regulation or any person's intellectual property or other rights.
                    </li>
                  </ol>
                  <h4>3.4 Multiple accounts and automated account opening</h4>
                  <ol className="p-3">
                    <li>One person may not maintain more than one account. Accounts registered by "bots" or other automated methods are not permitted.</li>
                  </ol>
                  <h4>3.5 Approval of registrations</h4>
                  <ol className="p-3">
                    <li>The Company reserves the right to accept or reject any application for registration of an account with the App and/or at its discretion.</li>
                  </ol>

                  <h3 className="pp_heading">4. Intellectual property</h3>
                  <h4>4.1 Copyright</h4>
                  <ol className="p-3">
                    <li>
                      In these Terms of Use, the term <b>"Proprietary Content"</b> means:
                      <ul>
                        <li>The App and/or the website;</li>
                        <li>
                          All of its content (including all of the text, graphics, designs, software, data, sound and video files and other information contained in the App and/or the website, and the
                          selection and arrangement thereof); and
                        </li>
                        <li>
                          All software, systems and other information owned or used by the Company in connection with the App and/or the website (whether hosted on the same server as the App and/or
                          the website or otherwise).
                        </li>
                        All Proprietary Content is the property of the Company or its licensors (as applicable) and is protected by Australian and international copyright laws. You must not reproduce,
                        transmit, republish or prepare derivative works from any of the Proprietary Content, except as expressly authorised by these Terms of Use or with the prior written consent of
                        the Company or other copyright owner (as applicable).
                      </ul>
                    </li>
                    <li>
                      You may download and print out content from the App and/or the website only for your own personal and non-commercial use and provided that you do not remove or modify any
                      copyright, trademark or other proprietary notices.
                    </li>
                  </ol>
                  <h4>4.2 Trademarks</h4>
                  <ol className="p-3">
                    <li>
                      The look and feel of the App and/or the website (including all button icons, scripts, custom graphics and headers) are the trademarks, service marks and/or trade dress of the
                      Company. These trademarks, service marks and trade dress may not be used, copied or imitated, in whole or in part, without the prior written consent of the Company.
                    </li>
                  </ol>

                  <h4>4.3 Copyright claims</h4>
                  <ol className="p-3">
                    <li>
                      If you believe that our site contains any material that infringes upon any copyright that you hold or control, or that users are directed through a link on the App and/or the
                      website to a third party website that you believe is infringing upon any copyright that you hold or control, you may send a notification of such alleged infringement to us in
                      writing. Such notification should identify the works that are allegedly being infringed upon and the allegedly infringing material and give particulars of the alleged
                      infringement. In response to such a notification, we will give a written notice of a claim of copyright infringement to the provider of the allegedly infringing material. If the
                      provider of that material does not respond to us in writing denying the alleged infringement within 14 days after receipt of that notice, we will remove or block the allegedly
                      infringing material as soon as is reasonably practicable. If the provider of that material responds to us in writing denying the alleged infringement, we will, as soon as is
                      reasonably practicable, send a copy of that response to the original notifying party. If the original notifying party does not, within a further 14 days, file an action seeking a
                      court order against the provider of the allegedly infringing material, we may restore any removed or blocked material at our discretion. If the original notifying party files
                      such a legal action, we will remove or block the allegedly infringing material pending resolution of that legal action.
                    </li>
                  </ol>
                  <h3 className="pp_heading">5. Disclaimer of warranties</h3>
                  <ol className="p-3">
                    <li>
                      TO THE MAXIMUM EXTENT PERMITTED BY LAW, THE COMPANY AND ITS OFFICERS, EMPLOYEES, AGENTS, CONSULTANTS, LICENSORS, PARTNERS AND AFFILIATES EXPRESSLY DISCLAIM ALL CONDITIONS,
                      REPRESENTATIONS AND WARRANTIES (WHETHER EXPRESS OR IMPLIED, STATUTORY OR OTHERWISE) IN RELATION TO THE APP, INCLUDING ANY IMPLIED WARRANTY/GUARANTEE OF MERCHANTABILITY, FITNESS
                      FOR A PARTICULAR PURPOSE OR NON-INFRINGEMENT.
                    </li>
                    <li>
                      The App and/or the website are provided strictly on an "as is" basis. To the maximum extent permitted by law, the Company and its officers, employees, agents, consultants,
                      licensors, partners and affiliates make no representation, warranty or guarantee as to the reliability, timeliness, quality, suitability, truth, availability, accuracy or
                      completeness of the App and/or the website or any of its content, and in particular do not represent, warrant or guarantee that:
                      <ul>
                        <li>The use of the App and/or the website will be secure, timely, uninterrupted or error-free or operate in combination with any other hardware, software, system or data;</li>
                        <li>The App and/or the website will meet your requirements or expectations;</li>
                        <li>
                          Anything on the App and/or the website, or on any third-party website referred or linked to in the App and/or the website, is reliable, accurate, complete or up-to-date;
                        </li>
                        <li>The quality of any information or other material purchased or obtained through the App and/or the website will meet any particular requirements or expectations;</li>
                        <li>Errors or defects will be corrected; or</li>
                        <li>The App and/or the website or the servers that make it available are free of viruses or other harmful components.</li>
                      </ul>
                      The report generated is compiled from the information provided or entered by you or on your behalf. We expressly disclaim any warranty that the report generated is a complete and
                      accurate record of your annual income and expenditure or the property cost base. The report can be no more accurate than the information provided or entered by you or on your
                      behalf and you should consult your tax accountant before lodging your tax return.
                    </li>
                  </ol>

                  <h3 className="pp_heading">6. Limitation of liability</h3>
                  <h4>6.1 Exclusion of liability</h4>
                  <ol className="p-3">
                    <li>
                      To the maximum extent permitted by law, the Company and its officers, employees, agents, consultants, licensors, partners and affiliates exclude all liability to you or any other
                      person for any loss, cost, expense, claim or damage (whether arising in contract, negligence, tort, equity, statute or otherwise, and for any loss, whether it be consequential,
                      indirect, incidental, special, punitive, exemplary or otherwise, including any loss of profits, loss or corruption of data or loss of goodwill) arising directly or indirectly out
                      of, or in connection with, these Terms of Use or the use of the App and/or the website by you or any other person.
                    </li>
                  </ol>

                  <h4>6.2 Remedies limited</h4>
                  <ol className="p-3">
                    <li>
                      To the maximum extent permitted by law, the Company and its officers, employees, agents, consultants, licensors, partners and affiliates expressly limit their liability for
                      breach of any non-excludable condition or warranty/guarantee implied by virtue of any legislation to the following remedies (the choice of which is to be at the Company's sole
                      discretion):
                      <ul>
                        <li>
                          In the case of goods, to any of the following:
                          <ul className="sub_point">
                            <li>The replacement of the goods or the supply of equivalent goods;</li>
                            <li>The repair of the goods;</li>
                            <li>The payment of the cost of replacing the goods or of acquiring equivalent goods; or</li>
                            <li>The payment of the cost of having the goods repaired; and</li>
                          </ul>
                        </li>
                        <li>
                          In the case of services:
                          <ul className="sub_point">
                            <li>The supply of the services again; or</li>
                            <li>The payment of the cost of having the services supplied again.</li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                  </ol>

                  <h4>6.3 Release</h4>
                  <ol className="p-3">
                    <li>
                      You agree that your use of the App and/or the website is at your own discretion and risk. You agree to release the Company and its officers, employees, agents, consultants,
                      licensors, partners and affiliates from any claim, demand or cause of action that you may have against any of them arising from these Terms of Use or the use of the App and/or
                      the website by you or any other person. The Company may plead this release as a bar and complete defence to any claims or proceedings.
                    </li>
                  </ol>

                  <h4>6.4 Force majeure</h4>
                  <ol className="p-3">
                    <li>
                      To the maximum extent permitted by law, and without limiting any other provision of these Terms of Use, the Company excludes liability for any delay in performing any of its
                      obligations under these Terms of Use where such delay is caused by circumstances beyond the reasonable control of the Company, and the Company shall be entitled to a reasonable
                      extension of time for the performance of such obligations.
                    </li>
                  </ol>

                  <h3 className="pp_heading">7. General</h3>
                  <h4>7.1 Interpretation</h4>
                  <ol className="p-3">
                    <li>
                      In these Terms of Use, the following rules of interpretation apply:
                      <ul>
                        <li>Headings are for reference purposes only and in no way define, limit or describe the scope or extent of any provision in these Terms of Use;</li>
                        <li>These Terms of Use may not be construed adversely against the Company solely because the Company prepared them;</li>
                        <li>The singular includes the plural and vice-versa;</li>
                        <li>
                          A reference to a "person" includes an individual, a firm, a corporation, a body corporate, a partnership, an unincorporated body, an association, a government body or any
                          other entity; and
                        </li>
                        <li>The meaning of general words is not limited by specific examples introduced by "including", "for example", "in particular" or similar expressions.</li>
                      </ul>
                    </li>
                  </ol>

                  <h4>7.2 Notifications</h4>
                  <ol className="p-3">
                    <li>The Company may provide any notification for the purposes of these Terms of Use by email and/or by adding the notification into your user control panel.</li>
                  </ol>

                  <h4>7.3 Costs</h4>
                  <ol className="p-3">
                    <li>Except as specifically provided in these Terms of Use, each party must bear its own legal, accounting and other costs associated with these Terms of Use.</li>
                  </ol>

                  <h4>7.4 Assignment</h4>
                  <ol className="p-3">
                    <li>
                      You may not assign, transfer or sub-contract any of your rights or obligations under these Terms of Use without the Company's prior written consent. Your registration with the
                      App and/or the website is personal to you and may not be sold or otherwise transferred to any other person.
                    </li>
                    <li>The Company may assign, transfer or sub-contract any of its rights or obligations under these Terms of Use at any time without notice to you.</li>
                  </ol>

                  <h4>7.5 No waiver</h4>
                  <ol className="p-3">
                    <li>
                      Waiver of any power or right under these Terms of Use must be in writing signed by the party entitled to the benefit of that power or right and is effective only to the extent
                      set out in that written waiver. Any failure by the Company to act with respect to a breach by you or others does not waive the Company's right to act with respect to that breach
                      or any subsequent or similar breaches.
                    </li>
                  </ol>
                  <h4>7.6 Severability</h4>
                  <ol className="p-3">
                    <li>
                      The provisions of these Terms of Use are severable and, if any provision of these Terms of Use is held to be illegal, invalid or unenforceable under present or future law, such
                      provision may be removed and the remaining provisions shall be enforced.
                    </li>
                  </ol>
                  <h4>7.7 Variation</h4>
                  <ol className="p-3">
                    <li>
                      The Company reserves the right to amend these Terms of Use and any other policy on the App and/or website at any time in its sole discretion and any such changes will, unless
                      otherwise noted, be effective immediately. Your continued usage of the App and/or website will mean you accept those amendments. We reserve the right, without notice and at our
                      sole discretion, to change, suspend, discontinue or impose limits on any aspect or content of the App and/or website.
                    </li>
                    <li>You may only vary or amend these Terms of Use by written agreement with the Company.</li>
                  </ol>
                  <h4>7.8 Governing law and jurisdiction</h4>
                  <ol className="p-3">
                    <li>
                      These Terms of Use will be governed in all respects by the laws of Victoria. The parties irrevocably submit to the non-exclusive jurisdiction of the courts of Victoria and the
                      courts of appeal from them.
                    </li>
                  </ol>

                  <h3 className="pp_heading">8. Prohibited conduct</h3>
                  <h4>8.1 YOU MUST NOT:</h4>
                  <ol className="p-3">
                    <li>Use any device, routine or software that interferes, or attempt to interfere, with the proper working of the App and/or the website;</li>
                    <li>Engage in any action that requires, or may require, an unreasonable or excessively large load on our infrastructure;</li>
                    <li>
                      Use the App and/or website to decipher passwords or security encryption codes, transmit any worms, viruses or Trojan horses, transfer or store illegal, threatening or obscene
                      material or otherwise violate the security of any computer network;
                    </li>
                    <li>
                      Use the App and/or the website to violate any applicable local, state, national or international law, to engage in any misleading or deceptive online marketing practices or for
                      any fraudulent or malicious purposes;
                    </li>
                    <li>
                      Use any spider, robot or search/retrieval application or any screen scraping, data mining or similar data gathering device, process, program or means to access, retrieve or index
                      any portion of the App and/or the website;
                    </li>
                    <li>Use the App and/or the website by any automated means;</li>
                    <li>
                      Use the App and/or the website to transmit junk mail, spam or chain letters or pyramid schemes or engage in other flooding techniques or mass distribution of unsolicited email;
                    </li>
                    <li>
                      Access, retrieve or index any portion of the App and/or the website for use in constructing or populating any database that is searchable online or for the purpose of soliciting
                      or sharing reviews;
                    </li>
                    <li>Interfere with the display of any advertisements appearing on or in connection with the App and/or the website;</li>
                    <li>Reverse engineer, decompile, disassemble, adapt, modify, translate, frame or reformat any of the material contained on the App and/or the website;</li>
                    <li>Reproduce, duplicate, copy or store any of the material appearing on the App and/or the website other than for your own personal and non-commercial use;</li>
                    <li>Falsely imply that any other application or website is associated with the App and/or the website;</li>
                    <li>Do anything that leads, or may lead, to a decrease in the value of the Company's intellectual property rights in the App and/or the website;</li>
                    <li>
                      Use or exploit any of the material appearing on the App and or the website for, or in connection with, any business or enterprise (whether for profit or otherwise), including any
                      business or enterprise that is in competition with the App and/or the website;
                    </li>
                    <li>
                      Release to the public any news release, advertising material, promotional material or any other form of publicity or information relating to the Company without the Company's
                      prior written consent; or
                    </li>
                    <li>
                      Use the App and/or the website to transmit any information or material that is, or may reasonably be considered to be:
                      <ul>
                        <li>
                          {' '}
                          Abusive, threatening, harassing, harmful, obscene, indecent, lewd, inflammatory, violent, vulgar, profane, racially, ethnically or otherwise objectionable or offensive in any
                          way;
                        </li>
                        <li>Libellous, defamatory, pornographic, sexually explicit, unlawful or plagiarised;</li>
                        <li>Infringing upon or violating any copyright, trademark, patent or other intellectual property or proprietary right;</li>
                        <li>In breach of any duty of confidentiality by which you are bound, whether by way of a fiduciary or contractual relationship;</li>
                        <li>In breach of any person’s privacy or publicity rights;</li>
                        <li>
                          A misrepresentation of facts, including the impersonation of any person or entity or a misrepresentation of an affiliation with any person or entity (including any
                          sponsorship or endorsement);
                        </li>
                        <li>In violation of any applicable law, statute, ordinance or regulation, or encouraging of others to do so;</li>
                        <li>Containing any political campaigning material, advertisements or solicitations; or</li>
                        <li>Likely to bring the Company or any of its staff into disrepute.</li>
                      </ul>
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Modal>
      <Modal className="privacy_height" open={isModalOpen1} footer={null} width={1000} onCancel={handleCancel1}>
        <section className="d-table w-100">
          <div className="container">
            <div className="row">
              <div className="col-12 text-center">
                <h1 className="">Privacy Policy</h1>
              </div>
            </div>
          </div>
        </section>
        <section className="section padding_section">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div>
                  <h3 className="pp_heading">1. Introduction</h3>
                  <ol className="ps-3">
                    <li>This website is owned and operated by The Property Accountant Pty Ltd (ACN 664 478 595)(the Property Accountant).</li>
                    <li>
                      The Property Accountant is committed to providing quality service to you and this Privacy Policy outlines our ongoing obligations to you in respect to how we manage your personal
                      information. When we say "Personal information" we mean information that either alone or in combination with other data can identify you as an individual.
                    </li>
                    <li>By accessing and using the app or our website, you agree to the terms of this Privacy Policy without any limitation or qualification.</li>
                    <li>
                      We may amend this Privacy Policy from time to time, and any amendments will be effective immediately upon positing the revised policy to the website. It is your responsibility to
                      review this Privacy Policy.
                    </li>
                  </ol>
                  <h3 className="pp_heading">2. Collection of Personal Information</h3>
                  <ol className="ps-3">
                    <li className="">
                      The type of personal information we collect may include (but is not limited to):
                      <ul>
                        <li>Your full name,</li>
                        <li>Contact details including residential and postal address,</li>
                        <li>Addresses,</li>
                        <li>Number, and</li>
                        <li>Information for expenses and costs relating to your investment property which will determine final treatment in income & expenditure or cost base report; and</li>
                        <li>Any other information relating to your property investment.</li>
                      </ul>
                      We do not intentionally gather personal information of visitors under the age of 18 on our platform. If you are under the age of 18 you are not permitted to submit any personal
                      information to us. If we learn that a child under 16 submits personal information or if you believe that we might have stored personal information on a child under 18 please
                      contact us at <a href="mailto:privacy@thepropertyaccountant.com.au">privacy@thepropertyaccountant.com.au</a> or{' '}
                      <a href="mailto:contact@thepropertyaccountant.com.au">contact@thepropertyaccountant.com.au</a> and we will endeavour to delete the information.
                    </li>
                  </ol>
                  <h3 className="pp_heading">3. How we collect personal information</h3>
                  <ol className="ps-3">
                    <li>
                      In order to use our app and/or web portal, you will need to register as a user. When registering as a user, we will require you to provide personal information to create your
                      user profile. We then use that information for your log in and other account keeping purposes.
                    </li>
                    <li>We will also collect personal information when you use the app and/or web portal and upload information and documents to your user profile.</li>
                    <li className="">
                      Where reasonable and practicable to do so, we will collect your personal information only from you. However, in some circumstances we may be provided with information by third
                      parties. In such a case we will take reasonable steps to ensure that you are made aware of the information provided to us by the third party.
                    </li>
                  </ol>
                  <h3 className="pp_heading">4. Why we collect personal information</h3>
                  <ol className="ps-3">
                    <li className="">
                      We only collect personal information that is reasonably necessary for a legitimate purpose of providing our services to you and for the primary purpose of providing our services
                      to you. We may also use your personal information for secondary purposes closely related to the primary purpose, in circumstances where you would reasonably expect such use or
                      disclosure.
                    </li>
                    <li>
                      We may use your personal information for business related purposes, including (without limitation):
                      <ul>
                        <li>To fulfil your requests for products and services;</li>
                        <li>To contact you about the products and services provided by our platform;</li>
                        <li>To send you important information such as confirmations, invoices, technical notices, updates, security alerts and support and administrative messages;</li>
                        <li>To answer any question you may have regarding our product;</li>
                        <li>To generate income & expenditure or cost base report or other dash board report and analysis ; and</li>
                        <li>To send you notification and guide you through action items on the app and web portal for your user account</li>
                      </ul>
                      We will only send you direct marketing, training, explanation emails, notifications, messages or video that promote our company or services you have opted-in.
                    </li>
                  </ol>
                  <h3 className="pp_heading">5. Duration of retention of your Information</h3>
                  <ol className="ps-3">
                    <li className="">
                      We will only keep your personal information for as long as is necessary for the purpose for which it was collected, subject to satisfying any legal, accounting or reporting
                      requirements.
                    </li>
                    <li className="">
                      If your account has been inactive for more than three (3) years, your personal information will either be deleted completely or anonymised (for example, by aggregation with other
                      data so that it can be used in a non-identifiable way for statistical analysis and business planning). In some circumstances, you can ask us to delete your personal information.
                    </li>
                  </ol>
                  <h3 className="pp_heading">6. The Release of your Information</h3>
                  <ol className="ps-3">
                    <li>
                      Your personal information will not be sold or provided to any third party or used for any other purpose not related to the delivery of our products to you. However, because of
                      the nature of our business, we may engage third party individuals and/or organizations (for example our web host provider, other SaaS providers, such as an email hosting service,
                      payment processors or outside contractors that we hire to perform maintenance or assist us in securing our website) to help improve our services.
                    </li>
                  </ol>
                  <h3 className="pp_heading">7. Disclosure of personal information</h3>
                  <ol className="ps-3">
                    <li>
                      Disclosure of your personal information will only be made in circumstances where:
                      <ul className="">
                        <li>It is done for the purpose for which it was collected;</li>
                        <li>We are required to make such disclosure by law;</li>
                        <li>You expressly consent to us making such disclosure; and</li>
                        <li>Such disclosure is permitted by this Privacy Policy and/or the Terms and Conditions that govern your access to the app and/or website.</li>
                      </ul>
                      If required to do so, we may also share personal information when we believe it necessary to investigate, prevent, or take action regarding illegal activities, suspected fraud,
                      situations involving potential threats to the physical safety of any person, violations of our Terms of Service, or as otherwise required by law.
                    </li>
                  </ol>
                  <h3 className="pp_heading">8. Accessing your personal information</h3>
                  <ol className="ps-3">
                    <li>
                      If your personally identifiable information changes, or if you no longer desire our service, you may correct, update, delete or deactivate it by emailing us at
                      <a href="mailto:privacy@thepropertyaccountant.com.au">privacy@thepropertyaccountant.com.au</a> or{' '}
                      <a href="mailto:contact@thepropertyaccountant.com.au">contact@thepropertyaccountant.com.au</a>
                    </li>
                    <li className="">In order to protect your personal information, we may require identification from you before releasing the requested information.</li>
                    <li>
                      We may reject certain unreasonable types of requests that are for example repetitive spams, require disproportionate technical effort (for example, developing a new system or
                      fundamentally changing an existing practice), those that risk the privacy of others, or would be extremely impractical.
                    </li>
                    <li>
                      Whenever we can provide information access and or correction for you, we will do so for free, always. Because we put such care into protecting information from accidental or
                      ill-intended destruction, even after the information is deleted from the platform, it may not be immediately deleted from our servers. To address any sort of request that you
                      have on the personal information from our site, Services, or social media, contact us at{' '}
                      <a href="mailto:privacy@thepropertyaccountant.com.au">privacy@thepropertyaccountant.com.au</a> or{' '}
                      <a href="mailto:contact@thepropertyaccountant.com.au">contact@thepropertyaccountant.com.au</a>
                    </li>
                  </ol>
                  <h3 className="pp_heading">9. Information Security</h3>
                  <ol className="ps-3">
                    <li>
                      The security of your personal information is important to us. We invest a lot of effort in maintaining commercially reasonable administrative, technical, and physical safeguards
                      that protect your personal information in order to prevent loss, misuse, and unauthorized access, disclosure, alteration and destruction of your data. These measures include for
                      example using SSL Certificates.
                    </li>
                    <li>
                      We follow generally accepted industry standards to protect the personal information submitted to us, both during transmission and once we receive it. However, we cannot promise
                      that the information about you will remain secure in all circumstances. It is also your responsibility to protect your data to help prevent anyone with ill intent to access and
                      abuse your accounts and Services. One of the easiest ways of doing so is to not reuse the same passwords you use with other accounts as your password for our Services.
                    </li>
                    <li>When Credit Card details are collected, we simply pass them on in order to be processed as required. We never permanently store complete Credit Card details.</li>
                    <li>
                      If you have any questions about security on our app and/or website, you can email us at{' '}
                      <a href="mailto:privacy@thepropertyaccountant.com.au">privacy@thepropertyaccountant.com.au</a> or{' '}
                      <a href="mailto:contact@thepropertyaccountant.com.au">contact@thepropertyaccountant.com.au</a>
                    </li>
                  </ol>
                  <h3 className="pp_heading">10. Cookies And Tracking</h3>
                  <ol className="ps-3">
                    <li>
                      The Property Accountant and our partner services such as Google Analytics may collect certain information during your time on our platform via "cookies" or similar technologies.
                      These cookies have the important function to deliver us information we use to better our Services by delivering us valuable data on, for example, demographics, count of visitors
                      to our site and places within the site that are popular. Most web browsers tend to accept these cookies by default, but if you would like to not be tracked by these you can
                      switch them off via your browser settings. If you choose to remove the tracking function of these cookies it will affect certain features and Services on our site.
                    </li>
                    <li>
                      We partner with Microsoft Clarity and Microsoft Advertising to capture how you use and interact with our website through behavioral metrics, heatmaps, and session replay to improve and market our products/services. Website usage data is captured using first and third-party cookies and other tracking technologies to determine the popularity of products/services and online activity. Additionally, we use this information for site optimization, fraud/security purposes, and advertising. For more information about how Microsoft collects and uses your data, visit the Microsoft Privacy Statement.
                    </li>
                  </ol>
                  <h3 className="pp_heading">11. Third Party Websites</h3>
                  <ol className="ps-3">
                    <li>
                      The Property Accountant also has public profiles across a range of social media sites. Any information that you include in a comment on any of our posts online may be read,
                      collected, and used by anyone else. If your personal information appears on our social media and you want it removed, contact us at{' '}
                      <a href="mailto:privacy@thepropertyaccountant.com.au">privacy@thepropertyaccountant.com.au</a> or{' '}
                      <a href="mailto:contact@thepropertyaccountant.com.au">contact@thepropertyaccountant.com.au</a> we will get back to you as soon as we can.
                    </li>
                  </ol>
                  <h3 className="pp_heading">12. Notice of Breach of Security</h3>
                  <ol className="ps-3">
                    <li>
                      If we ever do notice an unauthorized security breach into our system that affects you in ways such as the loss of your data or the theft of it we will notify you as soon as
                      possible as well as the responsible authorities and report on the actions we took in response.
                    </li>
                  </ol>
                  <h3 className="pp_heading">13. Complaints</h3>
                  <ol className="ps-3">
                    <li>
                      If you wish to complain about how we have handled your personal information, you can contact us at{' '}
                      <a href="mailto:privacy@thepropertyaccountant.com.au">privacy@thepropertyaccountant.com.au</a> or{' '}
                      <a href="mailto:contact@thepropertyaccountant.com.au">contact@thepropertyaccountant.com.au</a>
                    </li>
                    <li>
                      We will use the information from your feedback to investigate and resolve individual issues. We will also use the information to provide feedback to our business areas and to
                      improve the delivery of our services.
                    </li>
                    <li>
                      If we do not resolve your complaint to your satisfaction, you can contact the Office of the Australian Information Commissioner (OAIC) at{' '}
                      <a href="https://www.oaic.gov.au/privacy/privacy-complaints">https://www.oaic.gov.au/privacy/privacy-complaints</a> . The OAIC is independent to us.
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Modal>
    </>
  );
};

export default Signup;
