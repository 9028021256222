// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-search-parent {
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 10px;
}
.custom-search-parent .ant-input-group-addon .ant-input-search-button {
  min-height: 52px !important;
  border-left: none;
  border-radius: 6px !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
.custom-search {
  max-width: 300px;
}
.custom-search .ant-input-clear-icon {
  scale: 1.5;
}
.custom-search-parent .ant-input-outlined {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-right: none !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/pages/tax-Dashboard/TaxAccountantDashboard/index.less"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,oBAAA;EACA,mBAAA;EACA,SAAA;AACJ;AAEE;EACE,2BAAA;EACA,iBAAA;EACA,6BAAA;EACA,oCAAA;EACA,uCAAA;AAAJ;AAGE;EACE,gBAAA;AADJ;AAGE;EACE,UAAA;AADJ;AAIE;EACE,qCAAA;EACA,wCAAA;EACA,6BAAA;AAFJ","sourcesContent":[".custom-search-parent {\n    display: flex;\n    justify-content: end;\n    align-items: center;\n    gap: 10px;\n  }\n  \n  .custom-search-parent .ant-input-group-addon .ant-input-search-button {\n    min-height: 52px !important;\n    border-left: none;\n    border-radius: 6px !important;\n    border-top-left-radius: 0 !important;\n    border-bottom-left-radius: 0 !important;\n  }\n  \n  .custom-search {\n    max-width: 300px;\n  }\n  .custom-search .ant-input-clear-icon{\n    scale: 1.5;\n  }\n  \n  .custom-search-parent .ant-input-outlined {\n    border-top-right-radius: 0 !important;\n    border-bottom-right-radius: 0 !important;\n    border-right: none !important;\n  }\n  "],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
