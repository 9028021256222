import React, { useMemo } from 'react';
import { Navigate } from 'react-router-dom';
import { UserTypes } from '../components/common/userTypes';

const TexProtected = ({ role, children }) => {
  const isAuthenticated = useMemo(() => {
    const token = localStorage.getItem('token');
    const admin = localStorage.getItem('admin');
    const { user_type = null } = admin ? JSON.parse(admin) : {};
    const landing_url = process.env.REACT_APP_LANDING_PAGE_URL;

    if (token && user_type && role && !role.includes(user_type)) {
      return <Navigate to={'/'} />;
    } else if (token && user_type && [UserTypes.TAXACCOUTANT, UserTypes.SUB_TAXACCOUTANT, UserTypes.CHANNEL_PARTNER, UserTypes.SUB_CHANNEL_PARTNER].includes(user_type)) {
      return children;
    } else if (token && user_type && user_type === UserTypes.USER) {
      return <Navigate to={'/'} />;
    } else {
      window.open(landing_url, '_self');
    }
  }, [children]);

  return isAuthenticated;
};

export default TexProtected;
