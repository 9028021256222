import React, { useMemo } from 'react';
import { Navigate } from 'react-router-dom';

const PublicRoutes = ({ children }) => {
  const isAuthenticated = useMemo(() => {
    let token = localStorage.getItem('token');

    return token ? true : false;
  }, []);

  return isAuthenticated ? <Navigate to={'/'} /> : children;
};

export default PublicRoutes;
