import { Card, Form, Input, Modal, Spin, Typography, notification } from 'antd';
import React from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { sendOtpCheckVerificationDelete } from '../../../redux/actions/Header/sendOtp.action';
import { FrownOutlined, SmileOutlined } from '@ant-design/icons';
import { OtpCheckVerificationDelete } from '../../../redux/actions/Header/verifyOtp.action';
import { DeleteUser } from '../../../redux/actions/Header/DeleteUser.action';
import { useNavigate } from 'react-router-dom';

export default function DeleteAccount() {
  const landing_url = process.env.REACT_APP_LANDING_PAGE_URL;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isOpendelconfirm, setIsOpendelconfirm] = useState(false);
  const [otpId, setOtpId] = useState('');
  const [spin2, setSpin2] = useState(false);
  const [spin3, setSpin3] = useState(false);

  const deleteConfirm = () => {
    setSpin2(true);
    dispatch(sendOtpCheckVerificationDelete()).then((res) => {
      if (res.payload.success === true) {
        setOtpId(res.payload.result.otpId);
        setIsOpendelconfirm(true);
        setSpin2(false);
      } else {
        notification.open({
          message: 'Failure',
          description: res.payload.message || 'Something went wrong!',
          icon: (
            <FrownOutlined
              style={{
                color: 'red',
              }}
            />
          ),
        });
        setSpin2(false);
      }
    });
  };

  const handleFinishConfirm = (value) => {
    setSpin3(true);
    const values = {
      id: otpId,
      otp: value.otpCode,
    };
    dispatch(OtpCheckVerificationDelete(values)).then((res) => {
      if (res.payload.success === true) {
        notification.open({
          message: 'Success',
          description: res.payload.message,
          icon: (
            <SmileOutlined
              style={{
                color: '#56AAAA',
              }}
            />
          ),
        });
        dispatch(DeleteUser()).then((res) => {
          if (res.payload.success === true) {
            notification.open({
              message: 'Success',
              description: res.payload.message,
              icon: (
                <SmileOutlined
                  style={{
                    color: '#56AAAA',
                  }}
                />
              ),
            });
            setIsOpendelconfirm(false);
            setSpin3(false);
            localStorage.clear();
            window.open(landing_url, '_self');
          }
        });
        setIsOpendelconfirm(false);
        setSpin3(false);
      } else {
        notification.open({
          message: 'Failure',
          description: res.payload.message || 'Something went wrong!',
          icon: (
            <FrownOutlined
              style={{
                color: 'red',
              }}
            />
          ),
        });
        setSpin3(false);
      }
    });
  };

  return (
    <div className="container-fluid bg-dash-back">
      <div className="page-content">
        <div className="layout-specing">
          <div className="row align-items-center mt-1">
            <div className="col-12">
              <div className="text-center text-sm-start mb-sm-0 mb-3 mb-sm-0">
                <h5 className="m-0">Delete Account</h5>
              </div>
            </div>
          </div>
          <Card className="box_shadow txtAccountentModal1 mt-3">
            <Spin spinning={spin2}>
              <Typography.Text style={{ fontSize: '1.3rem' }}>Confirm Delete</Typography.Text>
              <p className="mt-3">Before proceeding with the deletion of your account, please note that all your data will be permanently removed, and you will not be able to access it again.</p>

              <p> To avoid losing any important information, we highly recommend that you first download your data and then proceed with deleting your account.</p>

              <p> Are you sure you want to continue with deleting your account?</p>
              <div className="justify-content-center d-flex mt-2 ">
                <button className="btn btn-primary m-2 confirm_btn" onClick={() => deleteConfirm()}>
                  Yes
                </button>
                <button className="btn btn-primary m-2 cancle_btn" onClick={() => navigate('/')}>
                  No
                </button>
              </div>
            </Spin>
          </Card>
        </div>
      </div>
      {/* MODAL delete verify Otp */}
      <Modal onCancel={() => setIsOpendelconfirm(false)} footer={null} centered style={{ zIndex: '1005', borderRadius: '1em' }} className="forgotPasswordModal" open={isOpendelconfirm}>
        <Spin spinning={spin3}>
          <Typography.Text style={{ fontSize: '1.5rem' }}>OTP Verification</Typography.Text>
          <p className="mt-2">An OTP (One-Time Password) has been sent to your registered mobile number.</p>
          <Form layout="vertical" id="forgotPassword" onFinish={handleFinishConfirm} className="mt-3">
            <Form.Item
              label="OTP"
              name="otpCode"
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: 'Please enter your otp!',
                },
              ]}
            >
              <Input placeholder="Enter otp" />
            </Form.Item>
            <div className="normal_button text-center">
              <button size="large" type="submit">
                Verify
              </button>
            </div>
          </Form>
        </Spin>
      </Modal>
    </div>
  );
}
