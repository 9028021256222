import { postRequest } from "../../../utils/apiUtils";
import {
    addTaxAccountantSuccess,
    addTaxAccountantFailure,
    addTaxAccountantRequest,
} from "../../reducers/slices/auth/addTaxAccountant.slice";

export const AddTaxAccountant = (data) => {
    return async (dispatch) => {
        await dispatch(addTaxAccountantRequest());
        const { result, error } = await postRequest(`user/assign/tax-accountant`, data);

        if (!error) {
            return await dispatch(addTaxAccountantSuccess(result));
        }
        return await dispatch(addTaxAccountantFailure(result));
    };
};
