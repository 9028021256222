import { deleteRequest, getRequest, postRequest } from '../../../utils/apiUtils';

export const GetAssignMortgageBroker = () => {
  return async () => {
    const { result } = await getRequest(`user/mortgage-broker`);

    return result;
  };
};

export const DeleteAssignMortgageBroker = () => {
  return async () => {
    const { result } = await deleteRequest(`user/mortgage-broker`);

    return result;
  };
};

export const AddMortgageBroker = (data) => {
  return async () => {
    const { result } = await postRequest(`user/assign/mortgage-broker`, data);

    return result;
  };
};
