import { Button, Col, Input, notification, Popconfirm, Row, Segmented, Spin, Table, Tag } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import eyeIcon from '../../assets/images/eye.svg';
import CustomIcon from '../sidebar/custIcon';
import watsappIcon from '../../assets/images/watsappIcon.svg';
import { GetTexDashboardData, GetTexInviteLaterDashboardData } from '../../redux/actions/tex-dashboard/getTexDashboarddata.action';
import { ChannelPartnerType, UserStatus, UserTypes, clientKeys } from '../../components/common/userTypes';
import './index.less';
import { formatCurrency } from '../../utils';
import { SendOutlined, UsergroupAddOutlined, UserOutlined } from '@ant-design/icons';
import { resendEmailTaxAccountantAsync } from '../../redux/reducers/slices/texDashboard/UserOnbording/resendEmailSlice';
import { DeleteOnboardingUser } from '../../redux/actions/tex-dashboard/UserOnbording/deleteUserList.action';
import deleteIcon from '../../assets/images/delete.svg';

const Clients = () => {
  const { user_type, channel_partner_type, mortgage_broker_status, channel_partner } = localStorage.getItem('admin') ? JSON.parse(localStorage.getItem('admin')) : {};
  const isTaxAccountant = user_type === UserTypes.TAXACCOUTANT;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const inviteLater = location?.state?.invite_later;
  const [clientListType, setClientListType] = useState(inviteLater ? 'invite_later' : 'invite_now');

  const TexDashboardData = useSelector((state) => state.getTexDashboardDataReducer);

  useEffect(() => {
    if (location?.state && location?.state?.invite_later) {
      navigate('/dashboard', { state: null });
    } else if (location?.state && !location?.state?.invite_later) {
      setClientListType('invite_now');
      navigate('/dashboard', { state: null });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location?.state]);

  useEffect(() => {
    setLoading(TexDashboardData.isLoading);
    if (TexDashboardData && TexDashboardData?.data?.result && TexDashboardData.error === false) {
      let dt = TexDashboardData?.data?.result?.map((elm) => {
        return { ...elm, key: crypto.randomUUID() };
      });
      setData(dt);
      setFilteredData(dt);
    }
  }, [TexDashboardData]);

  const fetchTexDashboardList = useCallback(() => {
    dispatch(GetTexDashboardData());
  }, [dispatch]);

  useEffect(() => {
    if (clientListType === 'invite_now') {
      fetchTexDashboardList();
    }
  }, [location.pathname, fetchTexDashboardList, clientListType]);

  useEffect(() => {
    if (inviteLater) {
      onChangeClientListType('invite_later');
    }
  }, [inviteLater]);

  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchText(value);
    const filtered = data.filter((item) => item.name.toLowerCase().includes(value) || item.email.toLowerCase().includes(value) || item.phone_number.toLowerCase().includes(value));
    setFilteredData(filtered);
  };

  const onChangeClientListType = (value) => {
    setClientListType(value);
    onSelectChange([]);
    setSearchText('');
    if (value === 'invite_later') {
      dispatch(GetTexInviteLaterDashboardData());
    }
  };

  const deleteOnbording = (id) => {
    setLoading(true);
    dispatch(DeleteOnboardingUser(id)).then((res) => {
      if (res.payload.success === true) {
        dispatch(GetTexInviteLaterDashboardData());
        notification.success({
          message: 'Success',
          description: res.payload.message || 'Property Deleted Successfully',
        });
        setLoading(false);
      } else {
        notification.error({
          message: 'Failure',
          description: res?.payload.message || 'Something went wrong!',
        });
        setLoading(false);
      }
    });
  };

  const handleSendEmail = async (ids) => {
    setLoading(true);
    const res = await dispatch(resendEmailTaxAccountantAsync(ids));

    if (res?.payload?.data?.success === true) {
      notification.success({
        message: 'Success',
        description: res?.payload?.data?.message,
      });
      onSelectChange([]);
      dispatch(GetTexInviteLaterDashboardData());
    } else {
      notification.error({
        message: 'Failure',
        description: res?.payload?.data?.message || 'Something went wrong!',
      });
    }
    setLoading(false);
  };

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: [Table.SELECTION_ALL, Table.SELECTION_NONE],
  };

  const column = [];

  if ((user_type === UserTypes.CHANNEL_PARTNER && channel_partner_type === ChannelPartnerType.MORTGAGE_BROKER) || user_type === UserTypes.SUB_CHANNEL_PARTNER) {
    column.push({
      title: 'Client Name',
      dataIndex: 'name',
      key: 'key',
      render: (text, record) => {
        return record.loans > 0 &&
          record.mortgage_broker &&
          ((user_type === UserTypes.SUB_CHANNEL_PARTNER && channel_partner?.mortgage_broker_status === UserStatus.ACTIVE) ||
            (user_type === UserTypes.CHANNEL_PARTNER && mortgage_broker_status === UserStatus.ACTIVE)) ? (
          <Button
            type="text"
            className="cursor-pointer"
            onClick={() => {
              localStorage.setItem('currentClient', record.id);
              navigate('client');
            }}
          >
            {text}
          </Button>
        ) : (
          <span className="ml-2">{text}</span>
        );
      },
    });
  }

  if (user_type === UserTypes.TAXACCOUTANT || user_type === UserTypes.SUB_TAXACCOUTANT) {
    column.push({
      title: 'Client Name',
      dataIndex: 'name',
      key: 'key',
      render: (text, record) => (
        <Button
          type="text"
          className="cursor-pointer"
          onClick={() => {
            clientListType === 'invite_later' ? setLocalStorageLater(record) : setLocalStorageNormal(record);
            localStorage.setItem('activeKey', clientKeys.REPORT);
            navigate('/clients/client/client-dashboard');
          }}
        >
          {text}
        </Button>
      ),
    });
  }

  column.push(
    ...[
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'key',
      },
      {
        title: 'Phone',
        dataIndex: 'phone_number',
        key: 'key',
      },
    ],
  );

  if ((user_type === UserTypes.CHANNEL_PARTNER && channel_partner_type === ChannelPartnerType.MORTGAGE_BROKER) || user_type === UserTypes.SUB_CHANNEL_PARTNER) {
    column.push({
      title: '# of loans',
      dataIndex: 'loans',
      key: 'loans',
      width: '10%',
      render(text, record) {
        if (!record.mortgage_broker) {
          return <Tag color="error">Access Pending</Tag>;
        }
        return text || 0;
      },
      onCell: (record) => ({
        colSpan: !record.mortgage_broker ? 3 : 1,
      }),
    });
    column.push({
      title: 'Loan Amount',
      dataIndex: 'loanAmount',
      key: 'loanAmount',
      width: '10%',
      align: 'right',
      render(text) {
        return text ? formatCurrency(text, 0, false, true) : '';
      },
      onCell: (record) => ({
        colSpan: !record.mortgage_broker ? 0 : 1,
      }),
    });
    column.push({
      title: 'View Report',
      key: 'key',
      width: '10%',
      render: (_text, record) => {
        return record.loans > 0 &&
          ((user_type === UserTypes.SUB_CHANNEL_PARTNER && channel_partner?.mortgage_broker_status === UserStatus.ACTIVE) ||
            (user_type === UserTypes.CHANNEL_PARTNER && mortgage_broker_status === UserStatus.ACTIVE)) ? (
          <Button
            type="primary"
            icon={<CustomIcon icon={eyeIcon} />}
            onClick={() => {
              localStorage.setItem('currentClient', record.id);
              navigate('client');
            }}
            size="small"
          />
        ) : (
          '-'
        );
      },
      onCell: (record) => ({
        colSpan: !record.mortgage_broker ? 0 : 1,
      }),
    });
  }

  if (user_type === UserTypes.TAXACCOUTANT || user_type === UserTypes.SUB_TAXACCOUTANT) {
    column.push({
      title: 'Total Properties',
      dataIndex: 'properties',
      key: 'key',
      width: '10%',
    });

    column.push({
      title: 'Subscribed Properties',
      dataIndex: 'totalSubscribedproperties',
      key: 'subscribed',
      width: '10%',
    });

    column.push({
      title: 'Subscribed by',
      dataIndex: 'lastSubscribedPropertyBy',
      key: 'lastSubscribedPropertyBy',
      width: '10%',
      render(text) {
        return <span> {text || '-'} </span>;
      },
    });

    column.push({
      title: 'View',
      key: 'key',
      render: (_text, record) => {
        return (
          <Row>
            <Col>
              <div className="d-flex justify-content-between align-items-center gap-2 Bank_card ">
                <Button
                  type="primary"
                  icon={<CustomIcon icon={eyeIcon} />}
                  onClick={() => {
                    clientListType === 'invite_later' ? setLocalStorageLater(record) : setLocalStorageNormal(record);
                    localStorage.setItem('activeKey', clientKeys.REPORT);
                    navigate('/clients/client/client-dashboard');
                  }}
                  size="small"
                />
              </div>
            </Col>
          </Row>
        );
      },
    });
  }

  if (clientListType === 'invite_later') {
    column.push({
      title: 'Action',
      key: 'action',
      render: (_text, record) => {
        return (
          <Row>
            <Col>
              <div className="d-flex justify-content-between align-items-center gap-2 Bank_card">
                <Popconfirm placement="topRight" title="Are you sure to delete?" onConfirm={() => deleteOnbording(record.id)} okText="Yes" cancelText="No">
                  <Button type="primary" className="Delete_btn" icon={<CustomIcon icon={deleteIcon} />} size="small" />
                </Popconfirm>
                <Button className="Connect_btn" icon={<SendOutlined rotate={-45} />} onClick={() => handleSendEmail([record.id])}>
                  {record?.send_invitation === 1 ? 'Resend Invite' : 'Invite'}
                </Button>
              </div>
            </Col>
          </Row>
        );
      },
    });
  }

  const setLocalStorageNormal = (record) => {
    localStorage.setItem('currentClient', record.id);
    localStorage.setItem('inviteLater', false);
  };

  const setLocalStorageLater = (record) => {
    localStorage.setItem('currentClient', record.user_id);
    localStorage.setItem('inviteLater', true);
  };

  return (
    <>
      <main className={`${isTaxAccountant ? '' : 'page-content'}`}>
        <div className={`${isTaxAccountant ? '' : ' container-fluid bg-dash-back'}`}>
          <a href="https://wa.me/+61493767832?text=I%20am%20interested%20in%20Property%20Accountant" className="whats_icon" target="_blank" rel="noreferrer">
            <img src={watsappIcon} className="img-fluid" alt="" />
          </a>
          <div className={`${isTaxAccountant ? '' : 'layout-specing'}`}>
            <Spin spinning={loading} size="large">
              <div className={`${isTaxAccountant ? '' : 'container-lg'}`}>
                <div className="row align-items-center">
                  <div className={`col-4 text-center text-sm-start mb-sm-0 mb-3 mb-sm-0`}>
                    <h5 className="m-0">Clients List</h5>
                  </div>

                  <div className="col-8 text-end custom-search-parent">
                    {selectedRowKeys && selectedRowKeys.length > 0 && (
                      <Button className="Connect_btn" type="primary" size="large" icon={<SendOutlined rotate={-45} />} onClick={() => handleSendEmail(selectedRowKeys)}>
                        Invite
                      </Button>
                    )}
                    {isTaxAccountant && (
                      <Segmented
                        options={[
                          {
                            value: 'invite_now',
                            label: 'Register Clients',
                            icon: <UserOutlined />,
                          },
                          {
                            value: 'invite_later',
                            label: 'Manage Clients',
                            icon: <UsergroupAddOutlined />,
                          },
                        ]}
                        size="large"
                        style={{ backgroundColor: 'rgb(229, 229, 229)' }}
                        value={clientListType}
                        onChange={(value) => {
                          onChangeClientListType(value);
                        }}
                      />
                    )}

                    <Input.Search placeholder="Search by Name, Email, Phone" allowClear value={searchText} size="middle" onChange={handleSearch} className="custom-search" />
                  </div>
                </div>
                <div className="row mt-4 client_table_parent">
                  <div className="col-12 ">
                    <div className="dashboard_list">
                      <div className="card ">
                        <Table
                          scroll={{
                            x: 'auto',
                          }}
                          rowKey="id"
                          rowSelection={clientListType === 'invite_later' ? rowSelection : null}
                          columns={column}
                          dataSource={filteredData}
                          size="middle"
                          pagination={!!isTaxAccountant}
                          className="client_table"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Spin>
          </div>
        </div>
      </main>
      <Outlet />
    </>
  );
};

export default Clients;
