import { configureStore } from '@reduxjs/toolkit';
import getCategoryListSlice from '../reducers/slices/category/getCategoryList.slice';
import getPropertyListSlice from '../reducers/slices/property/getPropertyList.slice';
import getPropertyListWithOutLoanSlice from '../reducers/slices/property/getPropertyListWithOutLoan.slice';
import getTransactionsSlice from '../reducers/slices/transactions/getTransactions.slice';
import getPropertyListByIdSlice from '../reducers/slices/property/getPropertyListById.slice';
import addRecordExpenseSlice from '../reducers/slices/record_expense/addRecord.slice';
import addPropertySlice from '../reducers/slices/property/addProperty.slice';
import editPropertySlice from '../reducers/slices/property/editProperty.slice';
import getDashboardDataSlice from '../reducers/slices/dashboard/getDashboardData.slice';
import userSignUpSlice from '../reducers/slices/auth/userSignUp.slice';
import uploadFileSlice from '../reducers/slices/record_expense/uploadFile.slice';
import editRecordExpensesSlice from '../reducers/slices/record_expense/editRecord.slice';
import getTransactionByIdSlice from '../reducers/slices/transactions/getTransactionsById.slice';
import getRentalSummaryByIdSlice from '../reducers/slices/rental_summary/getRentalSummaryById.slice';
import addRentalSummarySlice from '../reducers/slices/rental_summary/addRentalSummary.slice';
import deleteRentalSummarySlice from '../reducers/slices/rental_summary/deleteRentalSummary.slice';
import forgotPasswordSlice from '../reducers/slices/auth/forgotPassword.slice';
import getTexDashboardDataSlice from '../reducers/slices/texDashboard/getTexDashboardData.slice';
import getReportDataSlice from '../reducers/slices/reports/getReport.slice';
import getReportTransactionDataSlice from '../reducers/slices/reports/getReportTransaction.slice';
import getBankListSlice from '../reducers/slices/property/getBankList.slice';
import getStateListSlice from '../reducers/slices/property/getStateList.slice';
import changePasswordSlice from '../reducers/slices/auth/userChangePassword.slice';

import OtpVerificationSlice from '../reducers/slices/auth/otpSent.slice';
import OtpCheckVerificationSlice from '../reducers/slices/auth/otpVerification.slice';

import addTaxAccountantSlice from '../reducers/slices/auth/addTaxAccountant.slice';
import taxAccountantNoSlice from '../reducers/slices/auth/taxAccountantNo.slice';
import getClientdetailSlice from '../reducers/slices/client-detail/getclientdetailById.slice';
import deleteRentalSummarylistSlice from '../reducers/slices/rental_summary/deleteRentalSummarylist.Slice';
import deletePropertySlice from '../reducers/slices/property/deleteProperty.slice';
import getPropertyVideoSlice from '../reducers/slices/property-video/Video.slice';
import RemindSettlementSlice from '../reducers/slices/dashboard/RemindSettlement.slice';
import RemindDepreciationSlice from '../reducers/slices/dashboard/RemindDepreciation.slice';
import forgotPasswordUpdateSlice from '../reducers/slices/auth/forgotUpdate.slice';
import getBankConnectSlice from '../reducers/slices/dashboard/getBankConnect.slice';
import postBankConnectSlice from '../reducers/slices/dashboard/postBankConnect.slice';
import getBankDetailsSlice from '../reducers/slices/dashboard/getBankDetails.slice';
import OtpCheckVerificationDeleteSlice from '../reducers/slices/Header/verifyOTP.slice';
import SendOtpCheckVerificationDeleteSlice from '../reducers/slices/Header/sendOtp.slice';
import DeleteUserSlice from '../reducers/slices/Header/Deleteuser.slice';
import ManageConsentSlice from '../reducers/slices/Header/manageConsent.slice';
import ManageRevertSlice from '../reducers/slices/Header/ManageRevert.slice';
import DeleteConSlice from '../reducers/slices/BankConnection/deleteConnection.slice';
import getTaxUserListSlice from '../reducers/slices/texDashboard/UserOnbording/getUseronbording.slice';
import postImportTaxUserSlice from '../reducers/slices/texDashboard/UserOnbording/postImportCsv.slice';
import getApproveuserSlice from '../reducers/slices/dashboard/getApprove.slice';
import getRejectuserSlice from '../reducers/slices/dashboard/getReject.slice';
import getAssignTaxtoUserSlice from '../reducers/slices/auth/getAssignTaxtoUser.slice';
import deleteAssignTaxtoUserSlice from '../reducers/slices/auth/deleteAssignTaxtoUser.slice';
import deleteOnbordingUserSlice from '../reducers/slices/texDashboard/UserOnbording/deleteuseronboarding.slice';
import getSubUserListSlice from '../reducers/slices/texDashboard/subusers/getSubusers.slice';
import getuserdropListSlice from '../reducers/slices/texDashboard/subusers/getuserdroplist.slice';
import postuserDataSlice from '../reducers/slices/texDashboard/subusers/postuserData.slice';
import getUserdataByIdSlice from '../reducers/slices/texDashboard/subusers/getUserdataById.slice';
import deleteSubuserSlice from '../reducers/slices/texDashboard/subusers/deleteSubuser.slice';
import verifyUserSlice from '../reducers/slices/auth/verifyUser.slice';
import logoutSlice from '../reducers/slices/auth/logout.slice';
import reviewRentalSummarySlice from '../reducers/slices/rental_summary/reviewByIdSummay.slice';
import getClientBankDetailsSlice from '../reducers/slices/client-detail/clients-tabs/Client-Bankconnetion.Slice';
import getClientActionItemsSlice from '../reducers/slices/client-detail/clients-tabs/ActionItem.slice';
import getdocumentationDataSlice from '../reducers/slices/client-detail/clients-tabs/documetation.slice';
import getReciptdocumentationDataSlice from '../reducers/slices/client-detail/clients-tabs/documentRecipt.slice';
import deleteRecordExpenseSlice from '../reducers/slices/record_expense/deleteRecordExpense.slice';
import LoanRejectSlice from '../reducers/slices/dashboard/loanReject.slice';
import getTermConditionSlice from '../reducers/slices/auth/getTer-condition.slice';
import getUserActivitySlice from '../reducers/slices/user-activity/getTransactions.slice';
import getPropertyNotificationDataSlice from '../reducers/slices/dashboard/getPropertyNotificationData.slice';
import getNotificationDataSlice from '../reducers/slices/Header/getNotificationData.slice';

// Subscription
import getSubscriptionPropertyListSlice from '../reducers/slices/subscription/getSubscriptionPropertyList.slice';
import getSubscribePropertyListSlice from '../reducers/slices/subscription/getSubscribePropertyList.slice';
import getPlanListSlice from '../reducers/slices/subscription/getPlanList.slice';
import getMortgageBrokerClientListDataSlice from '../reducers/slices/mortgage_broker/getMortgageBrokerClientListData.slice';
import getMortgageBrokerDashboardDataSlice from '../reducers/slices/mortgage-dashboard/getMortgageBrokerDashboardData.slice';
import getMortgageBrokerDashboardListDataSlice from '../reducers/slices/mortgage-dashboard/getMortgageBrokerDashboardListData.slice';

// Audit Trail
import getAuditTrailListSlice from '../reducers/slices/AuditTrail/getAuditTrailList.slice';
import getAuditTrailChartDataSlice from '../reducers/slices/AuditTrail/getAuditTrailChartData.slice';
import getLockYearsDataSlice from '../reducers/slices/AuditTrail/getLockYearsData.slice';

//tax accountant

import TaxAccountantDashboardReducer from '../reducers/slices/tax-accountant-dashboard/TaxAccountantSlice';

import BankConnectionReducer from '../reducers/slices/BankConnection/BankConnectionSlice';

import resendEmailSlice from '../reducers/slices/texDashboard/UserOnbording/resendEmailSlice';

import FinancialPlannerSlice from '../reducers/slices/FinancialPlanner/FinancialPlannerSlice';

const store = configureStore({
  reducer: {
    financialPlanner: FinancialPlannerSlice,
    resendEmail: resendEmailSlice,
    bankConnection: BankConnectionReducer,
    taxAccountant: TaxAccountantDashboardReducer,
    userSignUpReducer: userSignUpSlice,

    getTransactionReducer: getTransactionsSlice,
    getPropertyListReducer: getPropertyListSlice,
    getPropertyListWithOutLoanReducer: getPropertyListWithOutLoanSlice,
    getPropertyListByIdReducer: getPropertyListByIdSlice,
    getCategoryListReducer: getCategoryListSlice,
    getDashboardDataReducer: getDashboardDataSlice,
    getTransactionByIdReducer: getTransactionByIdSlice,
    getRentalSummaryByIdReducer: getRentalSummaryByIdSlice,
    addRecordExpenseReducer: addRecordExpenseSlice,
    addPropertyReducer: addPropertySlice,
    addRentalSummaryReducer: addRentalSummarySlice,
    ReviewByIdSummayReducer: reviewRentalSummarySlice,
    editPropertyReducer: editPropertySlice,
    uploadFileReducer: uploadFileSlice,
    editRecordExpensesReducer: editRecordExpensesSlice,
    deleteRentalSummaryReducer: deleteRentalSummarySlice,
    forgotPasswordReducer: forgotPasswordSlice,
    getTexDashboardDataReducer: getTexDashboardDataSlice,
    getReportDataReducer: getReportDataSlice,
    getReportTransactionDataReducer: getReportTransactionDataSlice,
    getBankListReducer: getBankListSlice,
    getStateListReducer: getStateListSlice,
    changePasswordReducer: changePasswordSlice,
    OtpVerificationReducer: OtpVerificationSlice,
    OtpCheckVerificationSReducer: OtpCheckVerificationSlice,
    addTaxAccountantReducer: addTaxAccountantSlice,
    taxAccountantNoReducer: taxAccountantNoSlice,
    getClientdetailByIdReducer: getClientdetailSlice,
    deleteRentalSummarylistReducer: deleteRentalSummarylistSlice,
    deletePropertyReducer: deletePropertySlice,
    propertyvideoReducer: getPropertyVideoSlice,
    remindSettlementReducer: RemindSettlementSlice,
    remindDepreciationReducer: RemindDepreciationSlice,
    forgotUpdateReducer: forgotPasswordUpdateSlice,
    getBankConnectReducer: getBankConnectSlice,
    postBankConnectReducer: postBankConnectSlice,
    getBankDetalisReducer: getBankDetailsSlice,
    postverifyOtpReducer: OtpCheckVerificationDeleteSlice,
    sendOtpReducer: SendOtpCheckVerificationDeleteSlice,
    DeleteUserReducer: DeleteUserSlice,
    ManageConsentReducer: ManageConsentSlice,
    manageRevertRdeucer: ManageRevertSlice,
    DeleteConeectionReducer: DeleteConSlice,
    getTaxUserListReducer: getTaxUserListSlice,
    postImportCsvReducer: postImportTaxUserSlice,
    getApproveUserReducer: getApproveuserSlice,
    getRejectUserReducer: getRejectuserSlice,
    getAssignTaxtoUserReducer: getAssignTaxtoUserSlice,
    deleteAssignTaxtoUserReducer: deleteAssignTaxtoUserSlice,
    deleteOnbordingUserReducer: deleteOnbordingUserSlice,
    getSubusersListReducer: getSubUserListSlice,
    getuserDropListreducer: getuserdropListSlice,
    postSubuserReducer: postuserDataSlice,
    getUserDataByIdReducer: getUserdataByIdSlice,
    deleteSubuserReducer: deleteSubuserSlice,
    verifyUserReducer: verifyUserSlice,
    logoutReducer: logoutSlice,
    CLientBankReducer: getClientBankDetailsSlice,
    ClientActionItemReducer: getClientActionItemsSlice,
    DocumentationDataReducer: getdocumentationDataSlice,
    DocumentReciptReducer: getReciptdocumentationDataSlice,
    deleteRecordExpenseSliceReducer: deleteRecordExpenseSlice,
    LoanRejectSliceReducer: LoanRejectSlice,
    getTermConditionReducer: getTermConditionSlice,
    getUserActivityReducer: getUserActivitySlice,
    getPropertyNotificationDataReducer: getPropertyNotificationDataSlice,
    getNotificationDataReducer: getNotificationDataSlice,

    getSubscriptionPropertyListReducer: getSubscriptionPropertyListSlice,
    getSubscribePropertyListReducer: getSubscribePropertyListSlice,
    getPlanListReducer: getPlanListSlice,
    getMortgageBrokerClientListReducer: getMortgageBrokerClientListDataSlice,
    getMortgageBrokerDashboardReducer: getMortgageBrokerDashboardDataSlice,
    getMortgageBrokerDashboardListReducer: getMortgageBrokerDashboardListDataSlice,

    getAuditTrailListReducer: getAuditTrailListSlice,
    getAuditTrailChartDataReducer: getAuditTrailChartDataSlice,
    getLockYearsDataReducer: getLockYearsDataSlice,
  },
});

export default store;
