import { Alert, Spin } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { GetMortgageBrokerClientList } from '../../../redux/actions/mortgage_broker/getMortgageBrokerClientList.action';
import Reports from './index';
import { UserStatus, UserTypes } from '../../common/userTypes';
import { GetTexDashboardData } from '../../../redux/actions/tex-dashboard/getTexDashboarddata.action';

const Clients = () => {
  const { mortgage_broker_status, user_type, channel_partner } = localStorage.getItem('admin') ? JSON.parse(localStorage.getItem('admin')) : {};
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [clientIds, setClientIds] = useState([]);
  const notificationDta = useSelector((state) => state.getNotificationDataReducer);
  const isFinancialPlanner = notificationDta?.data?.result?.userData?.is_financial_planner;

  const TexDashboardData = useSelector((state) => state.getTexDashboardDataReducer);

  const isMortgageBroker = user_type === UserTypes.CHANNEL_PARTNER || user_type === UserTypes.SUB_CHANNEL_PARTNER;

  const MortgageBrokerClientList = useSelector((state) => state.getMortgageBrokerClientListReducer);

  useEffect(() => {
    isFinancialPlanner && dispatch(GetTexDashboardData());
  }, [dispatch, isFinancialPlanner]);

  useEffect(() => {
    isFinancialPlanner && setLoading(TexDashboardData.isLoading);
    if (isFinancialPlanner && TexDashboardData && TexDashboardData?.data?.result && TexDashboardData.error === false) {
      let dt = TexDashboardData?.data?.result?.map((elm) => {
        return { ...elm, key: crypto.randomUUID(), label: elm.name, value: elm.id };
      });
      setClientIds(dt.map((elm) => elm.id));
      setData(dt);
    }
  }, [TexDashboardData, isFinancialPlanner]);

  //* API Call
  useEffect(() => {
    isMortgageBroker && setLoading(MortgageBrokerClientList.isLoading);
    if (isMortgageBroker && MortgageBrokerClientList && MortgageBrokerClientList?.data?.result && MortgageBrokerClientList.error === false) {
      let dt = MortgageBrokerClientList?.data?.result?.map((elm) => {
        return { key: crypto.randomUUID(), value: elm.id, label: elm.name };
      });

      setClientIds(dt.map((elm) => elm.value));
      setData(dt);
    }
  }, [MortgageBrokerClientList, isMortgageBroker, isFinancialPlanner]);

  const fetchMortgageBrokerClientList = useCallback(() => {
    isMortgageBroker && dispatch(GetMortgageBrokerClientList());
  }, [dispatch, isMortgageBroker]);

  useEffect(() => {
    isMortgageBroker && fetchMortgageBrokerClientList();
  }, [fetchMortgageBrokerClientList, isMortgageBroker]);

  return (
    <main className="page-content">
      <div className="container-fluid bg-dash-back">
        <div className="layout-specing">
          <Spin spinning={loading} size="large">
            {/* <div className="row align-items-center">
                  <div className="text-center text-sm-start mb-sm-0 mb-3 mb-sm-0">
                    <h5 className="m-0">Loan Overview</h5>
                  </div>
                </div> */}
            <div className="row align-items-center mt-4">
              {!loading &&
                clientIds.length > 0 &&
                ((user_type === UserTypes.SUB_CHANNEL_PARTNER && channel_partner?.mortgage_broker_status === UserStatus.ACTIVE) ||
                  (user_type === UserTypes.CHANNEL_PARTNER && mortgage_broker_status === UserStatus.ACTIVE) ||
                  (isFinancialPlanner && clientIds.length > 0 && (user_type === UserTypes.TAXACCOUTANT || user_type === UserTypes.SUB_TAXACCOUTANT))) && (
                  <Reports isSwitching={true} clientIds={clientIds} clientOptions={data} autoRender={isFinancialPlanner && clientIds.length > 0} />
                )}
              {((user_type === UserTypes.SUB_CHANNEL_PARTNER && channel_partner?.mortgage_broker_status === UserStatus.PENDING) ||
                (user_type === UserTypes.CHANNEL_PARTNER && mortgage_broker_status === UserStatus.PENDING)) && (
                <div className="container-lg">
                  <div className="row align-items-center mb-4">
                    <div className="text-center text-sm-start mb-sm-0 mb-3 mb-sm-0">
                      <h5 className="m-0">Loan Overview</h5>
                    </div>
                  </div>
                  <Alert message="Mortgage Broker approval request pending." description="Mortgage Broker Request Pending. Please wait for it to be approved." type="warning" showIcon />
                </div>
              )}
              {!loading &&
                clientIds.length < 1 &&
                ((user_type === UserTypes.SUB_CHANNEL_PARTNER && channel_partner?.mortgage_broker_status === UserStatus.ACTIVE) ||
                  (user_type === UserTypes.CHANNEL_PARTNER && mortgage_broker_status === UserStatus.ACTIVE)) && (
                  <div className="container-lg">
                    <div className="row align-items-center mb-4">
                      <div className="text-center text-sm-start mb-sm-0 mb-3 mb-sm-0">
                        <h5 className="m-0">Loan Overview</h5>
                      </div>
                    </div>
                    <Alert message="Mortgage Broker client has not added any loans." type="warning" showIcon />
                  </div>
                )}

              {!loading && clientIds.length < 1 && isFinancialPlanner && (user_type === UserTypes.TAXACCOUTANT || user_type === UserTypes.SUB_TAXACCOUTANT) && (
                <div className="container-lg">
                  <div className="row align-items-center mb-4">
                    <div className="text-center text-sm-start mb-sm-0 mb-3 mb-sm-0">
                      <h5 className="m-0">Loan Overview</h5>
                    </div>
                  </div>
                  <Alert message="Client has not added any loans." type="warning" showIcon />
                </div>
              )}
            </div>
          </Spin>
        </div>
      </div>
    </main>
  );
};

export default Clients;
