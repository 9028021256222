import React from 'react';
import CustomIcon from './custIcon';
import reportDashboard from '../../assets/images/Report.svg';
import dollorDashboard from '../../assets/images/Transaction.svg';
import { useSelector } from 'react-redux';

const SubTaxNavigation = () => {
  const notificationDta = useSelector((state) => state.getNotificationDataReducer);
  const isFinancialPlanner = notificationDta?.data?.result?.userData?.is_financial_planner;

  let TexNavigationConfig = [
    {
      title: 'Clients',
      path: `clients`,
      icon: <CustomIcon width={20} icon={dollorDashboard} />,
    },
  ];

  if (isFinancialPlanner) {
    TexNavigationConfig.push({
      title: 'Loan Overview',
      path: 'loan-overview',
      icon: <CustomIcon width={20} icon={reportDashboard} />,
    });
  }

  return TexNavigationConfig;
};

export default SubTaxNavigation;
