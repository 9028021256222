import { getRequest } from '../../../../utils/apiUtils';
import {
  GetdocumentationDashboardDataFailure,
  GetdocumentationDashboardDataRequest,
  GetdocumentationDashboardDataSuccess,
} from '../../../reducers/slices/client-detail/clients-tabs/documetation.slice';

export const GetdocumentationdData = (id, propertyId = null) => {
  return async (dispatch) => {
    let tempURl = ``;
    if (!id) {
      tempURl = `tax-accountant/client/documents?propertyId=${propertyId}`;
    } else {
      tempURl = `tax-accountant/client/documents?userId=${id}&propertyId=${propertyId}`;
    }
    await dispatch(GetdocumentationDashboardDataRequest());
    const { result, error } = await getRequest(tempURl);

    if (!error) {
      return await dispatch(GetdocumentationDashboardDataSuccess(result));
    }
    return await dispatch(GetdocumentationDashboardDataFailure(result));
  };
};
