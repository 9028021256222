import { getRequest } from '../../../../utils/apiUtils';
import { GetReciptdocumentationDataFailure, GetReciptdocumentationDataRequest, GetReciptdocumentationDataSuccess } from '../../../reducers/slices/client-detail/clients-tabs/documentRecipt.slice';

export const GetReciptdocumentationdData = (id, id1, id2) => {
  return async (dispatch) => {
    await dispatch(GetReciptdocumentationDataRequest());
    const { result, error } = await getRequest(`tax-accountant/client/receipt/${id}?userId=${id1}&typeReceipt=${id2}`);

    if (!error) {
      return await dispatch(GetReciptdocumentationDataSuccess(result));
    }
    return await dispatch(GetReciptdocumentationDataFailure(result));
  };
};

export const GetReciptdocumentData = (id, user_id, type) => {
  return async () => {
    let tempURl = ``;
    if (!user_id) {
      tempURl = `user/transaction/receipt/${id}?transactionType=${type}`;
    } else {
      tempURl = `user/transaction/receipt/${id}?userId=${user_id}&transactionType=${type}`;
    }
    const { result } = await getRequest(tempURl);

    return result;
  };
};
