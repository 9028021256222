import { FrownOutlined } from '@ant-design/icons';
import { Badge, Input, Spin, Table, notification } from 'antd';
import React, { useEffect, useState } from 'react';
import { GetdocumentationdData } from '../../../../redux/actions/client-detail/clients-tabs/documetation.action';
import { useSelector, useDispatch } from 'react-redux';
import { GetReciptdocumentationdData } from '../../../../redux/actions/client-detail/clients-tabs/documentRecipt.action';
import { DocumentationReciptType, UserTypes } from '../../../common/userTypes';
import dayjs from 'dayjs';
import './index.less';
import { getYearList } from '../../../../utils';

const Documentation = () => {
  const [searchText, setSearchText] = useState('');
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const currentClient = localStorage.getItem('currentClient');
  const admin = localStorage.getItem('admin');
  const { user_type = null } = admin ? JSON.parse(admin) : {};
  const DocumentData = useSelector((state) => state.DocumentationDataReducer);

  useEffect(() => {
    dispatch(GetdocumentationdData(currentClient));
  }, [dispatch, currentClient]);

  const handleSearch = (value) => {
    setSearchText(value);
  };

  const NestedData = DocumentData?.BankData?.result?.map((item) => {
    const fy_rent = item?.fy_rent?.map((rent) => {
      return {
        name: 'End Of Year Rental Statement',
        status: rent?.status,
        key: `fy_rent-${rent?.id}`, // Unique key
        date: getYearList()?.find((item) => item?.value === rent?.financial_year)?.label,
        id: rent?.id,
      };
    });
    return {
      ...item,
      Document: [
        {
          name: 'Depreciation',
          date: item?.depreciation?.created_at ? dayjs(item?.depreciation?.created_at).format('DD-MM-YYYY') : '',
          status: item?.depreciation?.status,
          id: item?.depreciation?.id,
          key: `depreciation-${item?.depreciation?.id}`, // Unique key
        },
        {
          name: 'Purchase Settlement',
          date: item?.settlement[0]?.submitted_at ? dayjs(item?.settlement[0]?.submitted_at).format('DD-MM-YYYY') : '',
          status: item?.settlement[0]?.status,
          id: item?.settlement[0]?.id,
          key: `purchase-settlement-${item?.settlement[0]?.id}`, // Unique key
        },
        {
          name: 'Sell Settlement',
          date: item?.settlement[1]?.submitted_at ? dayjs(item?.settlement[1]?.submitted_at).format('DD-MM-YYYY') : '',
          status: item?.settlement[1]?.status,
          id: item?.settlement[1]?.id,
          key: `sell-settlement-${item?.settlement[1]?.id}`, // Unique key
        },
        ...fy_rent,
      ],
      key: item.id, // Ensure each top-level item has a unique key
    };
  });

  const expandedRowRender = (data) => {
    const columns = [
      {
        dataIndex: 'name',
        key: 'name',
        width: '20%',
        render: (text, record, index) => {
          return (
            <>
              {record?.id === undefined ? (
                <p className="browseBtn1" key={index}>
                  {record.name}
                </p>
              ) : (
                // eslint-disable-next-line
                <a
                  className="browseBtn"
                  onClick={() =>
                    getBankDocument(
                      record?.id,
                      record.name === 'Purchase Settlement' || record.name === 'Sell Settlement'
                        ? DocumentationReciptType.SETTLEMENT
                        : record.name === 'End Of Year Rental Statement'
                        ? 3
                        : DocumentationReciptType.DEPRECIATION,
                    )
                  }
                  key={index}
                >
                  {record.name}
                </a>
              )}
            </>
          );
        },
      },
      {
        dataIndex: 'date',
        key: 'date',
        width: '20%',
        render: (text, record, index) => {
          return record?.date ? <span key={index}>{record?.date}</span> : <span key={index}>-</span>;
        },
      },
      {
        dataIndex: 'status',
        key: 'status',
        width: '20%',
        render: (text, record, index) => {
          return record?.id ? (
            <span key={index}>
              <Badge status="success" className="me-2" />
              Completed
            </span>
          ) : (
            <span key={index}>
              <Badge status="warning" className="me-2" />
              Pending
            </span>
          );
        },
      },
    ];

    return <Table columns={columns} showHeader={false} dataSource={data?.Document} pagination={false} bordered={false} className="NestedRow" />;
  };

  const getBankDocument = (id, type) => {
    setLoading(true);
    dispatch(GetReciptdocumentationdData(id, currentClient, type)).then((res) => {
      if (res?.payload?.success) {
        setLoading(false);
        window.open(res?.payload?.result?.url, '_blank');
      } else {
        notification.open({
          message: 'Failure',
          description: res?.payload.message || 'Something went wrong!',
          icon: (
            <FrownOutlined
              style={{
                color: 'red',
              }}
            />
          ),
        });
        setLoading(false);
      }
    });
  };

  const columns = [
    {
      title: 'Property Name',
      dataIndex: 'street_number',
      key: 'id',
      width: '90%',
      render: (text, record) => {
        return `${record.street_number} ${record.street_name ? record.street_name + ' - ' + record.suburb + '-' + record.postcode : ''}`;
      },
    },
  ];

  const filteredData = NestedData?.filter((item) => `${item.street_number} ${item.street_name} ${item.suburb} ${item.postcode}`.toLowerCase().includes(searchText.toLowerCase()));

  return (
    <main className={user_type === UserTypes.USER ? 'page-content' : ''}>
      <div className={user_type === UserTypes.USER ? 'container-fluid bg-dash-back' : ''}>
        <div className={user_type === UserTypes.USER ? 'layout-specing' : ''}>
          <Spin spinning={DocumentData.isLoading ? DocumentData.isLoading : loading} size="large">
            <div className="row align-items-center mt-2 custom-documentation">
              <div className="col-xl-6 col-md-6 col-sm-6 col-12">
                <div className="text-center text-sm-start mb-sm-0 mb-3 mb-sm-0">
                  <h5 className="m-0">Documentation</h5>
                </div>
              </div>
              <div className="col-xl-6 col-md-6 col-sm-6 col-12 text-end custom-search-parent ">
                <Input.Search placeholder="Search Documentation" allowClear onSearch={handleSearch} onChange={(e) => handleSearch(e.target.value)} className="custom-search" value={searchText} />
              </div>
            </div>
            <div className="row align-items-center mt-4">
              <Table
                columns={columns}
                expandable={{
                  expandedRowRender,
                  defaultExpandedRowKeys: ['0'],
                }}
                dataSource={filteredData}
                rowKey={'id'}
                bordered={false}
              />
            </div>
          </Spin>
        </div>
      </div>
    </main>
  );
};

export default Documentation;
