import { Card, Collapse, Space } from 'antd';
import React from 'react';
import faqlist from './faqlist.json';
import './index.less';
import "../../../../assets/css/antd.css"
import "../../../../assets/css/style.css"


export default function Faq() {
  return (
    <>
      <div className="text-center text-sm-start mb-sm-0  mb-sm-0 mt-4">
        <h5 className="m-0">FAQ</h5>
      </div>
      <Card className="mt-2 pricing_faq">
        <Space direction="vertical">
          {faqlist.map((item, index) => (
            <Collapse
              key={index}
              expandIconPosition="end"
              collapsible="header"
              defaultActiveKey={['0']}
              items={[
                {
                  key: index,
                  label: item.question,
                  children: <p dangerouslySetInnerHTML={{ __html: item.answer }} />,
                },
              ]}
            />
          ))}
        </Space>
      </Card>
    </>
  );
}