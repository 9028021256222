import clients from '../../assets/images/clients.svg';
import onboarding from '../../assets/images/onbording.svg';

import CustomIcon from './custIcon';

const ChannelPartnerNavigationConfig = [
  {
    title: 'Clients',
    path: `clients`,
    icon: <CustomIcon width={20} icon={clients} />,
  },
  {
    title: 'Onboarding',
    path: 'onboarding',
    icon: <CustomIcon width={20} icon={onboarding} />,
  },
];

export default ChannelPartnerNavigationConfig;
