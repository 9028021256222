import { LockOutlined } from '@ant-design/icons';
import { Form, Input, Modal, notification, Spin, Typography } from 'antd';
import React from 'react';
import { UserChangePassword } from '../redux/actions/login/changePassword.action';
import { useDispatch } from 'react-redux';

const ChangePassword = ({ setIsOpen, isOpen, spin, setSpin }) => {
  const dispatch = useDispatch();

  const handleFinish = (values) => {
    setSpin(true);
    dispatch(UserChangePassword(values)).then((data) => {
      if (data.payload.success === true) {
        notification.success({
          message: 'Success',
          description: data.payload.message,
        });
        setIsOpen(false);
      } else {
        notification.error({
          message: 'Failure',
          description: data.payload.message || 'Something went wrong!',
        });
      }

      setSpin(false);
    });
  };
  return (
    <Modal onCancel={() => setIsOpen(false)} footer={null} centered style={{ zIndex: '1005', borderRadius: '1em' }} destroyOnClose className="forgotPasswordModal" open={isOpen}>
      <Spin spinning={spin}>
        <Typography.Text style={{ fontSize: '1.5rem' }}>Change Password</Typography.Text>
        <Form layout="vertical" id="forgotPassword" onFinish={handleFinish} style={{ marginTop: '2em' }}>
          <Form.Item
            label="Old Password"
            name="oldPassword"
            rules={[
              {
                required: true,
                whitespace: true,
                message: 'Please enter your Old Password!',
              },
            ]}
          >
            <Input.Password prefix={<LockOutlined className="site-form-item-icon" />} type="password" placeholder="Old Password" />
          </Form.Item>
          <Form.Item
            label="New Password"
            name="newPassword"
            rules={[
              {
                required: true,
                whitespace: true,
                message: 'Please enter your New Password!',
              },
            ]}
          >
            <Input.Password prefix={<LockOutlined className="site-form-item-icon" />} type="password" placeholder="New Password" />
          </Form.Item>
          <div className="normal_button text-center">
            <button size="large" type="submit">
              Change
            </button>
          </div>
        </Form>
      </Spin>
    </Modal>
  );
};

export default ChangePassword;

ChangePassword.defaultProps = {
  setIsOpen: () => {},
  isOpen: false,
  spin: false,
  setSpin: () => {},
};
