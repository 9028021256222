import svgDashboard from '../../assets/images/Home.svg';
import dollorDashboard from '../../assets/images/Transaction.svg';
import propertyDashboard from '../../assets/images/property.svg';
import reportDashboard from '../../assets/images/Report.svg';
// import faqDashboard from '../../assets/images/faq.svg';
// import circle from '../../assets/images/circle-play.svg';
import bankIcon from '../../assets/images/bank.svg';
import CustomIcon from './custIcon';
import { AuditOutlined, CreditCardOutlined, FieldTimeOutlined, FolderOpenOutlined } from '@ant-design/icons';

const NavigationConfig = [
  {
    title: 'Home',
    path: 'home',
    key: 'menu_home',
    icon: <CustomIcon width={20} icon={svgDashboard} />,
  },
  {
    title: 'Property',
    path: 'property',
    key: 'menu_property',
    icon: <CustomIcon width={20} icon={propertyDashboard} />,
  },
  {
    title: 'Bank',
    path: 'bank',
    key: 'menu_bank',
    icon: <CustomIcon width={20} icon={bankIcon} />,
  },
  {
    title: 'Reports',
    path: 'reports',
    key: 'menu_reports',
    icon: <CustomIcon width={20} icon={reportDashboard} />,
  },
  {
    title: 'Transactions',
    path: 'transactions',
    key: 'menu_transactions',
    icon: <CustomIcon width={20} icon={dollorDashboard} />,
  },
  {
    title: 'Audit Trail',
    path: 'audit-trail',
    key: 'menu_audit',
    icon: <AuditOutlined width={20} style={{ fontSize: 22, marginRight: '12px' }} />,
  },
  {
    title: 'Documentation',
    path: 'documentation',
    key: 'menu_user_documentation',
    icon: <FolderOpenOutlined style={{ fontSize: 20, marginRight: '12px' }} />,
  },

  {
    title: 'Subscription',
    path: 'subscription',
    key: 'menu_user_subscription',
    icon: <CreditCardOutlined style={{ fontSize: 20, marginRight: '12px' }} />,
  },

  {
    title: 'Activity',
    path: 'user-activity',
    key: 'menu_user_activity',
    icon: <FieldTimeOutlined style={{ fontSize: 22, marginRight: '12px' }} />,
  },

  // {
  //   title: 'FAQs',
  //   path: 'faqs',
  //   key: 'menu_faqs',
  //   icon: <CustomIcon width={20} icon={faqDashboard} />,
  // },
];

export default NavigationConfig;
