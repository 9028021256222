import { getRequest } from "../../../utils/apiUtils";
import { getAssignTaxtoUserFailure, getAssignTaxtoUserRequest, getAssignTaxtoUserSuccess } from "../../reducers/slices/auth/getAssignTaxtoUser.slice";


export const GetAssignTaxuser = () => {
    return async (dispatch) => {
        await dispatch(getAssignTaxtoUserRequest());
        const { result, error } = await getRequest(`user/tax-accountant`);

        if (!error) {
            return await dispatch(getAssignTaxtoUserSuccess(result));
        }
        return await dispatch(getAssignTaxtoUserFailure(result));
    };
};
