import { getRequest } from '../../../utils/apiUtils';
import { getTermConditionFailure, getTermConditionRequest, getTermConditionSuccess } from '../../reducers/slices/auth/getTer-condition.slice';

export const GetTermCondition = () => {
  return async (dispatch) => {
    await dispatch(getTermConditionRequest());
    const { result, error } = await getRequest(`user/termsandconditions/verified`);

    if (!error) {
      return await dispatch(getTermConditionSuccess(result));
    }
    return await dispatch(getTermConditionFailure(result));
  };
};
