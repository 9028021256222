import dayjs from 'dayjs';
import { UserTypes } from '../components/common/userTypes';
import { useLocation } from 'react-router';

export const amountFormat = (amount) => {
  // Remove any commas before converting to a number
  const numericAmount = Number(amount.toString().replace(/,/g, ''));
  return numericAmount
    .toFixed(2) // Format to 2 decimal places
    .toString() // Convert to string
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',') // Add commas as thousand separators
    .replace('.00', ''); // Remove trailing '.00' if present
};

export const GetValidateClass = (currentPath) => {
  const { pathname } = useLocation();

  if (pathname === '/' && currentPath === 'home') {
    return 'active';
  } else if (currentPath === 'property' && pathname === '/property/add') {
    return 'active';
  } else if (currentPath === 'transactions' && pathname === '/record-expenses') {
    return 'active';
  } else if (currentPath === 'transactions' && pathname.includes('/record-expenses/')) {
    return 'active';
  } else if (currentPath === 'clients' && pathname.includes('/clients/client/')) {
    return '';
  } else if (pathname.split('/').includes(currentPath)) {
    return 'active';
  } else {
    return '';
  }
  // return pathname === "/" && currentPath === "home"
  //     ? "active"
  //     : pathname.split("/").includes(currentPath)
  //         ? "active"
  //         : "";
};

export function formatCurrency(value, fixed = 0, remove = false, format = false) {
  if (isNaN(parseFloat(value))) {
    return '$0';
  }

  // If format is true, remove commas from the value first
  if (format) {
    value = value.toString().replace(/,/g, '');
  }

  const absValue = Math.abs(parseFloat(value)).toFixed(fixed);
  let formattedValue = absValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  if (remove || format) {
    formattedValue = formattedValue.replace('.00', ''); // Remove trailing '.00' if present
  }

  return value < 0 ? `-$${formattedValue}` : `$${formattedValue}`;
}

export const getYearList = () => {
  const start_date = 2020;
  let currentYear = dayjs().year();
  const currentMonth = dayjs().month(); // January is 0

  currentYear = currentMonth >= 6 ? currentYear : currentYear - 1;

  let dataOptions = [];

  for (let index = currentYear; index >= start_date; index--) {
    dataOptions.push({
      label: `FY ${index.toString().slice(-2)}-${(index + 1).toString().slice(-2)}`,
      value: index,
      key: index,
    });
  }
  return dataOptions;
};

export const getFinancialYearRanges = (years) => {
  return years.map((year) => {
    return {
      ...year,
      start: dayjs(`${year.year}-07-01`),
      end: dayjs(`${year.year + 1}-06-30`),
    };
  });
};

export const getAustralianFinancialYear = (date) => {
  const year = date.getFullYear();
  const month = date.getMonth() + 1; // Months are 0-based in JavaScript

  if (month >= 7) {
    return `${year % 100}-${(year + 1) % 100}`;
  } else {
    return `${(year - 1) % 100}-${year % 100}`;
  }
};

export const getErrorMessageForLockedYear = (transaction_date, role, user_id, id) => {
  const date = new Date(transaction_date);
  const fiscalYear = getAustralianFinancialYear(date);
  if (id === Number(user_id)) {
    return `Unable to add/edit/delete! Reports (FY ${fiscalYear}) are locked by You`;
  } else if (role === UserTypes.TAXACCOUTANT || role === UserTypes.SUB_TAXACCOUTANT) {
    return `Unable to add/edit/delete! Reports (FY ${fiscalYear}) are locked by your Tax Accountant`;
  } else if (role === UserTypes.USER) {
    return `Unable to add/edit/delete! Reports (FY ${fiscalYear}) are locked by the Property Investor`;
  }
};

export const getErrorNoteForLockedYear = (transaction_date, role, user_id, id) => {
  const date = new Date(transaction_date);
  const fiscalYear = getAustralianFinancialYear(date);
  if (id === Number(user_id)) {
    return `You cannot Edit/Delete this transaction because FY ${fiscalYear} reports are locked by You`;
  } else if (role === UserTypes.TAXACCOUTANT || role === UserTypes.SUB_TAXACCOUTANT) {
    return `You cannot Edit/Delete this transaction because FY ${fiscalYear} reports are locked by your Tax Accountant`;
  } else if (role === UserTypes.USER) {
    return `You cannot Edit/Delete this transaction because FY ${fiscalYear} reports are locked by the Property Investor`;
  }
};
