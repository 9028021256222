import { Avatar, Button, Form, Menu, notification, Select, Skeleton, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import reportDashboard from '../../assets/images/Report.svg';
import dollorDashboard from '../../assets/images/Transaction.svg';
import PropertyIcon from '../../assets/images/property.svg';
import svgDashboard from '../../assets/images/Home.svg';
import bankIcon from '../../assets/images/bank.svg';

import CustomIcon from './custIcon';
import { AuditOutlined, CreditCardOutlined, FolderOpenOutlined, SendOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { GetTexDashboardData, GetTexInviteLaterDashboardData } from '../../redux/actions/tex-dashboard/getTexDashboarddata.action';
import { GetclientDetailById } from '../../redux/actions/client-detail/getclientById.action';
import { UserTypes } from '../common/userTypes';
import { GetValidateClass } from '../../utils';
import { resendEmailTaxAccountantAsync } from '../../redux/reducers/slices/texDashboard/UserOnbording/resendEmailSlice';
const { Option } = Select;

const TaxAccountantSubSidebar = ({ view, collapsed, setCollapsed }) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [userDropdownList, setUserDropdownList] = useState([]);

  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { user_type } = localStorage.getItem('admin') ? JSON.parse(localStorage.getItem('admin')) : {};

  const inviteLater = localStorage.getItem('inviteLater') ? JSON.parse(localStorage.getItem('inviteLater')) : null;

  const TexDashboardData = useSelector((state) => state.getTexDashboardDataReducer);
  const currentClient = localStorage.getItem('currentClient');

  useEffect(() => {
    if (inviteLater) {
      dispatch(GetTexInviteLaterDashboardData());
    } else {
      dispatch(GetTexDashboardData());
    }
  }, [dispatch, inviteLater]);

  useEffect(() => {
    if (TexDashboardData && TexDashboardData?.data?.result && TexDashboardData.error === false) {
      let dt = TexDashboardData?.data?.result?.map((item) => {
        return { id: inviteLater ? item.user_id : item.id, label: item.name, value: inviteLater ? item.user_id : item.id };
      });
      setUserDropdownList(dt);
    }
  }, [TexDashboardData, inviteLater]);

  useEffect(() => {
    if (currentClient) {
      dispatch(GetclientDetailById(currentClient, user_type === UserTypes.CHANNEL_PARTNER || user_type === UserTypes.SUB_CHANNEL_PARTNER ? 2 : 1));
    }
  }, [currentClient, dispatch, user_type]);

  const { pathname } = useLocation();

  const clientDetail = useSelector((state) => state.getClientdetailByIdReducer);

  const menuItems = [
    {
      key: 1,
      label: 'Client Dashboard',
      path: 'client-dashboard',
      icon: <CustomIcon width={20} icon={svgDashboard} />,
    },
    {
      key: 4,
      label: 'Properties',
      path: 'property-details',
      icon: <CustomIcon width={20} icon={PropertyIcon} />,
    },
    {
      key: 2,
      label: 'Audit',
      path: 'audit-trail',
      icon: <AuditOutlined style={{ fontSize: 22, marginRight: '12px' }} />,
    },
    {
      key: 1,
      label: 'Reports',
      path: 'reports',
      icon: <CustomIcon width={20} icon={reportDashboard} />,
    },
    {
      key: 3,
      label: 'Transactions',
      path: 'transaction-details',
      icon: <CustomIcon width={20} icon={dollorDashboard} />,
    },

    {
      key: 5,
      label: 'Bank Loans',
      path: 'bank-connection',
      icon: <CustomIcon width={20} icon={bankIcon} />,
    },
    {
      key: 6,
      label: 'Documentation',
      path: 'documentation',
      icon: <FolderOpenOutlined style={{ fontSize: 20, marginRight: '12px' }} />,
    },
  ];

  if (user_type === UserTypes.TAXACCOUTANT) {
    menuItems.push({
      key: 7,
      label: 'Subscription',
      path: 'subscription',
      icon: <CreditCardOutlined style={{ fontSize: 20, marginRight: '12px' }} />,
    });
  }

  const handleDropdownVisibleChange = () => {
    if (view) {
      setCollapsed(false);
    }
  };

  const getAbbreviation = (str) => {
    if (str) {
      return str
        .split(' ')
        .map((word) => word[0])
        .join('')
        .toUpperCase();
    }
  };

  const handleSelectChange = (value) => {
    localStorage.setItem('currentClient', value);
    navigate('/clients/client/client-dashboard', { state: { reload_change: true } });
  };

  const handleSendEmail = async (record) => {
    setLoading(true);
    const { onboard_user_id } = record;
    const res = await dispatch(resendEmailTaxAccountantAsync([onboard_user_id]));

    if (res?.payload?.data?.success === true) {
      notification.success({
        message: 'Success',
        description: res?.payload?.data?.message,
      });
    } else {
      notification.error({
        message: 'Failure',
        description: res?.payload?.data?.message || 'Something went wrong!',
      });
    }
    setLoading(false);
  };

  const ForwardedMenuItem = ({ className, path, collapsed, icon, label }) => {
    return (
      <div className={className}>
        {collapsed ? (
          <>
            {path ? (
              <Tooltip title={label} className="collapsedTooltip" placement="right">
                <Link to={path}>{icon}</Link>
              </Tooltip>
            ) : (
              <Tooltip title={label} className="collapsedTooltip" placement="right">
                {icon}
              </Tooltip>
            )}
          </>
        ) : (
          <>
            {icon}
            {path ? <Link to={path}>{label}</Link> : <span>{label}</span>}
          </>
        )}
      </div>
    );
  };

  return (
    <>
      <div className="sidebar-wrapper shadow-none w-100">
        <div className="sidebar-brand border-bottom w-100  p-2" style={{ textAlign: 'center' }}>
          <Form form={form} layout="vertical" className="w-100">
            {!clientDetail?.isLoading ? (
              <Select
                onDropdownVisibleChange={handleDropdownVisibleChange}
                showSearch
                placeholder="Select Client"
                onChange={handleSelectChange}
                className="w-100"
                defaultValue={clientDetail?.data?.result?.id || undefined}
                optionFilterProp="children"
                filterOption={(input, option) => option?.children.toLowerCase().includes(input.toLowerCase())}
              >
                {userDropdownList.map((user) => (
                  <Option key={user.id} value={user.id}>
                    {user.label}
                  </Option>
                ))}
              </Select>
            ) : collapsed ? (
              <Skeleton.Input active className="overflow-hidden" style={{ width: '64px' }} block={true} />
            ) : (
              <Skeleton.Input style={{ width: '100%' }} active />
            )}
          </Form>
        </div>

        {!clientDetail?.isLoading ? (
          <div className="bg-white mt-1 ps-4 d-flex align-items-start flex-column gap-2  text-dark pb-3 border-0">
            {collapsed ? (
              <Tooltip
                title={
                  <div className="tooltip-content">
                    <div className="">{clientDetail?.data?.result?.name || '-'}</div>
                    <div>{clientDetail?.data?.result?.email || '-'}</div>
                    <div>{clientDetail?.data?.result?.phone_number || '-'}</div>
                  </div>
                }
                placement="right"
              >
                {clientDetail?.data?.result?.profile_image_path ? (
                  <Avatar size={36} className="mt-2" src={clientDetail?.data?.result?.profile_image_path} />
                ) : (
                  <Avatar size={36} className="mt-2">
                    {getAbbreviation(clientDetail?.data?.result?.name)}
                  </Avatar>
                )}
              </Tooltip>
            ) : (
              <>
                {clientDetail?.data?.result?.profile_image_path ? (
                  <Avatar src={clientDetail?.data?.result?.profile_image_path} size={64} className="mt-2" />
                ) : (
                  <Avatar size={64} className="mt-2">
                    {getAbbreviation(clientDetail?.data?.result?.name)}
                  </Avatar>
                )}
                <div className="text-start">
                  <span className="d-block fw-bold text-decoration-underline ">{clientDetail?.data?.result?.name || '-'}</span>
                  <Tooltip title={clientDetail?.data?.result?.email} placement="right">
                    <span className="d-block text-center text-truncate" style={{ maxWidth: '150px' }}>
                      {clientDetail?.data?.result?.email || '-'}
                    </span>
                  </Tooltip>
                  <span className="text-muted text-center">{clientDetail?.data?.result?.phone_number || '-'}</span>
                  {inviteLater && (
                    <Button className="Connect_btn mt-2" loading={loading} type="primary" icon={<SendOutlined rotate={-45} />} onClick={() => handleSendEmail(clientDetail?.data?.result)}>
                      {clientDetail?.data?.result?.send_invitation === 1 ? 'Resend Invite' : 'Invite Now'}
                    </Button>
                  )}
                </div>
              </>
            )}
          </div>
        ) : !collapsed ? (
          <div className="d-flex align-items-start mt-3 ps-4  gap-2 flex-column">
            <Skeleton.Avatar active={true} size={64} shape={'circle'} />
            <Skeleton.Input size={20} active />
            <Skeleton.Input size={20} active />
            <Skeleton.Input size={20} active />
          </div>
        ) : (
          <div className="d-flex justify-content-center align-items-center p-2 mt-1">
            <Skeleton.Avatar active={true} size={36} shape={'circle'} />
          </div>
        )}

        {/* <Divider /> */}
        <Menu
          mode="inline"
          className="h-100 d-flex flex-column justify-content-between align-items-center subMenuTaxAccountant"
          selectedKeys={[pathname]}
          items={menuItems.map((item) => {
            const path = item.path === 'home' ? '/' : '/clients/client/' + item.path;
            return {
              key: path,
              className: GetValidateClass(item.path),
              title: '',
              label: <ForwardedMenuItem collapsed={collapsed} icon={item.icon} path={path} label={item.label} />,
            };
          })}
        />
      </div>
    </>
  );
};

export default TaxAccountantSubSidebar;
