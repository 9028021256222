import { deleteRequest } from "../../../utils/apiUtils"
import { deleteAssignTaxFailure, deleteAssignTaxRequest, deleteAssignTaxSuccess } from "../../reducers/slices/auth/deleteAssignTaxtoUser.slice";


export const DeleteAssignTaxtoUser = () => {
    return async (dispatch) => {
        await dispatch(deleteAssignTaxRequest());
        const { result, error } = await deleteRequest(`user/tax-accountant`);

        if (!error) {
            return await dispatch(deleteAssignTaxSuccess(result));
        }
        return await dispatch(deleteAssignTaxFailure(result));
    };
};
